import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const BaseList = ({
    className,
    children,
}) => (
    <div className={cn(styles.BaseList, className)}>
        { children }
    </div>
);

export const BaseListItem = ({
    title,
    children,
}) => (
    <div className={styles.BaseListItem}>
        <div className={styles.BaseListTitle}>{ title }</div>
        <div className={styles.BaseListItemInner}>
            { children }
        </div>
    </div>
);

export const BaseListText = ({
    text,
    subText,
}) => (
    <div className={styles.BaseListText}>
        { text }
        <div className={styles.BaseListTextInner}>{ subText }</div>
    </div>
);

export default BaseList;
