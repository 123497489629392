import React from 'react';
import cn from 'classnames';
import Button from 'components/Button';
import styles from './styles.module.scss';
import catAstronaut from '../../assets/icons/profile/Cat_astronaut.jpg';
import folderDownload from '../../assets/icons/profile/folder-download.svg';

const UploadProfileImageModal = ({ handleClose, selectedImage, onChooseImage, onUploadImage, loading }) => (
    <div className={styles.ModalContainer}>
        <div
            className={styles.ModalOverlay}
            onClick={handleClose}></div>
        <div className={cn(styles.ModalWrapper)}>
            <div
                className={styles.ModalClose}
                onClick={handleClose}
            />
            <div className={styles.ModalContent}>
                <img src={catAstronaut}
                    alt={'Кот-астронавт'}
                    className={styles.ModalContent__Image}/>
                <div className={styles.ModalContent__Text}>
                    {'Вы можете загрузить изображение в формате JPG, GIF или PNG размером до 2 Мб'}
                </div>
            </div>
            <div className={styles.ModalButton__Choose}>
                <div className={styles.ModalButton__ChooseInput}>
                    <label htmlFor="upload-photo">
                                Выберите фотографию...
                    </label>
                    <img src={folderDownload}
                        alt={'Значок папки'}/>
                    <input
                        type="file"
                        name="photo"
                        id="upload-photo"
                        accept="image/jpg, image/jpeg, image/gif, image/png"
                        onChange={onChooseImage}
                    />
                </div>

            </div>
            {selectedImage && (
                <div className={styles.ModalButton__ChosenImageName}>
                    {selectedImage.name}
                </div>
            ) }
            <div className={styles.ModalButton__Upload}>
                <Button
                    size={'lg'}
                    variant={'primary'}
                    style={{ height: 44, width: '45%' }}
                    onClick={onUploadImage}
                    loading={loading}
                >
                    {'Загрузить'}
                </Button>
            </div>
        </div>
    </div>
);

export default UploadProfileImageModal;
