import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import MainTopWrapper from 'components/MainTopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import Badge from 'components/Badge';
import TitledContent from 'components/TitledContent';
import BaseList, { BaseListItem, BaseListText } from 'components/BaseList';
import ToggleTabs, { ToggleTabsItem, ToggleTabsWrapper } from 'components/ToggleTabs';
import TextContent from 'components/TextContent';
import FadeTransition from 'components/FadeTransition';
import Button from 'components/Button';
import BaseTable from 'components/BaseTable';

import DetailsContentWrapper from 'components/_modules/wrappers/DetailsContentWrapper';

import InvestmentIncomeTab from 'components/_modules/tabs/InvestmentIncomeTab';
import AdditionalIncomeTab from 'components/_modules/tabs/AdditionalIncomeTab';
import SocialTaxDeductionTab from 'components/_modules/tabs/SocialTaxDeductionTab';

import RedemptionSumsTable from 'components/_modules/policy/RedemptionSumsTable';
import BasicAssetTable from 'components/_modules/policy/BasicAssetTable';
import EqualColumnsTable from 'components/_modules/policy/EqualColumnsTable';
import PaymentScheduleTable from 'components/_modules/policy/PaymentScheduleTable';

import {
    GET_DATA_CHARTS,
} from 'api';

import ClinicList, { ClinicListItem, ClinicItem } from '../../components/ClinicList';

import styles from './styles.module.scss';

class PolicyPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            activeTab: 0,
            loading: true,
            graphAdditionalIncomeData: null,
            clampList: [],
            transactionID: 0,
            link: null,
        };

        this.getTabByType = this.getTabByType.bind(this);
        this.fetchGraphAdditionalIncomeData = this.fetchGraphAdditionalIncomeData.bind(this);
    }

    componentDidMount() {
        document.title = this.store.policy.policy.productName || '';

        const { policyId } = this.props.match.params;

        this.store.policy.getPolicy(policyId).then(() => {
            document.title = this.store.policy.policy.productName || '';
            this.setState({
                ...this.state,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.store.policy.clearPolicy();
    }

    getTabByType(tab, tabIndex) {
        switch (tab.type) {
        case 'investmentIncome':
            return <div key={tabIndex} className={styles.TabWrapper}>
                <InvestmentIncomeTab
                    tabIndex={tabIndex}
                    items={tab.items}
                    policyId={this.props.match.params.policyId}/>
            </div>;
        case 'additionalIncome':
            return <div key={tabIndex} className={styles.TabWrapper}>
                <AdditionalIncomeTab
                    tabIndex={tabIndex}
                    items={tab.items}/>
            </div>;
        case 'socialTaxDeduction':
            return this.store.policy.policy.hasSocialTaxDeduction
                ? <div key={tabIndex} className={styles.TabWrapper}>
                    <SocialTaxDeductionTab
                        tabIndex={tabIndex}
                        items={tab.items}
                        policyId={this.props.match.params.policyId}
                    />
                </div>
                : <div key={tabIndex}></div>;
        default:
            return <div key={tabIndex}></div>;
        }
    }

    headingBadgeData() {
        const { policy } = this.store.policy;

        if (policy.status === 'PolicyStatus_Archival' || policy.status === 'PolicyStatus_Terminated' || policy.status === 'PolicyStatus_Completed' || policy.status === 'PolicyStatus_Annulated') {
            return {
                text: 'В архиве',
                color: '#828a9f',
            };
        }

        return {
            text: 'Активный',
            color: '#95c11f',
        };
    }

    fetchGraphAdditionalIncomeData(index) {
        const { policyId } = this.props.match.params;

        GET_DATA_CHARTS(index, policyId).then((data) => {
            this.setState({
                ...this.state,
                graphAdditionalIncomeData: data,
            });
        });
    }

    render() {
        const policyFullDate = `${moment(this.store.policy.policy.contractBeginDate).format('DD.MM.YYYY')} —— ${moment(this.store.policy.policy.contractEndDate).format('DD.MM.YYYY')}`;
        return (
            <FadeTransition loading={this.state.loading}>
                <AppLayout
                    userName={this.store.profile.getUserNameField('navbar')}
                    companyCode={this.store.app.companyCode}
                >
                    {Object.keys(this.store.policy.policy).length !== 0 && (
                        <Wrapper>
                            <MainTopWrapper
                                profile={this.store.profile}>
                                <Container fluid>
                                    {window.location.pathname.includes('/supervisor/sales') ? (
                                        <div
                                            className={styles.PolicyHeaderBackButton}
                                            onClick={() => window.location.href = '/supervisor/sales'}>
                                            Полисы моих сотрудников
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.PolicyHeaderBackButton}
                                            onClick={() => window.location.href = '/sales'}>
                                            Мои полисы
                                        </div>
                                    )}
                                    <Heading
                                        level="2"
                                        color="#FFFFFF">
                                        <span style={{ marginRight: 8 }}>{this.store.policy.policy.productName}</span>
                                        <Badge style={{
                                            marginLeft: 0,
                                            background: this.headingBadgeData().color,
                                        }}>{this.headingBadgeData().text}</Badge>
                                    </Heading>

                                    <div className={styles.SideWrapper} style={{ padding: '50px 0 65px' }}>
                                        <div className={styles.SideWrapperInner}>
                                            <div className={styles.TitledContentWrapper}>
                                                <TitledContent
                                                    title={'Номер полиса'}
                                                    content={this.store.policy.policy.policyNumber}
                                                    color={'#FFFFFF'}/>
                                                <TitledContent
                                                    title={'Срок действия'}
                                                    content={policyFullDate}
                                                    color={'#FFFFFF'}/>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </MainTopWrapper>
                            <div>
                                <ToggleTabsWrapper>
                                    <Container
                                        fluid
                                        position={null}>
                                        <DetailsContentWrapper padding="0">
                                            <ToggleTabs>
                                                {this.store.policy.policy.tabs && this.store.policy.policy.tabs.map((item, index) => (
                                                    <ToggleTabsItem
                                                        key={index}
                                                        active={this.state.activeTab === index}
                                                        onClick={() => this.setState({
                                                            ...this.state,
                                                            activeTab: index,
                                                        })}>{item.title}</ToggleTabsItem>
                                                ))}
                                            </ToggleTabs>
                                        </DetailsContentWrapper>
                                    </Container>
                                </ToggleTabsWrapper>

                                {this.store.policy.policy.tabs && this.store.policy.policy.tabs.map((tab, tabIndex) => (
                                    this.state.activeTab === tabIndex && (
                                        (tab.type === 'socialTaxDeduction' || tab.type === 'investmentIncome' || tab.type === 'additionalIncome')
                                            ? this.getTabByType(tab, tabIndex)
                                            : <div
                                                key={tabIndex}
                                                style={{ padding: '0 0 50px' }}>
                                                {tab.items.map((item, itemIndex) => (
                                                    <React.Fragment key={itemIndex}>
                                                        {(item.type === 'tabHeader' && (tab.title === 'График платежей' ? (
                                                            <DetailsContentWrapper
                                                                padding="55px 0 75px"
                                                                backgroundColor="#F8F8FA"
                                                            >
                                                                <div className={styles.TitledContentWrapper}>
                                                                    {item.values.map((value, valueIndex) => (
                                                                        <TitledContent
                                                                            key={valueIndex}
                                                                            title={value.title}
                                                                            content={value.subTitle}/>
                                                                    ))}
                                                                </div>
                                                            </DetailsContentWrapper>)
                                                            : (
                                                                <DetailsContentWrapper
                                                                    padding="55px 0 75px"
                                                                    backgroundColor="#F8F8FA"
                                                                >
                                                                    <div className={styles.TitledContentWrapper}>
                                                                        {item.values.map((value, valueIndex) => (
                                                                            <TitledContent
                                                                                key={valueIndex}
                                                                                title={value.title}
                                                                                content={value.subTitle}/>
                                                                        ))}
                                                                    </div>
                                                                </DetailsContentWrapper>)))}

                                                        {item.type === 'list' && (
                                                            <DetailsContentWrapper
                                                                isToggle={item.closed}
                                                                title={item.title}
                                                            >
                                                                <BaseList className={styles.InfoList}>
                                                                    {item.values.map((value, valueIndex) => (
                                                                        <BaseListItem
                                                                            key={valueIndex}
                                                                            title={value.title}>
                                                                            {value.values.map((nestedValue, nestedValueIndex) => (
                                                                                <BaseListText key={nestedValueIndex}
                                                                                    text={nestedValue.value}
                                                                                    subText={nestedValue.subValue}/>
                                                                            ))}
                                                                        </BaseListItem>
                                                                    ))}
                                                                </BaseList>

                                                            </DetailsContentWrapper>
                                                        )}

                                                        {item.type === 'clinic_list' && (
                                                            <DetailsContentWrapper
                                                                isToggle={item.closed}
                                                                title={item.title}
                                                            >
                                                                <ClinicList className={styles.InfoList}>
                                                                    {item.values.$mobx.values.map((value, valueIndex) => (
                                                                        <ClinicListItem
                                                                            key={valueIndex}
                                                                            title={value.title}>
                                                                            {value.values.$mobx.values.map((nestedValue, nestedValueIndex) => (
                                                                                <ClinicItem key={nestedValueIndex}
                                                                                    text={nestedValue}/>
                                                                            ))}
                                                                        </ClinicListItem>
                                                                    ))}
                                                                </ClinicList>

                                                            </DetailsContentWrapper>
                                                        )}

                                                        {item.type === 'content' && (
                                                            <DetailsContentWrapper title={item.title}>
                                                                {item.values.map((value, valueIndex) => (
                                                                    <TextContent key={valueIndex}
                                                                        dangerouslySetInnerHTML={{ __html: value.value }}/>
                                                                ))}
                                                            </DetailsContentWrapper>
                                                        )}

                                                        {(item.type === 'redemption_sums_table' || item.type_table === 'redemption_sums_table') && (
                                                            <DetailsContentWrapper
                                                                title={item.title}
                                                                isToggle={item.closed}
                                                            >
                                                                <RedemptionSumsTable rows={item.rows}/>
                                                            </DetailsContentWrapper>
                                                        )}

                                                        {(item.type === 'basic_asset_table' || item.type_table === 'basic_asset_table') && (
                                                            <DetailsContentWrapper
                                                                title={item.title}
                                                                isToggle={item.closed}
                                                            >
                                                                <BasicAssetTable rows={item.rows}/>
                                                            </DetailsContentWrapper>
                                                        )}

                                                        {(item.type === 'equal_columns_table' || item.type_table === 'equal_columns_table') && (
                                                            <DetailsContentWrapper
                                                                title={item.title}
                                                                isToggle={item.closed}
                                                            >
                                                                <EqualColumnsTable rows={item.rows}/>
                                                            </DetailsContentWrapper>
                                                        )}

                                                        {(item.type === 'payment_schedule_table' || item.type_table === 'payment_schedule_table' || item.table_type === 'payment_schedule_table') && (
                                                            <DetailsContentWrapper
                                                                title={item.title}
                                                                isToggle={item.closed}
                                                            >
                                                                <PaymentScheduleTable
                                                                    years={item.years}
                                                                    policyId={this.props.match.params.policyId}
                                                                    isRecurrentEnabled={this.store.policy.isRecurrentEnabled}
                                                                    updatePaymentLink={this.updatePaymentLinkLife}
                                                                />
                                                            </DetailsContentWrapper>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                    )
                                ))}

                                {this.state.clampList.length !== 0 && (
                                    <div style={{ backgroundColor: '#F8F8FA' }}>
                                        <Container fluid>
                                            <div className={styles.SideWrapper}>
                                                <div className={styles.SideWrapperInner}>
                                                    <div style={{ marginTop: 85, marginBottom: 75 }}>
                                                        <Heading level="3">{'История изменений'}</Heading>

                                                        <BaseTable className={styles.ClampTable}>
                                                            <tbody>
                                                                {this.state.clampList.map((item, itemIndex) => (
                                                                    <tr
                                                                        key={itemIndex}
                                                                        data-status={item.status.code}
                                                                    >
                                                                        <td data-col="date">{moment(item.dateAndTime).format('DD.MM.YYYY')}</td>

                                                                        <td data-col="name">
                                                                            <span>{item.name}</span>
                                                                            <span data-rel="subTitle"></span>
                                                                        </td>

                                                                        <td data-col="status">{item.status.name}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </BaseTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </Container>
                                    </div>
                                )}
                            </div>
                        </Wrapper>
                    )}
                </AppLayout>
            </FadeTransition>
        );
    }
}

export default inject('store')(observer(PolicyPage));
