import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import Container from 'components/Container';
import MainTopWrapper from '../../components/MainTopWrapper';
import RatingList from '../../components/RatingList';
import SalesNavBar from '../../components/SalesNavBar';
import ContentWrapper from '../../components/ContentWrapper';

class RatingPage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Рейтинг продавцов',
            ratingList: this.store.profile.ratingList,
            isLoading: true,
        };
    }

    componentDidMount() {
        document.title = this.state.title;

        if (this.store.profile.ratingList.length === 0) {
            this.store.profile.getRatingList().then(() => {
                this.setState({ ratingList: this.store.profile.ratingList, isLoading: false });
            });
        } else {
            this.setState({ ratingList: this.store.profile.ratingList, isLoading: false });
        }
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <SalesNavBar additionalPoints={this.store.profile.user.additionalPoints}/>
                    <ContentWrapper isLoading={this.state.isLoading}>
                        <Container>
                            <RatingList
                                personalRating={(this.store.profile.user.personalRating) ? this.store.profile.user.personalRating : 0}
                                data={this.state.ratingList}/>
                        </Container>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(RatingPage));
