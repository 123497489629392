import React from 'react';
import ScrollContainer from 'components/ScrollContainer';
import PolicyBaseTable from '../PolicyBaseTable';
import styles from './styles.module.scss';

const EqualColumnsTable = ({
    rows,
}) => (
    <ScrollContainer>
        <PolicyBaseTable
            rows={rows}
            className={styles.EqualColumnsTable}
        />
    </ScrollContainer>
);

export default EqualColumnsTable;
