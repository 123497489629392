import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const Wrapper = ({
    topIndent,
    children,
}) => (
    <div className={cn(
        styles.Wrapper,
        { [styles.WrapperTopIndent]: topIndent },
    )}>{ children }</div>
);

export default Wrapper;
