import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import DateInputField from 'components/DateInputField';
import Button from 'components/Button';
import styles from './styles.module.scss';
import Analytics, { AnalyticItem, AnalyticListItem } from '../AnalyticsBlock';
import RadioGroup, { Radio } from '../RadioGroup';

const BRAND_COLOR = '#0d63d6';

const getCompanyName = (companyName, indexName) => {
    let name = '';
    if (companyName) {
        name = companyName;
        if (indexName) {
            name += ` (${indexName})`;
        }
    } else if (indexName) {
        name = indexName;
    }
    return name;
};

class Chart extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            companies: [],
            index: props.index,
            analytics: [],
            option: 'all',
            startDate: undefined,
            endDate: undefined,
            maxDate: undefined,
            minDate: undefined,
            to: undefined,
            from: undefined,
            color: undefined,
            chartOptions: {
                rangeSelector: {
                    enabled: false,
                },
            },
        };
    }

    isMultiply = false;

    isSingleOption = true;

    static getDerivedStateFromProps(props, state) {
        if (props.data && props.data.ANALYTICS.ITEMS && (props.data.ANALYTICS.ITEMS !== state.analytics)) {
            return { analytics: props.data.ANALYTICS.ITEMS };
        }
        return null;
    }

    handleRadioChange = (e) => {
        this.setState({
            ...this.state,
            option: e.target.value,
            to: undefined,
            from: undefined,
        }, () => {
            this.prepareChartOptions(this.props.data);
        });
    }

    onClickClear = ({ isMultiply, response }) => {
        this.setState({
            ...this.state,
            startDate: undefined,
            endDate: undefined,
        }, () => {
            if (isMultiply && this.state.startDate && this.state.endDate) {
                this.arrayFilter(response);
            }

            if (isMultiply && !this.state.startDate && !this.state.endDate) {
                this.prepareChartOptions(response);
            }
        });
    }

    onChangePlotBands = (e) => {
        const dataFrom = e.currentTarget.getAttribute('data-from').split('.');
        dataFrom[1] = Number(dataFrom[1]) - 1;
        const dataTo = e.currentTarget.getAttribute('data-to').split('.');
        dataTo[1] = Number(dataTo[1]) - 1;
        const trend = e.currentTarget.getAttribute('data-trend');
        const from = Date.UTC(dataFrom[2], dataFrom[1], dataFrom[0]);
        const to = Date.UTC(dataTo[2], dataTo[1], dataTo[0]);
        let color = (trend > 0) ? '#e2fd9d' : '#f7adadd4';
        color = (!trend) ? '#dedede85' : color;
        debugger;
        this.setState({
            ...this.state,
            from,
            to,
            color,
        }, () => {
            this.prepareChartOptions(this.props.data);
        });
    }

    onStartChange = ({ startDate, isMultiply, response }) => {
        this.setState({
            ...this.state,
            startDate,
        }, () => {
            if (isMultiply && this.state.startDate && this.state.endDate) {
                this.arrayFilter(response);
            }

            if (isMultiply && !this.state.startDate && !this.state.endDate) {
                this.prepareChartOptions(response);
            }
        });
    }

    onEndChange = ({ endDate, isMultiply, response }) => {
        this.setState({
            ...this.state,
            endDate,
        }, () => {
            if (isMultiply && this.state.startDate && this.state.endDate) {
                this.arrayFilter(response);
            }

            if (isMultiply && !this.state.startDate && !this.state.endDate) {
                this.prepareChartOptions(response);
            }
        });
    }

    arrayFilter(response) {
        const format = 'DD.MM.YYYY';
        const startDate = moment(this.state.startDate);
        const endDate = moment(this.state.endDate);

        const data = JSON.parse(JSON.stringify(response));

        data.MARKERS.Items = Object.keys(data.MARKERS.Items).filter((date) => {
            const itemDate = moment(date, format);

            return itemDate.isBetween(startDate, endDate, null, '[]');
        }).reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = data.MARKERS.Items[key];

            return obj;
        }, {});

        data.MARKERS.HighStockData = data.MARKERS.HighStockData.filter((item) => {
            const itemDate = moment(new Date((item.x) ? item.x : item[0]));

            return itemDate.isBetween(startDate, endDate, null, '[]');
        });

        data.MARKERS.Dotted_line = data.MARKERS.Dotted_line.filter((item) => {
            const itemDate = moment(new Date(item[0]));

            return itemDate.isBetween(startDate, endDate, null, '[]');
        });

        this.prepareChartOptions(data);
    }

    highChartsCallback(chart) {
        this.chart = chart;
    }

    getMultilineLineData = (props) => {
        const { index } = this.props;

        const { MARKERS: markers } = props;
        const {
            Items: items,
            HighStockData: highStockData,
            ExtraOptions: extraOptions,
            Dotted_line: dottedLine,
            isDottedLine, // Флаг пунктирной линии (нужен или нет)
            Max: { date: maxChartDate },
            Min: { date: minChartDate },
        } = markers;

        let companyCount = 0; // Количество линий

        for (const prop in items) {
            const { Value: value } = items[prop];

            companyCount = value.length;
            break;
        }

        if (!companyCount) {
            return null;
        }

        const graphicsData = [];
        const lines = highStockData.length / companyCount;
        let lineData;
        let begin = 0;
        let end = lines;
        let currentIndexName = 0;
        if (this.state.companies) currentIndexName = this.state.companies.findIndex((item) => item.indexName === this.state.option) || 0;
        const currencyIcon = ' %';
        let visible = true;

        for (let i = 0; i < companyCount; i += 1) {
            const {
                company_ticker: companyTicker,
                color_graph: colorGraph,
            } = extraOptions[i];
            if (this.state.option !== 'all' && this.state.option !== this.state.index) {
                visible = currentIndexName === i;
            }
            lineData = highStockData.slice(begin, end);
            graphicsData.push({
                name: companyTicker,
                data: lineData,
                type: 'line',
                color: colorGraph,
                tooltip: {
                    valueDecimals: 10,
                    enabled: true,
                    valueSuffix: currencyIcon,
                },
                visible,
                zIndex: 1,
            });

            begin += lines;
            end += lines;
        }

        // Вывод красной пунктирной линии на графике для всех стратегий, кроме Облигации MAX и Медицины будущего
        if (isDottedLine) {
            graphicsData.push({
                showInLegend: false,
                name: 'hidden_series',
                data: dottedLine.slice(0, lines),
                type: 'line',
                color: 'red',
                dashStyle: 'longdash',
                lineColor: 'red',
                labels: {
                    text: '100%',
                },
                tooltip: {
                    valueDecimals: 2,
                    enabled: true,
                    valueSuffix: currencyIcon,
                },
                zIndex: 1,
            });
        }

        const plotliners = [{
            color: 'red',
            width: 2,
            value: 108,
            dashStyle: 'longdash',
            tooltip: {
                useHTML: true,
            },
            label: {
                text: '108%',
            },
        }];

        return {
            chartOptions: {
                chart: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: '#80889e',
                    },

                },
                navigator: {
                    handles: {
                        backgroundColor: '#0d63d6',
                        borderColor: '#ffffff',
                        width: 20,
                        height: 20,
                    },
                    height: 80,
                    maskFill: Highcharts.Color(BRAND_COLOR).setOpacity(0.12).get('rgba'),
                    outlineColor: '#dfe2ee',
                    series: {
                        lineWidth: 2,
                        fillColor: '#ffffff',
                    },
                    xAxis: {
                        labels: {
                            enabled: false,
                        },
                        gridLineWidth: 0,
                        plotLines: plotliners,
                    },
                },
                rangeSelector: {
                    enabled: false,
                },
                xAxis: {
                    crosshair: {
                        color: '#dfe2ee',
                    },
                    gridLineColor: '#dfe2ee',
                    labels: {
                        style: {
                            color: '#80889e',
                        },
                    },
                    lineColor: '#dfe2ee',
                    tickLength: 0,
                    tickWidth: 0,
                    plotBands: [{ // mark the weekend
                        color: this.state.color,
                        from: this.state.from,
                        to: this.state.to,
                    }],
                    // one day
                    type: 'datetime',
                },
                yAxis: {
                    gridLineColor: '#dfe2ee',
                    labels: {
                        style: {
                            color: '#80889e',
                        },
                    },
                    lineWidth: 1,
                    lineColor: '#dfe2ee',
                    opposite: false,
                },
                scrollbar: {
                    enabled: false,
                },
                legend: {},
                title: {
                    text: '',
                },
                tooltip: {
                    dateTimeLabelFormats: {
                        week: ['%A, %b %e, %Y'],
                    },
                    formatter: function chartTooltip() {
                        const s = [];
                        let $date = '';

                        this.points.map((point, i, arr) => {
                            $date = point.x;
                            if (point.series.name !== 'hidden_series') {
                                let symbol = '';
                                let fixnumber = markers.numberSignsIndexStrategy;
                                let currSign = '';
                                let isMultipleGraphics = false;
                                let isShowDid = false;

                                // SWITCH оставлен, чтобы в моменте не поломмать данные
                                // Нужно сопоставить с БД и потом убрать
                                // Доработать и проверить установку isMultipleGraphics
                                switch (index) {
                                case 'Достигая максимум':
                                    isMultipleGraphics = true;
                                    isShowDid = true;
                                    break;
                                case 'SGMDROBT':
                                case 'CBKIGINF':
                                case 'SXDP':
                                case 'SOLHC':
                                case 'XLP':
                                    symbol = '';
                                    fixnumber = 6;
                                    point.series.name = index;
                                    currSign = markers.Currency;
                                    break;
                                case 'Энергогигант':
                                    symbol = '';
                                    fixnumber = 3;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'КУРС НА РОСТ':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Сельское хозяйство':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Развивающиеся рынки':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Энергетика будущего':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Медицина будущего 2.0':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Телеком Инновации':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Золото':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Нефть':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'ШП':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Лидеры рынка':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Голубые фишки':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    break;
                                case 'Бивалютный депозит':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    isShowDid = false;
                                    break;
                                case 'Лукойл':
                                    symbol = '';
                                    fixnumber = 2;
                                    currSign = markers.Currency;
                                    isMultipleGraphics = true;
                                    isShowDid = false;
                                    break;
                                default:
                                    fixnumber = 2;
                                    break;
                                }

                                if (markers.Currency_Graphics === 'PERCENT') symbol = '%';

                                const did = (typeof point.point.z !== 'undefined') ? `<br><span x="${point.x}" dy="${point.y}">Инвестдоход</span><span dx="${point.x}">: </span>`
                                    + `<span style="font-weight:bold">${point.point.z} ${currSign}<span>` : '';

                                // Если стратегия состоит из одной линии, то блок с ДИДом (инвестдоход) добавляем сразу
                                s.push(
                                    `${`<span style="fill:${point.series.color}" x="${point.x}" dy="${point.y}">●</span><span dx="${point.x}"> ${point.series.name}: </span>`
                                    + `<span style="font-weight:bold">${parseFloat(point.y).toFixed((markers.numberSignsIndexStrategy) ? markers.numberSignsIndexStrategy : fixnumber)} ${symbol}<span>`}${(isShowDid && !isMultipleGraphics) ? did : ''}`,
                                );

                                // Если стратегия состоит из нескольких линий, то блок с ДИДом (инвестдоход) добавляем на последней итерации цикла (по линиям)
                                if (isShowDid && isMultipleGraphics && arr.length - 1 === i) s.push(did);
                            }
                            return point;
                        });

                        const time = moment(new Date($date)).format('DD.MM.YYYY');

                        s.unshift(`<span>${time}</span>`);

                        return s.join(' <br> ');
                    },
                    shared: true,
                },
                series: graphicsData,
                plotOptions: {
                    series:
                        {
                            turboThreshold: 5000,
                        },
                },
            },
            min: moment(minChartDate, 'YYYY-MM-DD HH:mm').toDate(),
            max: moment(maxChartDate, 'YYYY-MM-DD HH:mm').toDate(),
        };
    };

    prepareChartOptions(data) {
        const { MULTIPLY, MARKERS } = data;
        const {
            chartOptions,
            max: maxValue,
            min: minValue,
        } = this.isMultiply ? this.getMultilineLineData(data) : this.getMultilineLineData(data);
        const maxDate = moment(maxValue, 'YYYY-MM-DD HH:mm').toDate();
        const minDate = moment(minValue, 'YYYY-MM-DD HH:mm').toDate();

        this.isMultiply = !!MULTIPLY;
        this.isSingleOption = MARKERS.ExtraOptions.length === 1;

        const companies = MARKERS.ExtraOptions.map((item) => ({
            companyName: item.company_name,
            indexName: item.company_ticker,
        }));

        this.setState({
            ...this.state,
            companies,
            maxDate,
            minDate,
            chartOptions,
        });
    }

    componentDidMount() {
        const { data } = this.props;
        if (data) {
            this.prepareChartOptions(data);
        }
    }

    render() {
        const { data } = this.props;

        return (
            <div className={styles.Chart}>
                <div className={styles.ChartFilter}>
                    <DateInputField
                        mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                        size="lg"
                        icon="calendar"
                        value={this.state.startDate}
                        format="dd.MM.yyyy"
                        placeholder="Начало"
                        minDate={this.state.minDate}
                        maxDate={this.state.endDate || this.state.maxDate}
                        onChange={(startDate) => this.onStartChange({
                            startDate,
                            isMultiply: this.isMultiply,
                            response: data,
                        })}/>

                    <DateInputField
                        mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                        size="lg"
                        icon="calendar"
                        value={this.state.endDate}
                        format="dd.MM.yyyy"
                        placeholder="Конец"
                        minDate={this.state.startDate || this.state.minDate}
                        maxDate={this.state.maxDate}
                        onChange={(endDate) => this.onEndChange({
                            endDate,
                            isMultiply: this.isMultiply,
                            response: data,
                        })}/>

                    <Button
                        buttonType="text"
                        size="lg"
                        variant="primary"
                        onClick={() => this.onClickClear({ isMultiply: this.isMultiply, response: data })}>
                        {'Сбросить'}
                    </Button>
                </div>
                {!this.isSingleOption
                    && <RadioGroup>
                        <Radio
                            value={this.state.index}
                            handleRadioChange={this.handleRadioChange}
                            checked={this.state.option === 'all' || this.state.option === this.state.index}
                        >
                            Все компании
                        </Radio>
                        {this.state.companies && this.state.companies.map((item, index) => (
                            <Radio
                                key={index}
                                value={item.indexName}
                                handleRadioChange={this.handleRadioChange}
                                checked={this.state.option === item.indexName}
                            >
                                {getCompanyName(item.companyName, item.indexName)}
                            </Radio>
                        ))}
                    </RadioGroup>
                }

                <HighchartsReact
                    callback={this.highChartsCallback}
                    options={this.state.chartOptions}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                />
                {this.state.analytics.length > 0 && this.state.analytics.find((item) => (item.NAME === ((this.state.option === 'all') ? this.state.index : this.state.option))) && <Analytics>
                    <AnalyticListItem>
                        {this.state.analytics.map((item, index) => ((item.NAME === ((this.state.option === 'all') ? this.state.index : this.state.option)) && (
                            <AnalyticItem
                                key = {index}
                                PeriodName={item.HEADING}
                                Trend={item.TREND ? item.TREND : ''}
                                startDate={item.START_DATE}
                                endDate={item.END_DATE}
                                onChangePlotBands={this.onChangePlotBands}
                                description ={item.DESCRIPTION}
                            />
                        )))}
                    </AnalyticListItem>
                </Analytics>}
            </div>
        );
    }
}

export default Chart;
