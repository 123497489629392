import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import ContentWrapper from 'components/ContentWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import MainTopWrapper from '../../components/MainTopWrapper';
import SupervisorRatingList from '../../components/SupervisorRatingList';
import { SUPERVISOR_RATING_LIST } from '../../api';
import styles from './styles.module.scss';

class SupervisorRatingPage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Рейтинг моих сотрудников',
            data: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        document.title = this.state.title;
        SUPERVISOR_RATING_LIST().then(({ data }) => {
            this.setState({ data, isLoading: false });
        });
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <ContentWrapper isLoading={this.state.isLoading}>
                        <Container>
                            <Heading className={styles.RatingListHeading} level="4">{'Рейтинг моих сотрудников'}</Heading>
                            <SupervisorRatingList
                                data={this.state.data}/>
                        </Container>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(SupervisorRatingPage));
