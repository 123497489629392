
export const getLevelAdvantages = (level, includeCoefficient = false) => {
    switch (level) {
    case 'Silver':
        return [
            'Сувенирка',
            'Техника до 5 тыс. руб.',
        ];
    case 'Gold': {
        const advantagesGold = [
            'Сувенирка',
            'Техника до 150 тыс. руб.',
            'Подарочные сертификаты: техника (Эльдорадо, м.Видео, ДНС), Ламода (до 10 тыс. руб.), Ozon и Детский мир',
            'Возможность участия в распродажах',
        ];
        if (includeCoefficient) advantagesGold.unshift('+20% к начислению уралкоинов');
        return advantagesGold;
    }
    case 'Platinum':
    {
        const advantagesPlatinum = [
            'Сувенирка',
            'Техника до 150 тыс. руб.',
            'Подарочные сертификаты: техника (Эльдорадо, м.Видео, ДНС), Ламода (до 10 тыс. руб.), Ozon и Детский мир',
            'Возможность участия в распродажах',
            'Подарочный сертификат на выбор',
            'Запросы на спец. товары (добавление любого товара в магазин на усмотрение продавца)',
        ];
        if (includeCoefficient) advantagesPlatinum.unshift('+30% к начислению уралкоинов');
        return advantagesPlatinum;
    }
    default:
        return [];
    }
};

export const getLevelAddedAdvantages = (level) => {
    switch (level) {
    case 'Silver':
        return [
            'Сувенирка',
            'Техника до 5 тыс. руб.',
        ];
    case 'Gold':
        return [
            'Подарочные сертификаты: техника (Эльдорадо, м.Видео, ДНС), Ламода (до 10 тыс. руб.), Ozon и Детский мир',
            'Возможность участия в распродажах',
        ];
    case 'Platinum':
        return [
            'Подарочный сертификат на выбор',
            'Запросы на спец. товары (добавление любого товара в магазин на усмотрение продавца)',
        ];
    default:
        return [];
    }
};
