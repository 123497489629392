import { observable, action, decorate } from 'mobx';
import { GET_NEWS } from 'api';

class NewsModule {
    constructor(props, appStore) {
        this.appStore = appStore;
    }

    news = [];

    getNews(id) {
        return GET_NEWS(id)
            .then((data) => {
                const { items } = data;
                this.news = items.sort((a, b) => (a.date < b.date ? 1 : -1));
            }).catch(() => {});
    }
}

decorate(NewsModule, {
    news: observable,
    getNews: action.bound,
});

export default NewsModule;
