import React from 'react';
import styles from './styles.module.scss';
import star from '../../assets/icons/new-icon-rating.svg';

const navigateToRating = () => {
    window.location.href = '/rating';
};

const RatingPositionSticker = ({ position }) => (
    <div className={styles.RatingPositionSticker} onClick={navigateToRating}>
        <img className={styles.RatingPositionStickerImage} src={star} alt='Coin'/>
        <div className={styles.RatingPositionStickerContent}>
            <div className={styles.RatingPositionStickerPosition}>{position}</div>
            <div className={styles.RatingPositionStickerText}>Место в рейтинге</div>
        </div>
    </div>
);

export default RatingPositionSticker;
