import React from 'react';
import parse from 'html-react-parser';
import moment from 'moment';
import styles from './styles.module.scss';
import Button from '../Button';
import arrow from '../../assets/icons/arrow-right.svg';

const NewsCard = ({
    data,
    size,
}) => (
    <div
        className={size === 'sm' ? styles.NewsCardWrapperSm : styles.NewsCardWrapperLg}
    >
        <div className={styles.NewsCardHeader}>
            {data.title}
        </div>
        <div className={styles.NewsCardDescription}>
            {parse(data.previewText)}
        </div>
        <div className={styles.NewsCardFooter}>
            <div className={styles.NewsCardDate}>
                {moment.unix(data.date).format('DD.MM.YYYY')}
            </div>
            {
                size === 'sm' ? <Button
                    variant="primary"
                    size="sm"
                    onClick={() => window.location.href = `/news/${data.id}`}
                    buttonType='default'
                    style={{ width: 40 }}>
                    <img src={arrow} alt=""/>
                </Button>
                    : <Button
                        variant="primary"
                        size="lg"
                        onClick={() => window.location.href = `/news/${data.id}`}
                        style={{ width: 278 }}
                        buttonType='default'>
                        Подробнее
                    </Button>
            }

        </div>
    </div>
);

export default NewsCard;
