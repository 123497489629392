import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import DetailsContentWrapper from 'components/_modules/wrappers/DetailsContentWrapper/index.js';
import TitledContent from 'components/TitledContent';
import TextContent from 'components/TextContent';
import TaxDeductionGeneratingCertificateBlock from 'components/_modules/policy/TaxDeductionGeneratingCertificateBlock';
import TaxDeductionGeneratedCertificatesList from 'components/_modules/policy/TaxDeductionGeneratedCertificatesList';
import Preloader from 'components/Preloader/Preloader';
import styles from './styles.module.scss';

const SocialTaxDeductionTab = ({ items, policyId, store }) => {
    const { selections, certificates, initialization, loading } = store.taxDeduction;

    useEffect(() => {
        const getData = () => {
            store.taxDeduction.getSelections(policyId).then(() => {
                store.taxDeduction.getCertificates(policyId).then(() => {
                    store.taxDeduction.setInitialization(true);
                });
            });
        };

        if (initialization === false) {
            getData();
        } else {
            store.taxDeduction.setLoading(false);
        }
    }, [initialization, policyId, store.taxDeduction]);

    return (
        loading
            ? <DetailsContentWrapper>
                <div className={styles.PreloaderWrapper}>
                    <Preloader embedded/>
                </div>
            </DetailsContentWrapper>
            : <React.Fragment>
                {items.map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                        {(item.type === 'tabHeader' && (
                            <DetailsContentWrapper
                                padding="55px 0 75px"
                                backgroundColor="#F8F8FA"
                            >
                                <div className={styles.TitledContentWrapper}>
                                    {item.values.map((value, valueIndex) => (
                                        <TitledContent
                                            key={valueIndex}
                                            title={value.title}
                                            content={value.subTitle}/>
                                    ))}
                                </div>
                            </DetailsContentWrapper>))}
                        {item.type === 'content' && (
                            <DetailsContentWrapper title={item.title}>
                                {item.values.map((value, valueIndex) => (
                                    <TextContent key={valueIndex}
                                        dangerouslySetInnerHTML={{ __html: value.value }}/>
                                ))}
                            </DetailsContentWrapper>
                        )}
                        {item.type === 'tax_deduction_generating_certificate_block' && (
                            <DetailsContentWrapper title={item.title}>
                                <TaxDeductionGeneratingCertificateBlock
                                    store={store}
                                    policyId={policyId}
                                    policyNumber={store.policy.policy.policyNumber}
                                    selections={selections}
                                />
                            </DetailsContentWrapper>
                        )}
                        {item.type === 'tax_deduction_generated_certificates_list' && certificates.length > 0 && (
                            <DetailsContentWrapper title={item.title}>
                                <TaxDeductionGeneratedCertificatesList
                                    certificates={certificates}
                                />
                            </DetailsContentWrapper>
                        )}
                    </React.Fragment>
                ))}
            </React.Fragment>
    );
};

export default inject('store')(observer(SocialTaxDeductionTab));
