import React from 'react';
import styles from './styles.module.scss';
import ProductCard from '../ProductCard';

const ShopList = ({ data }) => (
    <div className={styles.ShopListContainer}>
        {data.map((item, index) => (
            <ProductCard
                key={index}
                data={item}
            />
        ))}
    </div>
);

export default ShopList;
