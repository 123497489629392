import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const ClinicList = ({
    className,
    children,
}) => (
    <div className={cn(styles.BaseList, className)}>
        { children }
    </div>
);

export const ClinicListItem = ({
    title,
    children,
}) => (
    <div className={styles.BaseListItem}>
        <div className={styles.BaseListTitle}>{ title }</div>
        <div className={styles.BaseListItemInner}>
            { children }
        </div>
    </div>
);


export const ClinicItem = ({
    text,
}) => (
    <div className={styles.BaseListText}>
        { text }
    </div>
);

export default ClinicList;
