import { observable, action, decorate } from 'mobx';

class AppModule {
    modalData = null;

    isShowSurveyModal = true;

    setModalData(data) {
        this.modalData = data;
    }

    setIsShowSurveyModal(data) {
        this.isShowSurveyModal = data;
    }
}

decorate(AppModule, {
    modalData: observable,
    isShowSurveyModal: observable,
    setModalData: action.bound,
    setIsShowSurveyModal: action.bound,
});

export default AppModule;
