import React from 'react';
import styles from './styles.module.scss';
import Button from '../Button';

const FilterWrapper = ({
    text = 'Фильтровать',
    children,
    filterClick,
}) => (
    <div className={styles.FilterWrapper}>
        {children}
        <Button variant="primary" size="lg" buttonType='default' onClick={filterClick}>{text}</Button>
    </div>
);

export default FilterWrapper;
