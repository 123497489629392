import React, { Component } from 'react';
import styles from './styles.module.scss';
import SupervisorRatingItem from '../SupervisorRatingItem';
import { SUPERVISOR_RATING_LIST } from '../../api';
import Button from '../Button';

class SupervisorRatingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: this.props.data,
            officeOptions: [],
            territorialDirectorateOptions: [],
            macroRegionOptions: [],
            filterOffice: '',
            filterTerritorialDirectorate: '',
            filterMacroRegion: '',
            sortDirectionRating: 'ascending',
            sortDirectionPoints: 'ascending',
            sortDirectionBonus: 'ascending',
        };
    }

    componentDidMount() {
        const officeOptions = this.state.tableData.reduce((acc, item) => {
            if (acc.includes(item.region.office)) {
                return acc;
            }
            return [...acc, item.region.office];
        }, []);
        const territorialDirectorateOptions = this.state.tableData.reduce((acc, item) => {
            if (acc.includes(item.region.territorialDirectorate)) {
                return acc;
            }
            return [...acc, item.region.territorialDirectorate];
        }, []);
        const macroRegionOptions = this.state.tableData.reduce((acc, item) => {
            if (acc.includes(item.region.macroRegion)) {
                return acc;
            }
            return [...acc, item.region.macroRegion];
        }, []);
        this.setState({ officeOptions, territorialDirectorateOptions, macroRegionOptions });
    }

    filterTable() {
        SUPERVISOR_RATING_LIST(this.state.filterOffice, this.state.filterTerritorialDirectorate, this.state.filterMacroRegion).then(({ data }) => {
            this.setState({ data, tableData: data });
        });
    }

    sortByRank() {
        if (this.state.sortDirectionRating === 'ascending') {
            this.setState(
                {
                    tableData: this.state.tableData.sort((a, b) => (a.rank < b.rank ? 1 : -1)),
                    sortDirectionRating: 'descending',
                },
            );
        } else {
            this.setState({
                tableData: this.state.tableData.sort((a, b) => (a.rank > b.rank ? 1 : -1)),
                sortDirectionRating: 'ascending',
            });
        }
    }

    sortByPremium() {
        if (this.state.sortDirectionBonus === 'ascending') {
            this.setState(
                {
                    tableData: this.state.tableData.sort((a, b) => (a.totalInsurancePremiumRuble < b.totalInsurancePremiumRuble ? 1 : -1)),
                    sortDirectionBonus: 'descending',
                },
            );
        } else {
            this.setState({
                tableData: this.state.tableData.sort((a, b) => (a.totalInsurancePremiumRuble > b.totalInsurancePremiumRuble ? 1 : -1)),
                sortDirectionBonus: 'ascending',
            });
        }
    }

    sortByPoints() {
        if (this.state.sortDirectionPoints === 'ascending') {
            this.setState(
                {
                    tableData: this.state.tableData.sort((a, b) => (a.awarderdPoints < b.awarderdPoints ? 1 : -1)),
                    sortDirectionPoints: 'descending',
                },
            );
        } else {
            this.setState({
                tableData: this.state.tableData.sort((a, b) => (a.awarderdPoints > b.awarderdPoints ? 1 : -1)),
                sortDirectionPoints: 'ascending',
            });
        }
    }

    render() {
        return (
            <>
                <div className={styles.RatingListFilter}>
                    <label>
                        Выбрать точку продаж
                        <select
                            value={this.state.filterOffice}
                            onChange={(event) => this.setState({ filterOffice: event.target.value })}
                        >
                            <option value=''>Выбрать точку продаж</option>
                            {this.state.officeOptions.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                        </select>
                    </label>
                    <label>
                        Выбрать ТД
                        <select
                            value={this.state.filterTerritorialDirectorate}
                            onChange={(event) => this.setState({ filterTerritorialDirectorate: event.target.value })}
                        >
                            <option value=''>Выбрать ТД</option>
                            {this.state.territorialDirectorateOptions.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                        </select>
                    </label>
                    <label>
                        Выбрать макрорегион
                        <select
                            value={this.state.filterMacroRegion}
                            onChange={(event) => this.setState({ filterMacroRegion: event.target.value })}
                        >
                            <option value=''>Выбрать Макрорегион</option>
                            {this.state.macroRegionOptions.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                        </select>
                    </label>
                    <Button variant="primary" size="lg" buttonType='default' onClick={() => this.filterTable()}>{'Фильтровать'}</Button>
                </div>
                <table className={styles.RatingListTable}>
                    <thead className={styles.RatingListTableHeader}>
                        <tr>
                            <th>ФИО продавца</th>
                            <th>
                                <div className={styles.SortColumnBlock}>
                                Место в рейтинге
                                    <div className={styles.SortColumnButton}
                                        onClick={this.sortByRank.bind(this)}/>
                                </div>
                            </th>
                            <th>Точка продаж</th>
                            <th>
                                <div className={styles.SortColumnBlock}>
                                Премия
                                    <div className={styles.SortColumnButton}
                                        onClick={this.sortByPremium.bind(this)}/>
                                </div>
                            </th>
                            <th>
                                <div className={styles.SortColumnBlock}>
                                Баллы
                                    <div className={styles.SortColumnButton}
                                        onClick={this.sortByPoints.bind(this)}/>
                                </div>
                            </th>
                            <th>Территориальная дирекция</th>
                            <th>Макрорегион</th>
                        </tr>
                    </thead>
                    <tbody className={styles.RatingListTableBody}>
                        {this.state.tableData.map((item, index) => (
                            <SupervisorRatingItem
                                key={index}
                                data={item}/>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }
}

export default SupervisorRatingList;
