import React from 'react';
import styles from './styles.module.scss';
import attention from '../../assets/icons/attention_gray_transparent.svg';
import ToolTip from '../ToolTip';

const RatingItem = ({ firstIndex, lastIndex, data }) => (
    <div className={styles.RatingItemWrapper}>
        <div className={styles.RatingItemWrapperField_Mobile}>
            <div className={styles.RatingItemWrapperFieldName_Mobile}>{'ФИО продавца'}</div>
            <div className={styles.RatingItemUserName}
                data-index-first={firstIndex}
                data-index-last={lastIndex}>
                {data.userName}
            </div>
        </div>
        <div className={styles.RatingItemWrapperField_Mobile}>
            <div className={styles.RatingItemWrapperFieldName_Mobile}>{'Рейтинг'}</div>
            <div className={styles.RatingItemRank}
                data-index-last={lastIndex}>
                <div className={styles.RankItem}
                    data-rank={data.rank}>
                    {`${data.rank} место`}
                </div>
            </div>
        </div>
        <div className={styles.RatingItemWrapperField_Mobile}>
            <div className={styles.RatingItemWrapperFieldName_Mobile}>{'Макрорегион'}</div>
            <div className={styles.RatingItemOffice}
                data-index-last={lastIndex}>
                {data.macroRegion}
            </div>
        </div>
        <div className={styles.RatingItemWrapperField_Mobile}>
            <div className={styles.RatingItemWrapperFieldName_Mobile}>{'Премия'}</div>
            <div className={styles.RatingItemPremium}
                data-index-last={lastIndex}>
                {`${data.totalInsurancePremiumRuble.toLocaleString()} Р`}
            </div>
        </div>
        <div className={styles.RatingItemWrapperField_Mobile}>
            <div className={styles.RatingItemWrapperFieldName_Mobile}>{'Баллы: '}</div>
            <div className={styles.RatingItemPoints}
                data-index-first={firstIndex}
                data-index-last={lastIndex}>
                {`${data.awardedPoints.toLocaleString()} У`}
                <ToolTip
                    content={
                        <div className={styles.TooltipContent}>
                            <img src={attention} alt="" className={styles.ToolTipContentIcon}/>
                            {'Баллы начислены и доступны к списанию'}
                        </div>
                    }
                    trigger='mouseenter focus'
                    placement='bottom'>
                    <div className={styles.RatingItemPointsAvailable}/>
                </ToolTip>
            </div>
        </div>
    </div>
);

export default RatingItem;
