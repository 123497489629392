import React, { forwardRef } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const Button = forwardRef(({
    buttonType = 'default',
    variant,
    size,
    maxWidth,
    loading = false,
    children,
    to,
    href,
    style,
    className,
    type = 'button',
    ...rest
}, ref) => {
    const typeClass = () => {
        switch (buttonType) {
        case 'text':
            return styles.ButtonIsText;
        default:
            return styles.ButtonIsDefault;
        }
    };

    const sizeClass = () => {
        switch (size) {
        case 'lg':
            return styles.ButtonSizeLg;
        case 'sm':
            return styles.ButtonSizeSm;
        default:
            return null;
        }
    };

    const variantClass = () => {
        switch (variant) {
        case 'primary':
            return styles.ButtonPrimary;
        case 'primaryOutline':
            return cn(styles.ButtonPrimary, styles.ButtonOutline);
        case 'primaryReverse':
            return cn(styles.ButtonPrimary, styles.ButtonReverse);
        case 'white':
            return styles.ButtonWhite;
        case 'whiteOutline':
            return cn(styles.ButtonWhite, styles.ButtonOutline);
        case 'secondary':
            return styles.ButtonSecondary;
        case 'danger':
            return styles.ButtonDanger;
        case 'gray':
            return styles.ButtonGray;
        case 'grayOutline':
            return cn(styles.ButtonGray, styles.ButtonOutline);
        default:
            return null;
        }
    };

    const buttonStyle = () => ({
        ...style,
        ...(maxWidth && {
            width: '100%',
            maxWidth,
        }),
    });

    let ButtonComponent = 'button';

    if (to) {
        ButtonComponent = Link;
    }

    if (href) {
        ButtonComponent = 'a';
    }

    return (
        <ButtonComponent
            ref={ref}
            type={type}
            to={to}
            href={href}
            className={cn(styles.Button, typeClass(), sizeClass(), variantClass(), className)}
            style={{ ...buttonStyle() }}
            {...(href && { target: '_blank' })}
            {...rest}>
            {children}
            {loading && <div className={styles.ButtonLoading}/>}
        </ButtonComponent>
    );
});

export default Button;
