import React from 'react';
import ScrollContainer from 'components/ScrollContainer';
import BaseTable from 'components/BaseTable';
import styles from './styles.module.scss';

const BasicAssetTable = ({
    rows,
}) => (
    <ScrollContainer>
        <BaseTable className={styles.BasicAssetTable}>
            <thead>
                {rows.map((row, rowIndex) => (
                    row.row_type === 'table_header' && (
                        <tr key={rowIndex}>
                            {row.columns.filter((column) => column.title && column.title !== 'null').map((column, columnIndex) => (
                                <th key={columnIndex}>{column.title}</th>
                            ))}
                        </tr>
                    )
                ))}
            </thead>

            <tbody>
                {rows.map((row, rowIndex) => (
                    (
                        row.row_type === 'table_body'
                    || row.row_type === 'table_highlighted_body'
                    ) && (
                        <tr
                            key={rowIndex}
                        >
                            {row.columns.map((column, columnIndex) => (
                                <td key={columnIndex}>
                                    <span>{ column.title }</span>
                                    {column.subtitle && <span data-rel="subTitle">{ column.subtitle }</span>}
                                </td>
                            ))}
                        </tr>
                    )
                ))}
            </tbody>
        </BaseTable>
    </ScrollContainer>
);

export default BasicAssetTable;
