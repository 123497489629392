import React from 'react';
import { inject, observer } from 'mobx-react';
import MainHeader from 'components/MainHeader';
import Footer from 'components/Footer';
import LoadingBar from 'components/LoadingBar';
import Modal from 'components/Modal';
import cookies from 'js-cookie';
import styles from './styles.module.scss';
import LevelUpgradeModal from '../../components/LevelUpgradeModal';
import SurveyModal from '../../components/SurveyModal';
import { HIDE_PROMOTION_MESSAGE } from '../../api';

class AppLayout extends React.PureComponent {
    constructor(props) {
        super(props);

        this.store = props.store;
        this.state = {
            isShowLevelUpgradeModal: false,
        };
        this.handleCloseLevelUpgradeModal = this.handleCloseLevelUpgradeModal.bind(this);
        this.handleCloseSurveyModal = this.handleCloseSurveyModal.bind(this);

        this.store.app.setIsShowSurveyModal((JSON.parse(cookies.get('isShowSurveyModal')
            || (JSON.parse(cookies.get('showSurveyModalCount') || 0) < 2))));
    }

    handleCloseLevelUpgradeModal() {
        this.store.profile.setLevelUpgradeModalShown(true);
        this.setState({ isShowLevelUpgradeModal: false }, () => HIDE_PROMOTION_MESSAGE().then(() => {
            this.store.profile.getClientInfo();
        }).catch((error) => {
            console.log(error.message);
        }));
    }

    handleCloseSurveyModal() {
        this.store.app.setIsShowSurveyModal(false);
        cookies.set('isShowSurveyModal', false);
        cookies.set('showSurveyModalCount', JSON.parse(cookies.get('showSurveyModalCount') || 0) + 1, {
            expires: 10000,
        });
    }

    componentDidMount() {
        if (!this.store.profile.levelUpgradeModalShown && this.store.profile.user.isSeller) {
            this.setState({ isShowLevelUpgradeModal: this.store.profile.user.level.showPromotionMessage });
        }
    }

    render() {
        const {
            userName,
            companyCode,
            hasBackground = true,
            children,
        } = this.props;

        return (
            <div className={styles.AppLayout}>
                <LoadingBar
                    started={!!(this.store.loading.startsCount)}
                    value={this.store.loading.value}
                />

                <MainHeader
                    hasBackground={hasBackground}
                    isSeller={this.store.profile.user.isSeller}
                    isSupervisor={this.store.profile.user.isSupervisor}
                    userName={userName}
                    profileImageUrl={this.store.profile.profileImageUrl}
                />

                {this.store.app.modalData && (
                    <Modal
                        title={this.store.app.modalData.title}
                        body={<p>{ this.store.app.modalData.body }</p>}
                        footer={this.store.app.modalData.footer}
                        handleClose={() => this.store.app.setModalData(null)}
                    />
                )}
                {this.state.isShowLevelUpgradeModal && (
                    <LevelUpgradeModal
                        level={this.store.profile.user.level.currentPeriod.level}
                        handleClose={this.handleCloseLevelUpgradeModal}
                    />
                )}
                {
                    this.store.app.isShowSurveyModal && (
                        <SurveyModal
                            handleClose={this.handleCloseSurveyModal}/>
                    )}
                { children }

                <Footer companyCode={companyCode} />
            </div>
        );
    }
}

export default inject('store')(observer(AppLayout));
