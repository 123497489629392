import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import blue from '../../assets/icons/stonks_blue.svg';
import green from '../../assets/icons/stonks_green.svg';

const getIcon = (name) => {
    switch (name) {
    case 'НСЖ': return blue;
    case 'ИСЖ': return green;
    default: return null;
    }
};

const getHeaderStyle = (name) => {
    switch (name) {
    case 'НСЖ': return styles.CardHeaderTitle_Blue;
    case 'ИСЖ': return styles.CardHeaderTitle_Green;
    default: return null;
    }
};

const BonusCard = ({ data: { name, bonus } }) => (
    <div className={styles.BonusCard}>
        <div className={styles.CardHeader}>
            <div className={cn(styles.CardHeaderTitle, getHeaderStyle(name))}>
                {name}
                <img src={getIcon(name)} alt=""/>
            </div>
        </div>
        <div className={styles.Bonus}>
            <div className={styles.BonusSizeLabel}>{'Размер премии'}</div>
            <div className={styles.BonusSize}>{`${bonus.toLocaleString()} ₽`} </div>
        </div>
    </div>
);

export default BonusCard;
