import React from 'react';
import styles from './styles.module.scss';
import Preloader from '../Preloader/Preloader';

const ContentWrapper = ({
    children,
    isLoading,
}) => (
    <div className={styles.ContentWrapper}>
        {isLoading && (<div className={styles.PreloaderWrapper}><Preloader/></div>)}
        {!isLoading && children}
    </div>
);

export default ContentWrapper;
