import React, { Component } from 'react';
import Heading from 'components/Heading';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import styles from './styles.module.scss';
import FilterWrapper from '../FilterWrapper';
import MaskedInputField from '../MaskedInputField';
import RatingItem from '../RatingItem';
import RatingPositionCard from '../RatingPositionCard';

class RatingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            filter: {
                premiumFrom: '',
                premiumTo: '',
                pointsFrom: '',
                pointsTo: '',
            },
            sorting: {
                rating: {
                    fieldName: 'rank',
                    ascendingDirection: true,
                },
                premium: {
                    fieldName: 'totalInsurancePremiumRuble',
                    ascendingDirection: true,
                },
                points: {
                    fieldName: 'awardedPoints',
                    ascendingDirection: true,
                },
            },
        };
    }

    filterTable() {
        let { data } = this.props;
        if (this.state.filter.premiumFrom) {
            data = data.filter((item) => item.totalInsurancePremiumRuble >= this.state.filter.premiumFrom.replace(/[^0-9]/g, ''));
        }
        if (this.state.filter.premiumTo) {
            data = data.filter((item) => item.totalInsurancePremiumRuble <= this.state.filter.premiumTo.replace(/[^0-9]/g, ''));
        }
        if (this.state.filter.pointsFrom) {
            data = data.filter((item) => item.awardedPoints >= this.state.filter.pointsFrom.replace(/[^0-9]/g, ''));
        }
        if (this.state.filter.pointsTo) {
            data = data.filter((item) => item.awardedPoints <= this.state.filter.pointsTo.replace(/[^0-9]/g, ''));
        }
        this.setState({ data });
    }

    sortTable(field) {
        const { fieldName, ascendingDirection } = this.state.sorting[field];
        if (ascendingDirection) {
            this.setState(
                {
                    data: this.state.data.sort((a, b) => (a[fieldName] < b[fieldName] ? 1 : -1)),
                    sorting: { ...this.state.sorting, [field]: { ...this.state.sorting[field], ascendingDirection: false } },
                },
            );
        } else {
            this.setState(
                {
                    data: this.state.data.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1)),
                    sorting: { ...this.state.sorting, [field]: { ...this.state.sorting[field], ascendingDirection: true } },
                },
            );
        }
    }

    render() {
        const premiumMask = createNumberMask({
            prefix: '',
            suffix: ' Р',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ' ',
            allowDecimal: false,
            integerLimit: 10,
        });
        const pointsMask = createNumberMask({
            prefix: '',
            suffix: ' У',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ' ',
            allowDecimal: false,
            integerLimit: 10,
        });
        return (
            <div className={styles.RatingListWrapper}>
                <Heading className={styles.RatingListHeading} level="4">{'Рейтинг продавцов'}</Heading>
                <div className={styles.RatingPositionCardWrapper}>
                    <RatingPositionCard position={this.props.personalRating}/>
                </div>
                <div className={styles.RatingListFilterWrapper}>
                    <FilterWrapper
                        filterClick={this.filterTable.bind(this)}
                    >
                        <div>
                            <label className={styles.FilterInputLabel} data-label-inside={true}>от</label>
                            <MaskedInputField
                                mask={premiumMask}
                                className={styles.FilterInput}
                                label='Страховая премия'
                                size="lg"
                                value={this.state.filter.premiumFrom}
                                onChange={(value) => {
                                    this.setState({ ...this.state, filter: { ...this.state.filter, premiumFrom: value.target.value } });
                                }}
                            />
                        </div>
                        <div>
                            <label className={styles.FilterInputLabel} data-label-inside={false}>до</label>
                            <MaskedInputField
                                mask={premiumMask}
                                className={styles.FilterInput}
                                label=''
                                size="lg"
                                value={this.state.filter.premiumTo}
                                onChange={(value) => {
                                    this.setState({ ...this.state, filter: { ...this.state.filter, premiumTo: value.target.value } });
                                }}
                            />
                        </div>
                        <div>
                            <label className={styles.FilterInputLabel} data-label-inside={true}>от</label>
                            <MaskedInputField
                                mask={pointsMask}
                                className={styles.FilterInput}
                                label='Баллы'
                                size="lg"
                                value={this.state.filter.pointsFrom}
                                onChange={(value) => {
                                    this.setState({ ...this.state, filter: { ...this.state.filter, pointsFrom: value.target.value } });
                                }}
                            />
                        </div>
                        <div>
                            <label className={styles.FilterInputLabel} data-label-inside={false}>до</label>
                            <MaskedInputField
                                mask={pointsMask}
                                className={styles.FilterInput}
                                label=''
                                size="lg"
                                value={this.state.filter.pointsTo}
                                onChange={(value) => this.setState({ ...this.state, filter: { ...this.state.filter, pointsTo: value.target.value } })}
                            />
                        </div>
                    </FilterWrapper>
                </div>
                <div className={styles.RatingListTable}>
                    <div className={styles.RatingListTableHeader}>
                        <div className={styles.TableHeaderUserName}>{'ФИО продавца'}</div>
                        <div className={styles.TableHeaderRank}>
                            <div className={styles.SortColumnBlock}>
                                {'Рейтинг'}
                                <div className={styles.SortColumnButton}
                                    onClick={() => this.sortTable('rating')}/>
                            </div>
                        </div>
                        <div className={styles.TableHeaderOffice}>{'Макрорегион'}</div>
                        <div className={styles.TableHeaderPremium}>
                            <div className={styles.SortColumnBlock}>
                                {'Премия'}
                                <div className={styles.SortColumnButton}
                                    onClick={() => this.sortTable('premium')}/>
                            </div>
                        </div>
                        <div className={styles.TableHeaderPoints}>
                            <div className={styles.SortColumnBlock}>
                                {'Баллы'}
                                <div className={styles.SortColumnButton}
                                    onClick={() => this.sortTable('points')}/>
                            </div>
                        </div>
                    </div>
                    {this.state.data.map((item, index) => (
                        <RatingItem
                            key={index}
                            firstIndex={index === 0}
                            lastIndex={index + 1 === this.state.data.length}
                            data={item}/>
                    ))}
                </div>
            </div>
        );
    }
}

export default RatingList;
