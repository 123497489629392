import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import ContentWrapper from 'components/ContentWrapper';
import Container from 'components/Container';
import CartList from 'components/CartList';
import MainTopWrapper from '../../components/MainTopWrapper';
import { BASKET_LIST } from '../../api';

class CartPage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Личный кабинет продавца',
            basketList: [],
            basket: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        document.title = this.state.title;
        BASKET_LIST().then((data) => {
            this.setState({ basketList: data.items, basket: data.basket, isLoading: false });
        });
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <ContentWrapper isLoading={this.state.isLoading}>
                        <Container>
                            <CartList
                                basketList={this.state.basketList}
                                basket={this.state.basket}
                                pointsAvailable={(this.store.profile.user.balance) ? this.store.profile.user.balance.available : 0}/>
                        </Container>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(CartPage));
