import React from 'react';
import styles from './styles.module.scss';

const ScrollContainer = ({
    children,
    ...rest
}) => (
    <div
        className={styles.ScrollContainer}
        {...rest}
    >
        { children }
    </div>
);

export default ScrollContainer;
