import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import negative from '../../assets/icons/analytic-negative.png';
import positive from '../../assets/icons/analytic-positive.png';

const Analytics = ({
    children,
}) => (
    <div className={styles.InnerWrapper}>
        <h2>Аналитика</h2>
        { children }
    </div>
);

export const AnalyticListItem = ({
    children,
}) => (
    <div className={styles.Items}>
        { children }
    </div>
);

export const AnalyticItem = ({
    PeriodName,
    Trend,
    startDate,
    endDate,
    onChangePlotBands,
    description,
}) => (
    <div className={styles.Wrapper} data-to={endDate} data-from={startDate} data-trend = { Trend } onClick={(e) => onChangePlotBands(e)}>
        <div className={styles.TopWrapper}>
            <div className={styles.PeriodName}>{ PeriodName }</div>
            {Trend && (Trend > 0
                ? <div className={styles.Trend}>
                    <img
                        className={styles.TrendIcon}
                        src={positive}
                        alt={'positiveTrend'}
                    />
                    <div className={cn(styles.TrendValue, styles.Positive)}>{`+${Trend}%`}</div>
                </div>
                : <div className={styles.Trend}>
                    <img
                        className={styles.TrendIcon}
                        src={negative}
                        alt={'negativeTrend'}
                    />
                    <div className={cn(styles.TrendValue, styles.Negative)}>{`${Trend}%`}</div>
                </div>)}

        </div>
        <div className={styles.Text} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
);

export default Analytics;
