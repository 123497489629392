import React from 'react';
import cn from 'classnames';
import Button from 'components/Button';
import { inject, observer } from 'mobx-react';
import styles from './styles.module.scss';
import LevelUpgradeSilver from '../../assets/icons/profile/Silver/LevelUpgradeSilver.png';
import LevelUpgradeGold from '../../assets/icons/profile/Gold/LevelUpgradeGold.png';
import LevelUpgradePlatinum from '../../assets/icons/profile/Platinum/LevelUpgradePlatinum.png';
import { getLevelAdvantages } from '../../helpers/advantages.js';

function getLevelUpgradeImage(level) {
    switch (level) {
    case 'Silver':
        return LevelUpgradeSilver;
    case 'Gold':
        return LevelUpgradeGold;
    case 'Platinum':
        return LevelUpgradePlatinum;
    default:
        return [];
    }
}

class LevelUpgradeModal extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
    }

    render() {
        const { level, handleClose } = this.props;
        const advantagesList = getLevelAdvantages(level, true).map((advantageItem, index) => <div
            key={index}
            className={styles.AdvantagesItem}>{advantageItem}</div>);
        return (
            <div className={styles.ModalContainer}>
                <div
                    className={styles.ModalOverlay}
                    onClick={handleClose}></div>
                <div className={cn(styles.ModalWrapper)}>
                    <div
                        className={styles.ModalClose}
                        onClick={handleClose}
                    />

                    <div className={styles.ModalHead}>
                        <div className={styles.ModalTitle}>
                            {'Поздравляем!'}
                        </div>
                    </div>
                    <div className={styles.ModalContent}>
                        <div className={styles.ModalContentNewLevel_Wrapper__Mobile}>
                            <div className={styles.ModalContentNewLevel_Wrapper}>
                                <div className={styles.ModalContentNewLevel_Title}>
                                    {'Вы получили новый уровень:'}
                                </div>
                                <div className={cn(styles.ModalContentNewLevel_Name,
                                    styles[`ModalContentNewLevel_Name_${level}`])}>
                                    {this.store.profile.getUserLevelPlanet('levelPlanet')}
                                </div>
                                <div className={cn(styles.ModalContentNewLevel_AdvantagesWrapper, styles.ModalContentNewLevel_AdvantagesWrapper__Desktop)}>
                                    {advantagesList}
                                </div>
                            </div>
                            <div className={styles.ModalContentNewLevel_Image}>
                                <img
                                    src={getLevelUpgradeImage(level)}
                                    alt={'Изображение повышения уровня'}
                                />
                            </div>
                        </div>
                        <div className={cn(styles.ModalContentNewLevel_AdvantagesWrapper, styles.ModalContentNewLevel_AdvantagesWrapper__Mobile)}>
                            {advantagesList}
                        </div>
                    </div>

                    <div className={styles.ModalButton}>
                        <Button
                            size={'lg'}
                            variant={'primary'}
                            style={{ height: 44, width: '45%' }}
                            onClick={handleClose}
                        >
                            {'ОК'}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default inject('store')(observer(LevelUpgradeModal));
