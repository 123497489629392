import React from 'react';
import cn from 'classnames';
import Button from 'components/Button';
import styles from './styles.module.scss';
import surveyBackground from '../../assets/images/surveyBackground.png';
import surveyQRCode from '../../assets/images/surveyQRCode.png';

class SurveyModal extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
    }

    render() {
        const url = 'https://opros-udovletvorennosti-po-kosmo.testograf.ru';
        const { handleClose } = this.props;
        return (
            <div className={styles.ModalContainer}>
                <div
                    className={styles.ModalOverlay}
                    onClick={handleClose}></div>
                <div className={cn(styles.ModalWrapper)}>
                    <div
                        className={styles.ModalClose}
                        onClick={handleClose}
                    />

                    <div className={styles.ModalHead}>
                        <div className={styles.ModalSurveyBackground}>
                            <img className={styles.ModalSurveyBackgroundImage} src={surveyBackground} alt=""/>
                        </div>
                        <div className={styles.ModalTitle}>
                            {'Уважаемый космонавт'}
                        </div>
                        <div className={styles.ModalSubtitle}>
                            {'Просим дать обратную связь о нашем портале'}
                        </div>
                    </div>

                    <div className={styles.ModalButton}>
                        <Button
                            size={'lg'}
                            variant={'primary'}
                            style={{ height: 50, width: '45%' }}
                            onClick={() => window.open(url, '_blank')}
                        >
                            {'Пройти опрос'}
                        </Button>
                    </div>

                    <div className={styles.ModalQRCode}>
                        <img className={styles.ModalQRCodeImage} src={surveyQRCode} alt=""/>
                    </div>
                </div>
            </div>
        );
    }
}

export default SurveyModal;
