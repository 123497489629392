import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import ToolTip from '../ToolTip';
import styles from './styles.module.scss';
import attention from '../../assets/icons/attention_gray_transparent.svg';
import completed from '../../assets/icons/success-green.svg';

const SupervisorPolicyItem = ({ firstIndex, lastIndex, data }) => (
    <div className={styles.PolicyItemWrapper}>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'№ договора: '}</div>
            <div className={styles.PolicyItemNumber}
                data-index-first={firstIndex}
                data-index-last={lastIndex}
                onClick={() => window.location.href = `/supervisor/sales/policy/${data.policyId}`}
            >
                {data.number}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Продукт: '}</div>
            <div className={styles.PolicyItemProgram}
                data-index-last={lastIndex}>
                {data.program}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Тип: '}</div>
            <div className={cn(styles.PolicyItemType, data.insuranceType === 'НСЖ' ? styles.TypeBlue : styles.TypeGreen)}
                data-index-last={lastIndex}>
                {data.insuranceType}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Статус: '}</div>
            <div className={styles.PolicyItemStatus}
                data-index-last={lastIndex}>
                <img src={completed} alt=""/>
                {data.status}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Заключен: '}</div>
            <div className={styles.PolicyItemDate}
                data-index-last={lastIndex}>
                {moment(data.date).format('DD.MM.YYYY')}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Действует с: '}</div>
            <div className={styles.PolicyItemBeginDate}
                data-index-last={lastIndex}>
                {moment(data.beginDate).format('DD.MM.YYYY')}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Окончание: '}</div>
            <div className={styles.PolicyItemEndDate}
                data-index-last={lastIndex}>
                {moment(data.endDate).format('DD.MM.YYYY')}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Премия: '}</div>
            <div className={styles.PolicyItemPremium}
                data-index-last={lastIndex}>
                {data.insurancePremiumRub.toLocaleString()}&nbsp;Р
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Стратегия: '}</div>
            <div className={styles.PolicyItemStrategy}
                data-index-last={lastIndex}>
                {data.strategy}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Баллы: '}</div>
            <div className={styles.PolicyItemPoints}
                data-index-last={lastIndex}>
                {data.points.amount.toLocaleString()}
                <ToolTip
                    content={
                        <div className={styles.TooltipContent}>
                            <img src={attention} alt="" className={styles.ToolTipContentIcon}/>
                            {data.points.prompt}
                        </div>
                    }
                    trigger='mouseenter focus'
                    placement='bottom'>
                    <div className={data.points.status === 'Available'
                        ? styles.PolicyItemPointsAvailable : styles.PolicyItemPointsUnavailable}>
                    </div>
                </ToolTip>
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'ФИО сотрудника: '}</div>
            <div className={styles.PolicyItemEmployeeFullName}
                data-index-last={lastIndex}>
                {data.sellerName}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Точка продаж: '}</div>
            <div className={styles.PolicyItemOffice}
                data-index-last={lastIndex}>
                {data.region.office}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Территориальная дирекция: '}</div>
            <div className={styles.PolicyItemTerritorialDirectorate}
                data-index-last={lastIndex}>
                {data.region.territorialDirectorate}
            </div>
        </div>
        <div className={styles.PolicyItemWrapperField_Mobile}>
            <div className={styles.PolicyItemWrapperFieldName_Mobile}>{'Макрорегион: '}</div>
            <div className={styles.PolicyItemRegionalDirectorate}
                data-index-first={firstIndex}
                data-index-last={lastIndex}>
                {data.region.macroRegion}
            </div>
        </div>
    </div>
);

export default SupervisorPolicyItem;
