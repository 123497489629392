import React from 'react';
import File from 'components/File';
import styles from './styles.module.scss';

const TaxDeductionGeneratedCertificatesList = ({ certificates,
}) => (
    <div className={styles.CertificatesWrapper}>
        {certificates.map((certificate, certificateIndex) => (
            <File
                key={certificateIndex}
                name={certificate.title}
                href={`/api?method=Insurance&action=getSocialTaxDeductionGeneratedCertificate&certificateIndex=${certificate.certificateIndex}&correctionIndex=${certificate.correctionIndex}&title=${encodeURI(certificate.title)}`}
            />
        ))}
    </div>
);
export default TaxDeductionGeneratedCertificatesList;
