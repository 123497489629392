import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import Container from 'components/Container';
import ContentWrapper from 'components/ContentWrapper';
import ShopList from 'components/ShopList';
import MainTopWrapper from '../../components/MainTopWrapper';

import styles from './styles.module.scss';
import AddProductModal from '../../components/AddProductModal';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { PRODUCTS_LIST, PRODUCTS_PROPOSE_PRODUCT } from '../../api';
import Heading from '../../components/Heading';

class ShopPage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Личный кабинет продавца',
            data: [],
            isLoading: true,
            isShowAddProductModal: false,
            formAddProduct: {
                title: '',
                link: '',
            },
            isLoadingAddProduct: false,
            isShowSuccessModal: false,
            isShowFailModal: false,
        };
        this.onChangeAddProductForm = this.onChangeAddProductForm.bind(this);
        this.addProduct = this.addProduct.bind(this);
    }

    onChangeAddProductForm(field, value) {
        this.setState({
            ...this.state,
            formAddProduct: {
                ...this.state.formAddProduct,
                [`${field}`]: value,
            },
        });
    }

    addProduct(title, link) {
        this.setState({ isLoadingAddProduct: true });
        PRODUCTS_PROPOSE_PRODUCT(title, link).then(() => {
            this.setState({ isLoadingAddProduct: false,
                isShowAddProductModal: false,
                formAddProduct: {
                    title: '',
                    link: '',
                },
                isShowSuccessModal: true });
        }).catch(() => {
            this.setState({ isLoadingAddProduct: false, isShowAddProductModal: false, isShowFailModal: true });
        });
    }

    componentDidMount() {
        document.title = this.state.title;
        PRODUCTS_LIST().then((data) => {
            this.setState({ data: data.profile, isLoading: false });
        });
    }

    render() {
        const currentPeriodLevel = Object.keys(this.store.profile.user).length !== 0 ? this.store.profile.user.level.currentPeriod.level : null;
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <AddProductModal
                    isShow={this.state.isShowAddProductModal}
                    handleClose={() => this.setState({ ...this.state, isShowAddProductModal: false })}
                    form={this.state.formAddProduct}
                    onChangeForm={this.onChangeAddProductForm}
                    addProduct={this.addProduct}
                    loading={this.state.isLoadingAddProduct}
                />
                {this.state.isShowSuccessModal && <Modal
                    classNameModal={styles.SuccessModal}
                    classNameModalHead={styles.SuccessModalHead}
                    classNameModalTitle={styles.SuccessModalTitle}
                    title={ 'Заявка на добавление товара отправлена' }
                    handleClose={() => this.setState({ isShowSuccessModal: false })}
                />
                }
                {this.state.isShowFailModal && <Modal
                    classNameModal={styles.FailModal}
                    classNameModalHead={styles.FailModalHead}
                    classNameModalTitle={styles.FailModalTitle}
                    title={ 'Ошибка отправки заявки на добавление товара' }
                    handleClose={() => this.setState({ isShowFailModal: false })}
                />
                }
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <ContentWrapper isLoading={this.state.isLoading}>
                        <Container>
                            <div className={styles.ShopPageContentHeader}>
                                <Heading level="4">{ 'Магазин сувениров' }</Heading>
                                {currentPeriodLevel === 'Platinum' && (
                                    <div className={styles.ShopPageContentHeaderButton}>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={() => this.setState({ ...this.state, isShowAddProductModal: true })}
                                            style={{ width: '100%', borderRadius: 20 }}
                                        >
                                            {'Предложить новый товар'}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className={styles.ShopPageContentShopListWrapper}>
                                <ShopList data={this.state.data}/>
                            </div>
                        </Container>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(ShopPage));
