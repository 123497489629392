import React from 'react';
import styles from './styles.module.scss';

const File = ({
    name,
    size = null,
    loading = false,
    href = null,
    onRemove = undefined,
}) => (
    <div className={styles.File}>
        <div
            className={styles.FileIcon}
            data-format="pdf"
            data-visible={!loading}
        >
            {loading && (
                <div className={styles.FileLoading} />
            )}
        </div>

        <div className={styles.FileContent}>
            <div className={styles.FileTitle}>
                {href
                    ? (
                        <a
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{ name }</a>
                    )
                    : (
                        name
                    )}

                {onRemove !== undefined && (
                    <div
                        className={styles.FileRemove}
                        onClick={onRemove}
                    >
                        &#215;
                    </div>
                )}
            </div>

            {size && (
                <div className={styles.FileSize}>{ size }</div>
            )}
        </div>
    </div>
);

export default File;
