import React from 'react';
import moment from 'moment';
import ToolTip from '../ToolTip';
import styles from './styles.module.scss';
import attention from '../../assets/icons/attention_gray_transparent.svg';

const AdditionalPointsItem = ({ firstIndex, lastIndex, data }) => (
    <div className={styles.AdditionalPointsItemWrapper}>
        <div className={styles.AdditionalPointsItemWrapperField_Mobile}>
            <div className={styles.AdditionalPointsItemWrapperFieldName_Mobile}>{'Дата события'}</div>
            <div className={styles.AdditionalPointsItemEventDate}
                data-index-first={firstIndex}
                data-index-last={lastIndex}>
                {moment(data.eventDate).format('DD.MM.YYYY')}
            </div>
        </div>
        <div className={styles.AdditionalPointsItemWrapperField_Mobile}>
            <div className={styles.AdditionalPointsItemWrapperFieldName_Mobile}>{'Событие начисления'}</div>
            <div className={styles.AdditionalPointsItemDescription}
                data-index-last={lastIndex}>
                {data.description }
            </div>
        </div>
        <div className={styles.AdditionalPointsItemWrapperField_Mobile}>
            <div className={styles.AdditionalPointsItemWrapperFieldName_Mobile}>{'Баллы: '}</div>
            <div className={styles.AdditionalPointsItemPoints}
                data-index-first={firstIndex}
                data-index-last={lastIndex}>
                {data.points.toLocaleString()}
                <ToolTip
                    content={
                        <div className={styles.TooltipContent}>
                            <img src={attention} alt="" className={styles.ToolTipContentIcon}/>
                            {'Баллы начислены и доступны к списанию'}
                        </div>
                    }
                    trigger='mouseenter focus'
                    placement='bottom'>
                    <div className={styles.AdditionalPointsItemPointsAvailable}/>
                </ToolTip>
            </div>
        </div>
    </div>
);

export default AdditionalPointsItem;
