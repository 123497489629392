import React from 'react';
import Button from 'components/Button';
import cn from 'classnames';
import styles from './styles.module.scss';
import { ADD_BASKET } from '../../api';
import Modal from '../Modal';

const goToCart = () => {
    window.location.replace('/cart');
};

const getButtonText = (enoughLevel, level) => {
    if (enoughLevel) {
        return 'Добавить в корзину';
    }
    switch (level) {
    case 'Silver':
        return 'Доступен на Меркурии';
    case 'Gold':
        return 'Доступен на Сатурне';
    case 'Platinum':
        return 'Доступен на Юпитере';
    default:
        return 'Добавить в корзину';
    }
};

const getSaleText = (level) => {
    switch (level) {
    case 'Silver':
        return 'Доступен на Меркурии';
    case 'Gold':
        return 'Доступен на Сатурне';
    case 'Platinum':
        return 'Доступен на Юпитере';
    default:
        return 'Добавить в корзину';
    }
};

class ProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemNumber: 1,
            isLongDescriptionVisible: false,
            isModalVisible: false,
            title: '',
            option: '',
        };
        this.handleMinus = this.handleMinus.bind(this);
        this.handlePlus = this.handlePlus.bind(this);
        this.handleAllParameters = this.handleAllParameters.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    handleMinus() {
        if (this.state.itemNumber > 1) {
            this.setState((prevState) => ({
                itemNumber: prevState.itemNumber - 1,
            }));
        }
    }

    handlePlus() {
        if (this.state.itemNumber < this.props.data.quantity) {
            this.setState((prevState) => ({
                itemNumber: prevState.itemNumber + 1,
            }));
        }
    }

    handleAllParameters() {
        this.setState((prevState) => ({
            isLongDescriptionVisible: !prevState.isLongDescriptionVisible,
        }));
    }

    closeModal() {
        this.setState({
            isModalVisible: false,
        });
    }


    addItemToBasket(item, itemNumber, option) {
        ADD_BASKET(item.productId, item.title, item.price, itemNumber, item.quantity, item.previewImage.id, option)
            .then((data) => {
                if (data.success === 'ok') {
                    this.setState({
                        isModalVisible: true,
                        title: 'Товар добавлен в корзину',
                    });
                } else {
                    this.setState({
                        isModalVisible: true,
                        title: 'Ошибка добавления товара в корзину. Обратитесь к администратору',
                    });
                }
            }).catch((error) => {
                console.log(error);
                this.setState({
                    isModalVisible: true,
                    title: 'Ошибка добавления товара в корзину. Обратитесь к администратору',
                });
            });
    }

    render() {
        const { isLongDescriptionVisible } = this.state;
        const { sale } = this.props.data;
        const canBuyButtonActive = this.props.data.options ? this.props.data.canBuy && this.state.option : this.props.data.canBuy;
        return (
            <>
                {this.state.isModalVisible && <Modal
                    title={this.state.title}
                    body={
                        <p
                            dangerouslySetInnerHTML={{ __html: `${this.props.data.title}${this.state.option ? ` ${this.state.option}` : ''} ${this.state.itemNumber} шт. по ${this.props.data.price.toLocaleString()} У` }}>
                        </p>
                    }
                    footer={
                        <>
                            <Button className={styles.AddProductModalButton} onClick={() => this.closeModal()}
                                variant="contained">{'Продолжить покупки'}</Button>
                            <Button className={styles.AddProductModalButton} onClick={() => goToCart()}
                                variant="contained">{'Перейти в корзину'}</Button>
                        </>
                    }
                    handleClose={this.closeModal}/>
                }
                <div
                    className={cn(styles.ProductCardContent, this.props.data.canBuy ? styles.ProductCardContent_Available : styles.ProductCardContent_Unavailable)}>
                    <img className={styles.ProductCardImage}
                        src={`/upload/lk-sellers-shop/${this.props.data.previewImage.id}.png`}
                        alt={this.props.data.title}/>
                    <div className={styles.ProductCardMainWrapper}
                        data-product-sale={!!sale}>
                        <div
                            className={cn(styles.ProductCardTitle, `${!isLongDescriptionVisible ? '' : styles.ProductCardTitleHidden}`)}
                            dangerouslySetInnerHTML={{ __html: this.props.data.title }}>
                        </div>
                        {this.props.data.options
                            && <div className={styles.ProductCardSelectOption}>
                                <select
                                    value={this.state.option}
                                    onChange={(event) => this.setState({ option: event.target.value })}
                                >
                                    <option value=''>Выбрать компанию</option>
                                    {this.props.data.options.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>))}
                                </select>
                            </div>
                        }
                        <div
                            className={cn(styles.ProductCardShortDescription, `${!isLongDescriptionVisible ? '' : styles.ProductCardShortDescriptionHidden}`)}
                            data-product-sale={!!sale}
                            data-product-option={!!this.props.data.options}
                            dangerouslySetInnerHTML={{ __html: this.props.data.shortDescriptionHtml }}>
                        </div>
                        <Button
                            className={cn(styles.ProductCardAllParameters, `${!isLongDescriptionVisible ? '' : styles.ProductCardAllParametersButtonUp}`)}
                            onClick={this.handleAllParameters} variant="text">
                            {'Все параметры'}
                        </Button>
                        <div
                            className={cn(styles.ProductCardLongDescription, `${isLongDescriptionVisible ? '' : styles.ProductCardLongDescriptionHidden}`)}
                            data-product-sale={!!sale}
                            data-product-option={!!this.props.data.options}
                            dangerouslySetInnerHTML={{ __html: this.props.data.longDescriptionHtml }}>
                        </div>
                    </div>
                    <div className={styles.ProductCardBottomWrapper}>
                        <div className={styles.ProductCardPriceWrapper}>
                            <div className={styles.PriceTitle}>Стоимость</div>
                            {sale ? (
                                <>
                                    <div className={styles.PriceNumber_Sale__FullPrice}
                                        data-product-sale-hasEnoughLevel={sale.hasEnougthLevel}>
                                        {`${sale.fullPrice.toLocaleString()} У`}
                                    </div>
                                    <div className={styles.PriceNumber_Sale__Wrapper}
                                        data-product-sale-hasEnoughLevel={sale.hasEnougthLevel}>
                                        <div className={styles.PriceNumber_Sale__SalePrice}
                                            data-product-sale-hasEnoughLevel={sale.hasEnougthLevel}>
                                            {`${sale.salePrice.toLocaleString()} У`}
                                        </div>
                                        <div className={styles.PriceNumber_Sale__Title}
                                            data-product-sale-hasEnoughLevel={sale.hasEnougthLevel}>
                                            {getSaleText(sale.level)}
                                        </div>
                                    </div>
                                </>
                            )
                                : (
                                    <div className={styles.PriceNumber}>{`${this.props.data.price.toLocaleString()} У`}</div>
                                )}
                        </div>
                        <div className={styles.ProductCardBuyButtonWrapper}>
                            <Button
                                className={styles.ProductCardBuyButton}
                                style={{
                                    backgroundColor: canBuyButtonActive ? '#0063cc' : '#DFE2EE',
                                    borderColor: canBuyButtonActive ? '#0063cc' : '#DFE2EE',
                                    color: canBuyButtonActive ? '#FFFFFF' : '#000000',
                                    cursor: canBuyButtonActive ? 'pointer' : 'default',
                                }}
                                onClick={canBuyButtonActive ? () => this.addItemToBasket(this.props.data, this.state.itemNumber, this.state.option) : null}
                            >
                                {getButtonText(this.props.data.hasEnougthLevel, this.props.data.level)}
                            </Button>
                            <div className={styles.ProductCardQuantityWrapper}>
                                <div className={styles.ProductCardQuantityTitle}>
                                    {`В наличии ${this.props.data.quantity} шт`}
                                </div>
                                <div className={styles.ProductCardSelectQuantityWrapper}>
                                    <Button
                                        className={cn(styles.ProductCardSelectQuantityButton, styles.ProductCardSelectQuantityButton_Minus)}
                                        onClick={this.props.data.canBuy ? this.handleMinus : null}
                                        style={{ cursor: this.props.data.canBuy ? 'pointer' : 'default' }}>{'-'}</Button>
                                    <div
                                        className={styles.ProductCardSelectQuantityNumber}>{this.state.itemNumber}</div>
                                    <Button
                                        className={cn(styles.ProductCardSelectQuantityButton, styles.ProductCardSelectQuantityButton_Plus)}
                                        onClick={this.props.data.canBuy ? this.handlePlus : null}
                                        style={{ cursor: this.props.data.canBuy ? 'pointer' : 'default' }}>{'+'}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductCard;
