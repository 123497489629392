import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Container from 'components/Container';
import LoginForm from 'components/LoginForm';
import LoginWrapper from 'components/LoginWrapper';
import LoginCard from 'components/LoginCard';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            isVisible: true,
            title: 'Личный кабинет продавца',
        };
    }

    componentDidMount() {
        document.title = this.state.title;
    }

    render() {
        return (
            <LoginWrapper>
                <Container fluid>
                    <LoginCard title={'Вход в личный кабинет'}>
                        <LoginForm store={this.store}/>
                    </LoginCard>
                </Container>
            </LoginWrapper>
        );
    }
}

export default withRouter(inject('store')(observer(LoginPage)));
