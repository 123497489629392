import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const Container = ({
    fluid,
    position = 'relative',
    children,
}) => (
    <div
        className={cn(
            styles.Container,
            [fluid && styles.ContainerFluid],
        )}
        style={{ position }}
    >
        { children }
    </div>
);

export default Container;
