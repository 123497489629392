import React from 'react';
import Button from '../Button';
import Modal from '../Modal';
import InputField from '../InputField';
import styles from './styles.module.scss';

const AddProductModal = ({
    isShow,
    handleClose,
    form,
    addProduct,
    loading,
    onChangeForm,
}) => (
    isShow && <Modal
        classNameModal={styles.AddProductModal}
        classNameModalHead={styles.AddProductModalHead}
        classNameModalTitle={styles.AddProductModalTitle}
        classNameModalFoot={styles.AddProductModalFoot}
        title={ 'Предложить новый товар' }
        body={<div >
            <div style={{ width: '100%', marginTop: 13 }}>
                <InputField
                    label="Укажите название"
                    size="lg"
                    style={{ height: 44 }}
                    type="text"
                    placeholder="Например, Apple iPhone 13"
                    name="title"
                    value={form.title}
                    onChange={(e) => onChangeForm('title', e.target.value)}
                />
            </div>
            <div style={{ width: '100%', marginTop: 25 }}>
                <InputField
                    label="Укажите ссылку"
                    size="lg"
                    style={{ height: 44 }}
                    type="text"
                    placeholder="Например, www.dns-shop.ru/..."
                    name="link"
                    value={form.link}
                    onChange={(e) => onChangeForm('link', e.target.value)}
                />
            </div>
        </div>
        }
        footer={
            <Button
                onClick={() => addProduct(form.title, form.link)}
                loading={loading}
                disabled={!(form.title && form.link)}
                style={{ height: 50, width: '80%' }}
                size="lg"
                variant="primary">{ 'Отправить на согласование' }</Button>
        }
        handleClose={handleClose} />
);

export default AddProductModal;
