import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import UserLevelTooltip from '../UserLevelTooltip';

const UserLevelSticker = ({ level, planet }) => (
    <UserLevelTooltip
        period={'currentPeriod'}
        level={level}
    >
        <div className={styles.UserLevelSticker}>
            <div className={cn(styles.UserLevelStickerImage, styles[`UserLevelStickerImage_${level}`])}/>
            <div className={styles.UserLevelStickerTitle}>
                {planet}
            </div>
        </div>
    </UserLevelTooltip>
);

export default UserLevelSticker;
