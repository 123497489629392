import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import SelectField from 'components/SelectField';
import DateInputField from 'components/DateInputField';
import Heading from 'components/Heading';
import InputField from 'components/InputField';
import Button from 'components/Button';
import MaskedInputField from 'components/MaskedInputField';
import styles from './styles.module.scss';

const TaxDeductionGeneratingCertificateBlock = ({
    store,
    policyId,
    policyNumber,
    selections,
}) => {
    const inputFormBuilderFields = {
        insurer: {
            nominativeCase: 'Страхователь',
            objectiveCase: 'страхователя',
        },
        insured: {
            nominativeCase: 'Застрахованный',
            objectiveCase: 'застрахованного',
        },
    };
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(-1);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [contractParties, setContractParties] = useState({
        insurer: {},
        insured: {},
    });
    const [errors, setErrors] = useState({
        insurer: {
            inn: true,
            document: { seriesAndNumber: true, type: true, issueDate: true },
        },
        insured: {
            inn: true,
            document: { seriesAndNumber: true, type: true, issueDate: true },
        },
    });
    const [canSubmit, setCanSubmit] = useState(false);

    const isNewTypeOfCertificate = useCallback(() => year >= 2024, [year]);

    const handleInnChange = (e, contractParty) => {
        let newVal;
        if (e.target.value === undefined || e.target.value === null) {
            newVal = '';
        } else {
            newVal = e.target.value;
        }

        setContractParties(() => ({
            ...contractParties,
            [contractParty]: {
                ...contractParties[contractParty], inn: newVal,
            },
        }));
    };

    const handleDocumentChange = (e, contractParty, field) => {
        let newVal;
        if (e.target.value === undefined || e.target.value === null) {
            newVal = '';
        } else {
            newVal = e.target.value;
        }

        if (!(field === 'seriesAndNumber' && e.target.value.trim().length > 25)) {
            setContractParties(() => ({
                ...contractParties,
                [contractParty]: {
                    ...contractParties[contractParty],
                    document: { ...contractParties[contractParty].document, [field]: newVal },
                },
            }));
        }
    };

    const handleDocumentDateChange = (contractParty, value) => {
        let issueDate;
        if (value) {
            issueDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
        } else {
            issueDate = null;
        }
        setContractParties(() => ({
            ...contractParties,
            [contractParty]: {
                ...contractParties[contractParty], document: { ...contractParties[contractParty].document, issueDate },
            },
        }));
    };

    const inputDocumentFields = (contractParty) => (
        <React.Fragment>
            <div className={styles.CardRow}>
                <MaskedInputField
                    mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                    type="text"
                    label={`ИНН ${inputFormBuilderFields[contractParty].objectiveCase}`}
                    size="lg"
                    name={`${contractParty}Inn`}
                    placeholder={`ИНН ${inputFormBuilderFields[contractParty].objectiveCase}`}
                    description={'Обязательное поле, заполните 12 цифр'}
                    value={contractParties[contractParty].inn || ''}
                    error={errors[contractParty].inn}
                    onChange={(e) => handleInnChange(e, contractParty)}
                />
            </div>
            <div className={styles.CardRow}>
                <SelectField
                    label={`Вид документа ${inputFormBuilderFields[contractParty].objectiveCase}`}
                    size="lg"
                    name="documentType"
                    placeholder={`Вид документа ${inputFormBuilderFields[contractParty].objectiveCase}`}
                    description={'Обязательное поле'}
                    value={contractParties[contractParty].document ? contractParties[contractParty].document.type : -1}
                    error={errors[contractParty].document.type}
                    options={documentTypes}
                    onChange={(e) => handleDocumentChange(e, contractParty, 'type')}
                />
            </div>
            <div className={styles.CardRow}>
                <div data-name="document">
                    <InputField
                        type="text"
                        label={`Серия и номер документа ${inputFormBuilderFields[contractParty].objectiveCase}`}
                        size="lg"
                        name={`${contractParty}DocumentSeriesAndNumber`}
                        placeholder={`Серия и номер документа ${inputFormBuilderFields[contractParty].objectiveCase}`}
                        description={'Обязательное поле, заполните от 1 до 25 символов'}
                        error={errors[contractParty].document.seriesAndNumber}
                        value={contractParties[contractParty].document ? contractParties[contractParty].document.seriesAndNumber || '' : ''}
                        onChange={(e) => handleDocumentChange(e, contractParty, 'seriesAndNumber')}
                    />
                </div>
                <div data-name="documentDate">
                    <DateInputField
                        mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                        label={`Дата выдачи документа ${inputFormBuilderFields[contractParty].objectiveCase}`}
                        description={'Обязательное поле'}
                        size="lg"
                        icon="calendar"
                        format="dd.MM.yyyy"
                        placeholder="дд.мм.гггг"
                        name={`${contractParty}DocumentDate`}
                        maxDate={moment().toDate()}
                        error={errors[contractParty].document.issueDate}
                        value={(contractParties[contractParty].document && contractParties[contractParty].document.issueDate) ? moment(contractParties[contractParty].document.issueDate).toDate() || '' : ''}
                        onChange={(value) => handleDocumentDateChange(contractParty, value)}
                    />
                </div>
            </div>
        </React.Fragment>
    );

    const inputFormBuilder = (contractParty) => (
        <React.Fragment>
            <div className={styles.CardRow}>
                <InputField
                    type="text"
                    label={inputFormBuilderFields[contractParty].nominativeCase}
                    size="lg"
                    name={`${contractParty}FullName`}
                    placeholder={inputFormBuilderFields[contractParty].nominativeCase}
                    value={`${contractParties[contractParty].fullName} (${moment(contractParties[contractParty].birthDate).format('DD.MM.YYYY')})`}
                    readOnly
                    disabled
                />
            </div>
            {inputDocumentFields(contractParty)}
        </React.Fragment>
    );

    const inputForm = () => isNewTypeOfCertificate() && (
        <React.Fragment>
            <Heading
                description={'Уважаемый клиент! Перед формированием справки просьба проверить актуальность Ваших персональных данных.'}
            />
            {inputFormBuilder('insurer')}
            {contractParties.insured && Object.keys(contractParties.insured).length > 0 && (
                <div className={styles.Insured}>
                    {inputFormBuilder('insured')}
                </div>
            )}
        </React.Fragment>
    );

    const urlBuilder = (insurerSubmitObject, insuredSubmitObject) => {
        let url = `/api?method=Insurance&action=getSocialTaxDeduction&policyId=${policyId}&policyNumber=${encodeURI(policyNumber)}&year=${moment(year).format('YYYY')}`;
        if (isNewTypeOfCertificate()) {
            url += `&insurer=${JSON.stringify(insurerSubmitObject)}`;
            if (insuredSubmitObject) {
                url += `&insured=${JSON.stringify(insuredSubmitObject)}`;
            }
        }
        return url;
    };

    const contractPartySubmitObjectBuilder = (contractParty) => ({ ...({ inn: contractParties[contractParty].inn, document: contractParties[contractParty].document }) });

    const handleSubmit = (e) => {
        e.preventDefault();

        const insurerSubmitObject = contractPartySubmitObjectBuilder('insurer');
        const insuredSubmitObject = Object.keys(contractParties.insured).length > 0 ? contractPartySubmitObjectBuilder('insured') : null;
        const url = urlBuilder(insurerSubmitObject, insuredSubmitObject);

        setLoading(true);

        axios.get(url)
            .then((response) => {
                const { data: { errorResult = null } = {} } = response;

                if (errorResult) {
                    const { message } = errorResult;

                    throw new Error(message);
                }

                const openedWindow = window.open(url, '_blank');

                if (openedWindow === null) {
                    throw new Error(null);
                } else {
                    store.taxDeduction.getCertificates(policyId).then(() => {
                        setLoading(false);
                    });
                }
            })
            .catch((error) => {
                store.app.setModalData({
                    title: 'Ошибка',
                    body: error.message,
                });

                setLoading(false);
            });
    };

    useEffect(() => {
        const initializeSelections = () => {
            if (Object.keys(contractParties.insurer).length === 0 && selections.insurer !== null) {
                setContractParties(() => ({ ...contractParties, insurer: selections.insurer }));
            }
            if (Object.keys(contractParties.insured).length === 0 && selections.insured !== null) {
                setContractParties(() => ({ ...contractParties, insured: selections.insured }));
            }

            if (documentTypes.length === 0) {
                const documentTypesTmp = [];
                selections.documentTypes.forEach((documentType) => {
                    documentTypesTmp.push({ id: documentType.key, name: documentType.documentType });
                });
                setDocumentTypes(documentTypesTmp);
            }
        };

        const setInnErrors = (contractType, value) => {
            if (errors[contractType].inn === !value) {
                setErrors(() => ({ ...errors, [contractType]: { ...errors[contractType], inn: value } }));
            }
        };

        const setDocumentErrors = (contractType, field, value) => {
            if (errors[contractType].document[field] === !value) {
                setErrors(() => ({
                    ...errors,
                    [contractType]: {
                        ...errors[contractType],
                        document: { ...errors[contractType].document, [field]: value },
                    },
                }));
            }
        };

        const checkContractPartyErrors = (contractType) => {
            if (Object.hasOwn(contractParties[contractType], 'inn')
                && typeof contractParties[contractType].inn === 'string' && contractParties[contractType].inn.length === 12) {
                setInnErrors(contractType, false);
            } else setInnErrors(contractType, true);

            if (Object.hasOwn(contractParties[contractType], 'document') && contractParties[contractType].document !== null) {
                if (Object.hasOwn(contractParties[contractType].document, 'seriesAndNumber')
                    && typeof contractParties[contractType].document.seriesAndNumber === 'string'
                    && contractParties[contractType].document.seriesAndNumber.trim().length >= 1) {
                    setDocumentErrors(contractType, 'seriesAndNumber', false);
                } else {
                    setDocumentErrors(contractType, 'seriesAndNumber', true);
                }
                if (Object.hasOwn(contractParties[contractType].document, 'type')
                    && typeof contractParties[contractType].document.type === 'string'
                    && contractParties[contractType].document.type !== -1) {
                    setDocumentErrors(contractType, 'type', false);
                } else {
                    setDocumentErrors(contractType, 'type', true);
                }
                if (Object.hasOwn(contractParties[contractType].document, 'issueDate')
                    && typeof contractParties[contractType].document.issueDate === 'string') {
                    setDocumentErrors(contractType, 'issueDate', false);
                } else {
                    setDocumentErrors(contractType, 'issueDate', true);
                }
            } else {
                setDocumentErrors(contractType, 'seriesAndNumber', true);
                setDocumentErrors(contractType, 'type', true);
                setDocumentErrors(contractType, 'issueDate', true);
            }
        };

        const checkErrors = () => {
            checkContractPartyErrors('insurer');
            if (contractParties.insured && Object.keys(contractParties.insured).length > 0) {
                checkContractPartyErrors('insured');
            }
        };

        const checkContractPartyCanSubmit = (contractParty) => (Object.keys(contractParties[contractParty]).length === 0)
            || (!errors[contractParty].inn
                && !errors[contractParty].document.seriesAndNumber
                && !errors[contractParty].document.type
                && !errors[contractParty].document.issueDate);

        const checkCanSubmit = () => {
            if (isNewTypeOfCertificate()) {
                setCanSubmit(checkContractPartyCanSubmit('insurer') && checkContractPartyCanSubmit('insured'));
            } else {
                setCanSubmit(true);
            }
        };

        initializeSelections();
        checkErrors();
        checkCanSubmit();
    }, [contractParties, documentTypes.length, errors, isNewTypeOfCertificate, selections.documentTypes, selections.insured, selections.insurer, store.taxDeduction]);

    return (
        <React.Fragment>
            <div className={styles.CardRow}>
                <div data-name="select">
                    <SelectField
                        size="lg"
                        name="chooseYear"
                        placeholder={'Выбрать год'}
                        value={year}
                        options={selections.availableYears.map((item) => ({ id: item, name: item }))}
                        onChange={(e) => setYear(e.target.value)}
                    />
                </div>
            </div>
            {year !== -1 && (
                <React.Fragment>
                    {inputForm()}
                    <div data-name='button-submit-wrapper'>
                        <Button
                            data-name='button-submit'
                            size="lg"
                            type="submit"
                            variant="primary"
                            loading={loading}
                            disabled={!canSubmit}
                            onClick={handleSubmit}
                        >{'Сформировать справку'}</Button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default TaxDeductionGeneratingCertificateBlock;
