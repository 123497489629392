import React, { Component } from 'react';
import NewsCard from 'components/NewsCard';
import { inject, observer } from 'mobx-react';
import styles from './styles.module.scss';
import Heading from '../../components/Heading';
import Wrapper from '../../components/Wrapper';
import MainTopWrapper from '../../components/MainTopWrapper';
import Container from '../../components/Container';
import AppLayout from '../../layouts/AppLayout';
import Preloader from '../../components/Preloader/Preloader';

class NewsListPage extends Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Новости | Личный кабинет продавца',
            isLoading: true,
            news: [],
        };
    }

    componentDidMount() {
        document.title = this.state.title;
        if (Object.keys(this.store.news.news).length === 0) {
            this.store.news.getNews().then(() => {
                this.setState({ news: this.store.news.news, isLoading: false });
            });
        } else {
            this.setState({ news: this.store.news.news, isLoading: false });
        }
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <Container fluid>
                        {this.state.isLoading && (<div className={styles.PreloaderWrapper}><Preloader/></div>)}
                        {!this.state.isLoading && (
                            <div className={styles.NewsListWrapper}>
                                <div>
                                    <Heading className={styles.NewsHeading} level="4">{'Новости'}</Heading>
                                </div>
                                <div className={styles.NewsListContent}>
                                    {this.state.news.map((item, index) => (
                                        <div className={styles.NewsListItem}><NewsCard data={item} key={index}></NewsCard>
                                        </div>))}
                                </div>
                            </div>
                        )}
                    </Container>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(NewsListPage));
