import React from 'react';
import cn from 'classnames';
import Button from 'components/Button';
import styles from './styles.module.scss';
import { getLevelAdvantages } from '../../helpers/advantages.js';

const LevelFunctionalityModal = ({ handleClose }) => (
    <div className={styles.ModalContainer}>
        <div
            className={styles.ModalOverlay}
            onClick={handleClose}></div>
        <div className={cn(styles.ModalWrapper)}>
            <div
                className={styles.ModalClose}
                onClick={handleClose}
            />

            <div className={styles.ModalHead}>
                <div className={styles.ModalTitle}>
                    {'Функционал уровней'}
                </div>
            </div>

            <div className={styles.ModalContent}>
                <div className={styles.ModalContentHeader}>
                    <div className={styles.ModalContentHeader__ColumnLevel}>
                    </div>
                    <div className={styles.ModalContentHeader__ColumnLevelCondition}>
                        {'Условия получения уровня'}
                    </div>
                    <div className={styles.ModalContentHeader__ColumnCoefficient}>
                        {'Коэффициент начисления уралкоинов'}
                    </div>
                    <div className={styles.ModalContentHeader__ColumnAdvantages}>
                        {'Дополнительно получите'}
                    </div>
                </div>
                <div className={styles.LevelContent}>
                    <div className={styles.LevelContentItem__Level}>
                        <div className={styles.LevelContentItem__LevelTitle}>
                            {'Платиновый (Юпитер)'}
                        </div>
                        <div
                            className={cn(styles.LevelContentItem__LevelImage, styles.LevelContentItem__LevelImage_Platinum)}>
                        </div>
                    </div>
                    <div className={styles.LevelContent__ItemsWrapper}>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Условия получения уровня'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_BottomBorder, styles.LevelContentItem__LevelCondition)}>
                            <div className={styles.LevelContentItem__LevelCondition__Item}>
                                {'5 ИСЖ'}
                            </div>
                            <div className={styles.LevelContentItem__LevelCondition__Item}>
                                {'4 НСЖ'}
                            </div>
                            <div className={styles.LevelContentItem__LevelCondition__Alternative}>
                                {'либо суммарная премия от 21 млн руб.'}
                            </div>
                            <div className={styles.LevelContentItem__LevelCondition__Period}>
                                {'1 раз в квартал'}
                            </div>
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Коэффициент начисления уралкоинов'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_BottomBorder, styles.LevelContentItem__Coefficient)}>
                            <div className={styles.LevelContentItem__CoefficientPercent}>
                                {'+30%'}
                            </div>
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Дополнительно получите'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem__Advantages)}>
                            <div className={styles.LevelContentItem__AdvantagesList}>
                                <ul>
                                    {getLevelAdvantages('Platinum').map((advantageItem, index) => (<li key={index}>{advantageItem}</li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.LevelContent}>
                    <div className={styles.LevelContentItem__Level}>
                        <div className={styles.LevelContentItem__LevelTitle}>
                            {'Золотой (Сатурн)'}
                        </div>
                        <div
                            className={cn(styles.LevelContentItem__LevelImage, styles.LevelContentItem__LevelImage_Gold)}>
                        </div>
                    </div>
                    <div className={styles.LevelContent__ItemsWrapper}>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Условия получения уровня'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_BottomBorder, styles.LevelContentItem__LevelCondition)}>
                            <div className={styles.LevelContentItem__LevelCondition__Item}>
                                {'4 ИСЖ'}
                            </div>
                            <div className={styles.LevelContentItem__LevelCondition__Item}>
                                {'3 НСЖ'}
                            </div>
                            <div className={styles.LevelContentItem__LevelCondition__Alternative}>
                                {'либо суммарная премия от 15 млн руб.'}
                            </div>
                            <div className={styles.LevelContentItem__LevelCondition__Period}>
                                {'1 раз в квартал'}
                            </div>
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Коэффициент начисления уралкоинов'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_BottomBorder, styles.LevelContentItem__Coefficient)}>
                            <div className={styles.LevelContentItem__CoefficientPercent}>
                                {'+20%'}
                            </div>
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Дополнительно получите'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem__Advantages)}>
                            <div className={styles.LevelContentItem__AdvantagesList}>
                                <ul>
                                    {getLevelAdvantages('Gold').map((advantageItem, index) => (<li key={index}>{advantageItem}</li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.LevelContent}>
                    <div className={styles.LevelContentItem__Level}>
                        <div className={styles.LevelContentItem__LevelTitle}>
                            {'Серебряный (Меркурий)'}
                        </div>
                        <div
                            className={cn(styles.LevelContentItem__LevelImage, styles.LevelContentItem__LevelImage_Silver)}>
                        </div>
                    </div>
                    <div className={styles.LevelContent__ItemsWrapper}>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Условия получения уровня'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_BottomBorder, styles.LevelContentItem__LevelCondition)}>
                            <div className={styles.LevelContentItem__LevelCondition__Item}>
                                {'1 ИСЖ'}
                            </div>
                            <div className={styles.LevelContentItem__LevelCondition__Item}>
                                {'1 НСЖ'}
                            </div>
                            <div className={styles.LevelContentItem__LevelCondition__Period}>
                                {'1 раз в квартал'}
                            </div>
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Коэффициент начисления уралкоинов'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_BottomBorder, styles.LevelContentItem__Coefficient)}>
                            <div className={styles.LevelContentItem__CoefficientNumber}>
                                {'1'}
                            </div>
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem_Mobile_ShowTitle)}>
                            {'Дополнительно получите'}
                        </div>
                        <div className={cn(styles.LevelContentItem, styles.LevelContentItem__Advantages)}>
                            <div className={styles.LevelContentItem__AdvantagesList}>
                                <ul>
                                    {getLevelAdvantages('Silver').map((advantageItem, index) => (<li key={index}>{advantageItem}</li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.ModalButton}>
                <Button
                    size={'lg'}
                    variant={'primary'}
                    style={{ height: 44, width: '45%' }}
                    onClick={handleClose}
                >
                    {'ОК'}
                </Button>
            </div>
        </div>
    </div>
);

export default LevelFunctionalityModal;
