import React from 'react';
import styles from './styles.module.scss';

const LoadingBar = ({
    started = false,
    value = 0,
}) => (
    <div
        className={styles.LoadingBar}
        style={{
            width: `${value}%`,
            opacity: started ? 1 : 0,
        }}
    />
);

export default LoadingBar;
