import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import QuestionLabel from '../QuestionLabel';

const SelectField = ({
    label,
    size,
    options = [],
    value,
    placeholder,
    description,
    error,
    isRequired,
    ...rest
}) => {
    const sizeClass = () => {
        switch (size) {
        case 'lg':
            return styles.SelectFieldSizeLg;
        default:
            return null;
        }
    };

    return (
        <div className={cn(styles.SelectField, sizeClass())}
            {...error && {
                'data-error': error,
            }}>
            <div className={styles.SelectFieldLabelWrapper}>
                <label className={styles.SelectFieldLabel} data-required={!!(isRequired)}>
                    {label}
                </label>
                {description && <QuestionLabel text={description}/>}
            </div>

            <div className={styles.SelectFieldWrapper}>
                <select
                    className={styles.SelectFieldControl}
                    value={value}
                    data-selected={value}
                    {...rest}
                >
                    <option
                        key={'placeholder'}
                        value={-1}
                        disabled
                    >{ placeholder || 'Выберите значение' }</option>

                    {options.map((option, index) => (
                        <option
                            key={index}
                            value={option.id}>{ option.name }</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default SelectField;
