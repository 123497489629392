import React from 'react';
import styles from './styles.module.scss';
import SupervisorOrderItem from '../SupervisorOrderItem';

class SupervisorOrdersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <table className={styles.OrdersListTable}>
                <thead className={styles.OrdersListTableHeader}>
                    <tr>
                        <th>№ заказа</th>
                        <th>Дата заказа</th>
                        <th>Стоимость</th>
                        <th>Статус заказа</th>
                        <th>Описание</th>
                        <th>Дата доставки</th>
                        <th>ФИО сотрудника</th>
                        <th>Точка продаж</th>
                        <th>Территориальная дирекция</th>
                        <th>Макрорегион</th>
                    </tr>
                </thead>
                <tbody className={styles.OrdersListTableBody}>
                    {this.props.data.map((item, index) => (
                        <SupervisorOrderItem
                            key={index}
                            data={item}/>
                    ))}
                </tbody>
            </table>
        );
    }
}

export default SupervisorOrdersList;
