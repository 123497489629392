import React, { PureComponent } from 'react';
import cn from 'classnames';
import moment from 'moment/moment';
import Uralcoin from '../../assets/icons/uralcoin.svg';
import styles from './styles.module.scss';
import ToolTip from '../ToolTip';
import UralCoinsStickerSpendButton from '../StyledComponents/UralCoinsStickerSpendButton';

class UralCoinsSticker extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modal: '',
            visibleModal: false,
        };

        this.Modal = this.Modal.bind(this);
    }

    Modal(visibleModal = false) {
        this.setState({ visibleModal });
    }

    render() {
        const { points, validTo, isFrozen } = this.props;
        const activeTooltip = <ToolTip
            trigger='mouseenter focus'
            placement='bottom'
            content={
                <div className={styles.UralCoinsStickerOverlay}>
                    <div className={styles.UralCoinsStickerModal}>
                        <div className={styles.UralCoinsStickerContent}>
                            <div className={styles.UralCoinsStickerRow}>
                                <div className={styles.UralCoinsStickerAvailableWrapper}>
                                    <div className={styles.UralCoinsStickerAvailableTitle}>
                                        {'Доступно для трат'}
                                    </div>
                                    <div className={styles.UralCoinsStickerAvailableNumber}>
                                        {`${(points.available) ? points.available.toLocaleString() : 0} У`}
                                    </div>
                                </div>
                                <div className={styles.UralCoinsStickerUnavailableWrapper}>
                                    <div className={styles.UralCoinsStickerUnavailableTitle}>
                                        {'Будут начислены'}
                                    </div>
                                    <div className={styles.UralCoinsStickerUnavailableNumber}>
                                        {`${(points.unavailable) ? points.unavailable.toLocaleString() : 0} У`}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.UralCoinsStickerSpendButton}>
                                <UralCoinsStickerSpendButton variant="contained"
                                    onClick={() => window.location.href = '/shop'}>
                                    {'Потратить баллы'}
                                </UralCoinsStickerSpendButton>
                            </div>
                        </div>
                    </div>
                </div>
            }>
            <div className={cn(styles.UralCoinsStickerStatus, styles.UralCoinsStickerStatus__isActive)}>
                <img className={styles.UralCoinsStickerImage} src={Uralcoin} alt='Уралкоин'/>
                <div className={styles.UralCoinsStickerStatusContent}>
                    <div className={styles.UralCoinsStickerStatusPercentage}>
                        {points.total.toLocaleString()}
                    </div>
                    <div className={styles.UralCoinsStickerStatusText}>{'Уралкоинов'}</div>
                </div>
            </div>
        </ToolTip>;

        const frozenTooltip = <ToolTip
            trigger='mouseenter focus'
            placement='bottom'
            content={
                <div className={styles.UralCoinsStickerOverlay}>
                    <div className={cn(styles.UralCoinsStickerModal, styles.UralCoinsStickerModal__isFrozen)}>
                        <div className={cn(styles.UralCoinsStickerContent, styles.UralCoinsStickerContent__isFrozen)}>
                            {`Ваши уралкоины заморожены до ${moment(validTo).format('DD.MM.YY')}`}
                        </div>
                    </div>
                </div>
            }>
            <div className={cn(styles.UralCoinsStickerStatus, styles.UralCoinsStickerStatus__isFrozen)}>
                <img className={cn(styles.UralCoinsStickerImage, styles.UralCoinsStickerImage__isFrozen)} src={Uralcoin} alt='Уралкоин'/>
                <div className={cn(styles.UralCoinsStickerStatusContent, styles.UralCoinsStickerStatusContent__isFrozen)}>
                    <div className={styles.UralCoinsStickerStatusPercentage}>
                        {points.total.toLocaleString()}
                    </div>
                    <div className={styles.UralCoinsStickerStatusText}>{'Уралкоинов'}</div>
                </div>
            </div>
        </ToolTip>;

        return isFrozen ? frozenTooltip : activeTooltip;
    }
}

export default UralCoinsSticker;
