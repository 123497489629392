import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const BaseTable = ({
    children,
    className,
}) => (
    <table className={cn(styles.BaseTable, className)}>
        { children }
    </table>
);

export default BaseTable;
