import React from 'react';
import cn from 'classnames';
import ScrollContainer from 'components/ScrollContainer';
import BaseTable from 'components/BaseTable';
import styles from './styles.module.scss';

const InvestmentTable = ({
    rows = [],
}) => {
    const filteredColumns = (columns) => columns.filter((column) => column.title && column.title !== 'null');

    const nextColumnsCount = (columns, column) => {
        const index = columns.indexOf(column);
        const nextColumns = columns.slice(index + 1, columns.length);
        const prevColumns = columns.slice(0, index);

        let nextCounter = 0;
        let prevIndex = prevColumns.length - 1;

        if (nextColumns.length !== 0) {
            while (nextColumns[nextCounter] && (column.title && !nextColumns[nextCounter].title)) {
                nextCounter += 1;
            }
        }

        if (prevColumns.length !== 0 && nextCounter === 0) {
            while (prevColumns[prevIndex] && (column.title && !prevColumns[prevIndex].title)) {
                nextCounter += 1;
                prevIndex -= 1;
            }
        }

        return nextCounter + 1;
    };

    const columnStyle = (columns, column) => {
        const isFirstColumn = filteredColumns(columns).indexOf(column) === 0;

        const columnsCount = nextColumnsCount(columns, column);

        const width = `${(100 / columns.length) * columnsCount}%`;
        const paddingLeft = `calc(${(100 / columns.length) * (columnsCount - 1)}% + 10px)`;

        return {
            width,
            ...(isFirstColumn && {
                paddingLeft,
            }),
        };
    };

    return (
        <ScrollContainer>
            <BaseTable className={cn(styles.InvestmentTable)}>
                <thead>
                    {rows.map((row, rowIndex) => (
                        row.row_type === 'table_header' && (
                            <tr key={rowIndex}>
                                {filteredColumns(row.columns).map((column, columnIndex) => (
                                    <th
                                        key={columnIndex}
                                        style={columnStyle(row.columns, column)}
                                    >
                                        <span style={column.color}>{column.title}</span>
                                    </th>
                                ))}
                            </tr>
                        )
                    ))}
                </thead>

                <tbody>
                    {rows.map((row, rowIndex) => (
                        (
                            row.row_type === 'table_body'
                    || row.row_type === 'table_highlighted_body'
                        ) && (
                            <tr
                                key={rowIndex}
                                data-highlighted={row.row_type === 'table_highlighted_body'}>
                                {filteredColumns(row.columns).map((column, columnIndex) => (
                                    <td
                                        key={columnIndex}
                                        style={columnStyle(row.columns, column)}
                                    >
                                        <span className={(column.color === 'Red') ? styles.Red : ((column.color === 'Green') ? styles.Green : '')}>{ column.title }</span>
                                        {column.subtitle && <span data-rel="subTitle">{ column.subtitle }</span>}
                                    </td>
                                ))}
                            </tr>
                        )
                    ))}
                </tbody>
            </BaseTable>
        </ScrollContainer>
    );
};

export default InvestmentTable;
