import React from 'react';
import moment from 'moment/moment';
import styles from './styles.module.scss';

const SupervisorOrderItem = ({ data }) => (
    <tr className={styles.OrderItemWrapper}>
        <td className={styles.OrderItemOrder}>{ data.orderId }</td>
        <td className={styles.OrderItemDate}>{ moment(data.date).format('DD.MM.YYYY') }</td>
        <td className={styles.OrderItemTotalPrice}>{ data.totalPrice.toLocaleString() } У</td>
        <td className={styles.OrderItemStatus}>{ data.status }</td>
        <td className={styles.OrderItemDescription}>{ data.description.split(',').map((el, index) => (<div key={index}>{el.trim()}</div>)) }</td>
        <td className={styles.OrderItemPlannedDeliveryText}>{ data.plannedDeliveryText }</td>
        <td className={styles.OrderItemEmployeeFullName}>{ data.sellerName }</td>
        <td className={styles.OrderItemOffice}>{ data.region.office }</td>
        <td className={styles.OrderItemTerritorialDirectorate}>{ data.region.territorialDirectorate }</td>
        <td className={styles.OrderItemRegionalDirectorate}>{ data.region.macroRegion }</td>
    </tr>
);

export default SupervisorOrderItem;
