import React from 'react';
import { Transition } from 'react-transition-group';
import Preloader from './Preloader/Preloader';

const FadeTransition = ({
    loading,
    children,
}) => {
    const duration = 150;

    const defaultTransitionStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
    };

    const transitionStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };
    return (
        loading ? <Preloader loading={loading}/> : <Transition in={!loading} timeout={duration}>
            {(state) => (
                <div style={{
                    ...defaultTransitionStyle,
                    ...transitionStyles[state],
                }}>{ children }</div>
            )}
        </Transition>
    );
};

export default FadeTransition;
