import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const UralCoinsStickerSpendButton = styled(Button)(() => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    maxWidth: 345,
    minWidth: 345,
    maxHeight: 64,
    minHeight: 64,
}));

export default UralCoinsStickerSpendButton;
