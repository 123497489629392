import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import ContentWrapper from 'components/ContentWrapper';
import Container from 'components/Container';
import OrdersList from 'components/OrdersList';
import MainTopWrapper from '../../components/MainTopWrapper';

import styles from './styles.module.scss';
import { ORDER_LIST } from '../../api';
import Heading from '../../components/Heading';

class OrdersPage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Личный кабинет продавца',
            data: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        document.title = this.state.title;
        ORDER_LIST().then((data) => {
            this.setState({ data: data.profile.orders, isLoading: false });
        });
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <ContentWrapper isLoading={this.state.isLoading}>
                        <Container>
                            <Heading className={styles.OrdersListHeading} level="4">{ 'Мои заказы' }</Heading>
                            <OrdersList data={this.state.data}/>
                        </Container>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(OrdersPage));
