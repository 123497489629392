import React, { useState } from 'react';
import cn from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { AUTH_LOGOUT } from 'api';
import Container from 'components/Container';
import styles from './styles.module.scss';

const HeaderNavBar = ({
    isSeller, isSupervisor, userName, profileImageUrl,
}) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    const logout = () => {
        AUTH_LOGOUT().then(() => {
            window.location.replace('/');
        });
    };
    return (
        <div className={styles.HeaderNavBar}>
            <Container fluid>
                <div
                    className={styles.HeaderNavBarWrapper}
                    data-menu-opened={isOpen}
                >
                    {isSeller && (
                        <div className={styles.HeaderNavBarItems}>
                            <Link to="/sales" className={cn(styles.HeaderNavBarItem, styles.HeaderNavBarLogo, styles.HeaderNavBarLogoLIFE)}></Link>

                            <Link
                                to="/sales"
                                className={cn(
                                    styles.HeaderNavBarItem,
                                    styles.HeaderNavBarItemSales,
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/sales' },
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/sales/additional' },
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/rating' },
                                )}>
                                {'Продажи'}
                            </Link>

                            <Link
                                to="/shop"
                                className={cn(
                                    styles.HeaderNavBarItem,
                                    styles.HeaderNavBarItemShop,
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/shop' },
                                )}
                            >
                                {'Магазин сувениров'}
                            </Link>

                            <Link
                                to="/cart"
                                className={cn(
                                    styles.HeaderNavBarItem,
                                    styles.HeaderNavBarItemCart,
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/cart' },
                                )}
                            >
                                {'Корзина'}
                            </Link>

                            <Link
                                to="/orders"
                                className={cn(
                                    styles.HeaderNavBarItem,
                                    styles.HeaderNavBarItemBell,
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/orders' },
                                )}
                            >
                                {'Заказы'}
                            </Link>
                            {profileImageUrl
                                ? <Link
                                    to="/profile"
                                    className={cn(
                                        styles.HeaderNavBarItem,
                                        { [styles.HeaderNavBarItemActive]: history.location.pathname === '/profile' },
                                    )}>
                                    {<>
                                        <img
                                            src={profileImageUrl}
                                            alt={'Фото профиля'}
                                            className={cn(
                                                styles.HeaderNavBarItem,
                                                styles.HeaderNavBarItemProfileImage,
                                            )}
                                        />
                                        <span>{userName}</span>
                                    </>}
                                </Link>
                                : <Link
                                    to="/profile"
                                    className={cn(
                                        styles.HeaderNavBarItem,
                                        styles.HeaderNavBarItemPerson,
                                        { [styles.HeaderNavBarItemActive]: history.location.pathname === '/profile' },
                                    )}>
                                    {userName}
                                </Link>
                            }
                            <div
                                className={cn(
                                    styles.HeaderNavBarItem,
                                    styles.HeaderNavBarItemLogout,
                                )}
                                onClick={logout}
                            >{'Выход'}</div>
                        </div>
                    )}
                    {isSupervisor && (
                        <div className={cn(styles.HeaderNavBarItems, isSeller && styles.HeaderNavBarItems_AlignRight)}>
                            {!isSeller && (
                                <Link to="/supervisor/sales" className={cn(styles.HeaderNavBarLogo, styles.HeaderNavBarLogoLIFE)}></Link>
                            )}
                            <Link
                                to="/supervisor/sales"
                                className={cn(
                                    styles.HeaderNavBarItem,
                                    styles.HeaderNavBarItemSales,
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/supervisor/sales' },
                                )}
                            >
                                {'Продажи моих сотрудников'}
                            </Link>

                            <Link
                                to="/supervisor/orders"
                                className={cn(
                                    styles.HeaderNavBarItem,
                                    styles.HeaderNavBarItemBell,
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/supervisor/orders' },
                                )}
                            >
                                {'Заказы моих сотрудников'}
                            </Link>

                            <Link
                                to="/supervisor/rating"
                                className={cn(
                                    styles.HeaderNavBarItem,
                                    styles.HeaderNavBarItemBell,
                                    { [styles.HeaderNavBarItemActive]: history.location.pathname === '/supervisor/rating' },
                                )}
                            >
                                {'Рейтинг моих сотрудников'}
                            </Link>
                            {!isSeller && (
                                <>
                                    <Link
                                        /* to="/profile" */
                                        className={cn(
                                            styles.HeaderNavBarItem,
                                            styles.HeaderNavBarItemPerson,
                                            { [styles.HeaderNavBarItemActive]: history.location.pathname === '/profile' },
                                        )}>
                                        {/* {userName} */}
                                    </Link>

                                    <div
                                        className={cn(
                                            styles.HeaderNavBarItem,
                                            styles.HeaderNavBarItemLogout,
                                        )}
                                        onClick={logout}
                                    >{'Выход'}</div></>
                            )}
                        </div>
                    )}

                    <div
                        className={styles.HeaderNavBarMenuToggler}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HeaderNavBar;
