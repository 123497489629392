import React from 'react';
import { AUTH_USER, GET_CLIENT_INFO, CHANGE_PASSWORD, RESTORE_ACCESS, CHECK_CAPTCHA } from 'api';
import cn from 'classnames';
import parse from 'html-react-parser';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import styles from './styles.module.scss';
import Button from '../Button';
import Modal from '../Modal';
import { getCaptchaSiteKey, isTestEnvironment, isProdEnvironment } from '../../helpers/helpers';

const modalType = {
    errorAuth: 'errorAuth',
    formChangePass: 'formChangePass',
    formSetNewPass: 'formSetNewPass',
    successChangePass: 'successChangePass',
};

class LoginForm extends React.Component {
    form = { ...this.initialForm };

    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            adminLogin: '',
            adminPass: '',
            adminEmail: '',
            adminLoginNewPass: '',
            validateLoginNewPass: null,
            adminNewPass: '',
            validateNewPass: null,
            adminNewPassRep: '',
            validateNewPassRep: null,
            adminOldPass: '',
            validateOldPass: null,
            buttonAuthLoading: '',
            buttonEmailLoading: '',
            buttonDisabled: '',
            buttonEmailDisabled: true,
            buttonChangePassDisabled: true,
            modalTitle: '',
            isOpenModal: false,
            modalType: 'formSetNewPass',
            error: '',
            errorAuth: '',
            isShowBtnChangePass: true,
            validateEmail: true,
            validatePass: true,
            visibleCaptcha: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getFormChangePass = this.getFormChangePass.bind(this);
        this.handleSubmitChangePass = this.handleSubmitChangePass.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.changeModalForm = this.changeModalForm.bind(this);
        this.changePassButton = this.changePassButton.bind(this);
        this.successChangePass = this.successChangePass.bind(this);
        this.isValidEmail = this.isValidEmail.bind(this);
        this.isValidNewPass = this.isValidNewPass.bind(this);
        this.setNewPasswordModal = this.setNewPasswordModal.bind(this);
        this.handleChangeLoginNewPass = this.handleChangeLoginNewPass.bind(this);
        this.handleChangeNewPass = this.handleChangeNewPass.bind(this);
        this.handleChangeNewPassRep = this.handleChangeNewPassRep.bind(this);
        this.handleChangeOldPass = this.handleChangeOldPass.bind(this);
        this.handleSubmitSetNewPass = this.handleSubmitSetNewPass.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleSetCaptchaModalVisible = this.handleSetCaptchaModalVisible.bind(this);
        this.handleChallengeHidden = this.handleChallengeHidden.bind(this);
        this.handleCheckTokenAndSubmit = this.handleCheckTokenAndSubmit.bind(this);
    }

    handleChangeLoginNewPass(e) {
        if (e.target.value === '') {
            this.setState({
                validateLoginNewPass: false,
            });
        } else {
            this.setState({
                validateLoginNewPass: true, adminLoginNewPass: e.target.value,
            });
        }
    }

    handleChangeNewPass(e) {
        if (e.target.value === '') {
            this.setState({
                validateNewPass: false,
            });
        } else if (this.isValidNewPass(e.target.value)) {
            this.setState({
                validateNewPass: true, adminNewPass: e.target.value,
            });
        } else {
            this.setState({
                validateNewPass: false, adminNewPass: e.target.value,
            });
        }
    }

    handleChangeNewPassRep(e) {
        if (e.target.value !== this.state.adminNewPass || e.target.value === '') {
            this.setState({ validateNewPassRep: false });
        } else {
            this.setState({
                adminNewPassRep: e.target.value,
                validateNewPassRep: true,
            });
        }
    }

    handleChangeOldPass(e) {
        if (e.target.value === '') {
            this.setState({
                validateOldPass: false,
            });
        } else {
            this.setState({
                validateOldPass: true, adminOldPass: e.target.value,
            });
        }
    }

    handleChangeLogin(e) {
        this.setState({
            adminLogin: e.target.value,
        });
    }

    handleChangePassword(e) {
        this.setState({
            adminPass: e.target.value,
        });
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    isValidNewPass(pass) {
        return /(?=.*\d)(?=.*[A-Z]).{8,}$/.test(pass);
    }

    handleChangeEmail(e) {
        if (e.target.value === '' && !this.isValidEmail(e.target.value)) {
            this.setState({ validateEmail: false, buttonEmailDisabled: true });
        } else {
            this.setState({ validateEmail: true, buttonEmailDisabled: false });
        }
        this.setState({
            adminEmail: e.target.value,
        });
    }

    handleSubmitSetNewPass(e) {
        return new Promise((res) => {
            CHANGE_PASSWORD(this.state.adminLoginNewPass, this.state.adminOldPass, this.state.adminNewPass)
                .then((data) => {
                    res();
                    this.closeModal();
                }).catch((error) => {
                    const { message } = error.data;
                    this.setState({ error: message });
                });
        });
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            modalType: modalType.errorAuth,
            errorAuth: '',
            error: '',
        });
    }

    setNewPasswordModal() {
        return (<form>
            <label className={cn(styles.InputFieldLabelWrapper, styles.InputFieldLabelWrapperPassword)}>Логин</label>
            <input type="login" name="formLogin" className={styles.InputType} onChange={this.handleChangeLoginNewPass} required /><br/>
            {!this.state.validateLoginNewPass && <div className={styles.Error} id="error">
              Обязательное поле
                {this.state.validateLoginNewPass}
            </div>}
            <label className={cn(styles.InputFieldLabelWrapper, styles.InputFieldLabelWrapperPassword)}>Временный пароль</label>
            <input id="OldPass" type="text" name="OldPass" className={styles.InputType} onChange={this.handleChangeOldPass} required /><br/>
            {!this.state.validateOldPass && <div className={styles.Error} id="error">
              Обязательное поле
                {this.state.validateOldPass}
            </div>}
            <label className={cn(styles.InputFieldLabelWrapper, styles.InputFieldLabelWrapperPassword)}>Новый пароль</label>
            <input id="NewPass" type="password" name="NewPass" className={styles.InputType} onChange={this.handleChangeNewPass} required /><br/>
            {!this.state.validateNewPass && <div className={styles.Error} id="error">
              Пароль должен состоять из латинских букв, содержать хотя бы одно число, одну заглавную букву и длиной не менее 8 символов
                {this.state.validateNewPass}
            </div>}
            <label className={cn(styles.InputFieldLabelWrapper, styles.InputFieldLabelWrapperPassword)}>Повторите новый пароль</label>
            <input id="NewPassRep" type="password" name="NewPassRep" className={styles.InputType} onChange={this.handleChangeNewPassRep} required /><br/>
            {!this.state.validateNewPassRep && <div className={styles.Error} id="error">
              Пароли не совпадают!
            </div>}
            <div className={styles.Error} id="error">
                {parse(this.state.error)}
            </div>
            <div className={styles.buttonBLock}>
                <Button
                    variant="primary"
                    size="lg"
                    className={styles.button}
                    disabled={
                        !this.state.validateLoginNewPass
                      || !this.state.validateNewPass
                      || !this.state.validateNewPassRep
                      || !this.state.validateOldPass
                    }
                    onClick={this.handleSubmitSetNewPass}
                >{ 'Сменить' }</Button>
                <Button
                    variant="primary"
                    size="lg"
                    buttonType="text"
                    className={styles.button}
                    onClick={ () => this.closeModal()}
                >{ 'Закрыть' }</Button>
            </div>

        </form>);
    }

    changeModalForm() {
        this.setState({
            modalType: modalType.formChangePass,
            modalTitle: 'Сбросить пароль',
        });
    }

    successChangePass() {
        return (<>
            <p>На указанный адрес электронной почты отправлено письмо с новым паролем</p>
            <div className={styles.buttonBLock}>
                <Button
                    variant="primary"
                    id="btn-submit"
                    size="lg"
                    type="button"
                    onClick={() => this.closeModal()}
                    className={styles.button}
                >{ 'Закрыть' }</Button>
                <Button
                    variant="primary"
                    id="btn-submit"
                    size="lg"
                    type="button"
                    onClick={() => {
                        this.setState({
                            modalType: 'formSetNewPass',
                            modalTitle: 'Смена пароля',
                        });
                    }}
                    className={styles.button}
                >{ 'Сменить пароль' }</Button>
            </div>
        </>
        );
    }

    getFormChangePass() {
        return (<form>
            <label className={cn(styles.InputFieldLabelWrapper, styles.InputFieldLabelWrapperPassword)}>E-mail</label>
            <input id="formEmail" type="email" name="formEmail" className={styles.InputType} onChange={this.handleChangeEmail} required /><br/>
            {!this.state.validateEmail && <div className={styles.Error} id="error">
              Неправильный формат E-mail
            </div>}
            <div className={styles.Error} id="error">
                {parse(this.state.error)}
            </div>
            <div className={styles.buttonBLock}>
                <Button
                    variant="primary"
                    size="lg"
                    className={styles.button}
                    loading={this.state.buttonEmailLoading}
                    disabled={this.state.buttonEmailDisabled}
                    onClick={this.handleSubmitChangePass}
                >{ 'Сбросить' }</Button>
                <Button
                    variant="primary"
                    size="lg"
                    buttonType="text"
                    className={styles.button}
                    onClick={ () => this.closeModal()}
                >{ 'Закрыть' }</Button>
            </div>

        </form>);
    }

    changePassButton() {
        return (<>
            <p>{this.state.errorAuth}</p>
            <div className={styles.buttonBLock}>
                <Button
                    /* style={{width: 285}} */
                    variant="primary"
                    id="btn-submit"
                    size="lg"
                    type="button"
                    onClick={() => this.closeModal()}
                    className={styles.button}
                >{ 'Повторить ввод' }</Button>
                {this.state.isShowBtnChangePass && <Button
                    size="lg"
                    buttonType="text"
                    variant="primary"
                    onClick={this.changeModalForm}
                    className={styles.button}
                >{'Сбросить пароль'}</Button>}
            </div>
        </>
        );
    }

    handleSubmitChangePass() {
        this.setState({ buttonEmailLoading: true, buttonEmailDisabled: true });

        return new Promise((res) => {
            RESTORE_ACCESS(this.state.adminEmail)
                .then((data) => {
                    res();
                    this.setState({ buttonEmailLoading: false, buttonEmailDisabled: false, modalType: modalType.successChangePass });
                }).catch((error) => {
                    const { message } = error.data;
                    this.setState({ modalTitle: 'Сбросить пароль', error: message });
                    this.setState({ buttonEmailLoading: true, buttonEmailDisabled: true });
                });
        });
    }

    handleSubmit() {
        this.setState({
            buttonAuthLoading: true,
            buttonDisabled: true,
        });
        return new Promise((res) => {
            AUTH_USER(this.state.adminLogin, this.state.adminPass)
                .then((data) => {
                    if (data && data.success === true) {
                        res();
                        GET_CLIENT_INFO()
                            .then(({ profile }) => {
                                this.setState({
                                    buttonAuthLoading: false,
                                    buttonDisabled: false,
                                });
                                if (profile.isSeller) {
                                    window.location.href = '/sales';
                                } else if (profile.isSupervisor) {
                                    window.location.href = '/supervisor/sales';
                                }
                            }).catch((error) => {
                                const { message } = error.data;
                                document.getElementById('error').innerHTML = message || 'Ошибка авторизации';
                                console.log(message);
                                this.setState({
                                    buttonLoading: false,
                                    buttonDisabled: false,
                                });
                            });
                    }
                }).catch((error) => {
                    const { message, errorCode } = error.data;
                    this.setState({
                        buttonAuthLoading: false,
                        buttonDisabled: false,
                        isOpenModal: true,
                        modalTitle: 'Ошибка',
                        errorAuth: message,
                        isShowBtnChangePass: errorCode !== 'SinglePortalException' && errorCode !== 'EntitySearchException',
                        modalType: modalType.errorAuth,
                    });
                });
        });
    }

    handleSignIn(e) {
        e.preventDefault();
        this.handleSubmit();
    }

    handleSetCaptchaModalVisible(e) {
        e.preventDefault();
        this.setState({ visibleCaptcha: true });
    }

    handleChallengeHidden() {
        this.setState({ visibleCaptcha: false });
    }

    handleCheckTokenAndSubmit(token) {
        CHECK_CAPTCHA(token).then(() => {
            this.handleSubmit();
        });
    }

    componentDidMount() {
        document.addEventListener('keydown', (event) => {
            if (event.code === 'Enter') {
                this.handleSubmit();
            }
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', (event) => {
            if (event.code === 'Enter') {
                this.handleSubmit();
            }
        });
    }

    render() {
        const isCheckCaptcha = isTestEnvironment() || isProdEnvironment();
        return (
            <>
                {isCheckCaptcha
                    && <InvisibleSmartCaptcha
                        sitekey={getCaptchaSiteKey()}
                        onSuccess={(token) => this.handleCheckTokenAndSubmit(token)}
                        onChallengeHidden={this.handleChallengeHidden}
                        visible={this.state.visibleCaptcha}
                    />
                }
                <form className="form" onSubmit={isCheckCaptcha ? this.handleSetCaptchaModalVisible : this.handleSignIn}>
                    <label className={styles.InputFieldLabelWrapper}>Логин</label>
                    <input type="text" name="formLogin" className={styles.InputType} onChange={this.handleChangeLogin} required /><br/>
                    <label className={cn(styles.InputFieldLabelWrapper, styles.InputFieldLabelWrapperPassword)}>Пароль</label>
                    <input id="formPass" type="password" name="formPass" className={styles.InputType} onChange={this.handleChangePassword} required /><br/>
                    <div id="error" className={styles.Error}></div>
                    <Button
                        style={{ width: '100%', marginTop: 30 }}
                        variant="primary"
                        id="btn-submit"
                        size="lg"
                        type="submit"
                        className={styles.button}
                        disabled={this.state.buttonDisabled}
                        loading={this.state.buttonAuthLoading}>{ 'Войти' }</Button>
                    { this.state.isOpenModal && <Modal
                        classNameModal={styles.LoginFormModal}
                        handleClose={this.closeModal}
                        title={this.state.modalTitle}
                        body={this.state.modalType === modalType.formChangePass ? this.getFormChangePass()
                            : this.state.modalType === modalType.errorAuth ? this.changePassButton()
                                : this.state.modalType === modalType.successChangePass ? this.successChangePass()
                                    : this.state.modalType === modalType.formSetNewPass ? this.setNewPasswordModal() : null }
                    />}
                </form>
            </>
        );
    }
}

export default LoginForm;
