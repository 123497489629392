import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CartListOrderTextField = styled(TextField)(() => ({
    maxWidth: 378,
    minWidth: 378,
    maxHeight: 64,
    minHeight: 64,
}));

export default CartListOrderTextField;
