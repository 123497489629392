import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const Modal = ({
    title,
    subTitle,
    body,
    footer,
    description,
    handleClose,
    classNameModal,
    classNameModalHead,
    classNameModalTitle,
    classNameModalFoot,
}) => (
    <div className={styles.ModalContainer}>
        <div
            className={styles.ModalOverlay}
            onClick={handleClose}></div>
        <div className={cn(styles.Modal, classNameModal)}>
            {handleClose !== undefined && (
                <div
                    className={styles.ModalClose}
                    onClick={handleClose}
                />
            )}

            <div className={cn(styles.ModalHead, classNameModalHead)}>
                <div className={cn(styles.ModalTitle, classNameModalTitle)}>
                    { title }

                    { subTitle && <div className={styles.ModalSubTitle}>{ subTitle }</div> }
                </div>
            </div>

            <div className={styles.ModalBody}>
                { body }
            </div>

            {description
                && <div className={styles.ModalBody}>
                    { description }
                </div>
            }

            <div className={cn(styles.ModalFoot, classNameModalFoot)}>
                { footer }
            </div>
        </div>
    </div>
);

export default Modal;
