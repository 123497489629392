import React, { Component } from 'react';
import Heading from 'components/Heading';
import moment from 'moment';
import styles from './styles.module.scss';
import AdditionalPointsItem from '../AdditionalPointsItem';
import FilterWrapper from '../FilterWrapper';
import DateInputField from '../DateInputField';

class AdditionalPointsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: this.props.data,
            periodStart: '',
            periodEnd: '',
        };
    }

    filterTable() {
        let filterData = this.props.data;
        if (this.state.periodStart) {
            filterData = filterData.filter((item) => moment(item.eventDate).isSameOrAfter(moment(this.state.periodStart)));
        }
        if (this.state.periodEnd) {
            filterData = filterData.filter((item) => moment(item.eventDate).isSameOrBefore(moment(this.state.periodEnd)));
        }
        this.setState({ tableData: filterData });
    }

    render() {
        return (
            <div className={styles.AdditionalPointsListWrapper}>
                <Heading className={styles.AdditionalPointsListHeading} level="4">{'История начисления баллов'}</Heading>
                <div className={styles.AdditionalPointsListFilterWrapper} >
                    <FilterWrapper
                        filterClick={this.filterTable.bind(this)}
                    >
                        <DateInputField
                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            selectsStart
                            label="Период"
                            value={this.state.periodStart}
                            size="lg"
                            icon="calendar"
                            format="dd.MM.yyyy"
                            placeholder="дд.мм.гггг"
                            content='c'
                            onChange={(value) => this.setState({ ...this.state, periodStart: value })}/>
                        <DateInputField
                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            selectsEnd
                            size="lg"
                            icon="calendar"
                            format="dd.MM.yyyy"
                            placeholder="дд.мм.гггг"
                            content='по'
                            value={this.state.periodEnd}
                            onChange={(value) => this.setState({ ...this.state, periodEnd: value })}/>
                    </FilterWrapper>
                </div>
                <div className={styles.AdditionalPointsListTable}>
                    <div className={styles.AdditionalPointsListTableHeader}>
                        <div className={styles.TableHeaderEventDate}>{'Дата события'}</div>
                        <div className={styles.TableHeaderDescription}>{'Событие начисления'}</div>
                        <div className={styles.TableHeaderPoints}>{'Баллы'}</div>
                    </div>
                    {this.state.tableData.map((item, index) => (
                        <AdditionalPointsItem
                            key={index}
                            firstIndex={index === 0}
                            lastIndex={index + 1 === this.state.tableData.length}
                            data={item}/>
                    ))}
                </div>
            </div>
        );
    }
}

export default AdditionalPointsList;
