import { observable, action, decorate } from 'mobx';
import { GET_POLICY } from 'api';

class PolicyModule {
    constructor(props, appStore) {
        this.appStore = appStore;
    }

    policy = {};

    getPolicy(id) {
        return GET_POLICY(id)
            .then((data) => {
                const { policy, companyCode } = data;
                policy.companyCode = companyCode;
                this.policy = policy;
            }).catch(() => {});
    }

    clearPolicy() {
        this.policy = {};
    }
}

decorate(PolicyModule, {
    policy: observable,
    getPolicy: action.bound,
    clearPolicy: action.bound,
});

export default PolicyModule;
