import React from 'react';
import moment from 'moment/moment';
import styles from './styles.module.scss';
import TopWrapper from '../TopWrapper';
import Container from '../Container';
import HeadingWrapper from '../HeadingWrapper';
import Heading from '../Heading';
import UserLevelSticker from '../UserLevelSticker';
import RatingPositionSticker from '../RatingPositionSticker';
import UralCoinsSticker from '../UralCoinsSticker';

import nightProfileBackground from '../../assets/images/night_profile_background.jpg';
import morningProfileBackground from '../../assets/images/morning_profile_background.jpg';
import dayProfileBackground from '../../assets/images/day_profile_background.jpg';
import evenProfileBackground from '../../assets/images/even_profile_background.jpg';

class MainTopWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            dayTitle: '',
            dayBackground: null,
            userFirstName: '',
        };
    }

    getTimeCategory(time) {
        const timeFormat = 'HH:mm:ss';

        if (
            time.isBetween(moment('00:00:00', timeFormat), moment('04:59:59', timeFormat))
            || time.isSame(moment('00:00:00', timeFormat))
            || time.isSame(moment('04:59:59', timeFormat))
        ) {
            this.setState({
                ...this.state,
                dayTitle: 'Доброй ночи',
                dayBackground: nightProfileBackground,
            });
        } else if (
            time.isBetween(moment('05:00:00', timeFormat), moment('11:59:59', timeFormat))
            || time.isSame(moment('05:00:00', timeFormat))
            || time.isSame(moment('11:59:59', timeFormat))
        ) {
            this.setState({
                ...this.state,
                dayTitle: 'Доброе утро',
                dayBackground: morningProfileBackground,
            });
        } else if (
            time.isBetween(moment('12:00:00', timeFormat), moment('17:59:59', timeFormat))
            || time.isSame(moment('12:00:00', timeFormat))
            || time.isSame(moment('17:59:59', timeFormat))
        ) {
            this.setState({
                ...this.state,
                dayTitle: 'Добрый день',
                dayBackground: dayProfileBackground,
            });
        } else if (
            time.isBetween(moment('18:00:00', timeFormat), moment('23:59:59', timeFormat))
            || time.isSame(moment('18:00:00', timeFormat))
            || time.isSame(moment('23:59:59', timeFormat))
        ) {
            this.setState({
                ...this.state,
                dayTitle: 'Добрый вечер',
                dayBackground: evenProfileBackground,
            });
        }
    }

    getOnlyName() {
        if (this.props.profile.user.name) {
            const userName = this.props.profile.user.name.split(' ');
            this.setState({
                userFirstName: userName[1],
            });
        }
    }

    componentDidMount() {
        const format = 'hh:mm:ss';
        const currentTime = moment(new Date(), format);
        this.getTimeCategory(currentTime);
        this.getOnlyName();
    }

    render() {
        const { children, profile } = this.props;
        return (
            <TopWrapper
                background={this.state.dayBackground}
                hasBothRoles={profile.user.isSeller && profile.user.isSupervisor}>
                <Container fluid>
                    {!children
                        && <HeadingWrapper>
                            <Heading
                                level="2"
                                color="#FFFFFF">
                                {`${this.state.dayTitle}, ${this.state.userFirstName}!`}
                            </Heading>
                            {profile.user.isSeller && (
                                <div className={styles.StickersBlock}>
                                    <UserLevelSticker
                                        level={profile.user.level.currentPeriod.level}
                                        planet={profile.getUserLevelPlanet('planet')}/>
                                    <RatingPositionSticker
                                        position={(profile.user.personalRating) ? profile.user.personalRating : 0}/>
                                    <UralCoinsSticker
                                        points={(profile.user.balance) ? profile.user.balance : 0}
                                        validTo={profile.user.level.currentPeriod.validTo}
                                        isFrozen={false}/>
                                </div>
                            )}
                        </HeadingWrapper>}
                    {children}
                </Container>
            </TopWrapper>
        );
    }
}

export default MainTopWrapper;
