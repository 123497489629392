import React from 'react';
import styles from './styles.module.scss';

const LoginCard = ({
    title,
    onClose = undefined,
    children,
}) => (
    <div className={styles.LoginCard}>
        {onClose !== undefined && (
            <div
                className={styles.LoginCardClose}
                onClick={onClose}
            />
        )}

        <div className={styles.LoginCardTitle}>{ title }</div>
        { children }
    </div>
);

export default LoginCard;
