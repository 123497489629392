import React from 'react';
import moment from 'moment/moment';
import styles from './styles.module.scss';

const OrderItem = ({ data }) => (
    <tr className={styles.OrderItemWrapper}>
        <td className={styles.OrderItemOrder}>{ data.orderId }</td>
        <td className={styles.OrderItemDate}>{ moment(data.date).format('DD.MM.YYYY') }</td>
        <td className={styles.OrderItemTotalPrice}>{ data.totalPrice } У</td>
        <td className={styles.OrderItemStatus}>{ data.status }</td>
        <td className={styles.OrderItemDescription}
            dangerouslySetInnerHTML={{ __html: data.description }}>
        </td>
        <td className={styles.OrderItemPlannedDeliveryText}>{ data.plannedDeliveryText }</td>
    </tr>
);

export default OrderItem;
