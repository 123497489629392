import React from 'react';
import DetailsContentWrapper from 'components/_modules/wrappers/DetailsContentWrapper/index.js';
import AdditionalProfitTable from 'components/_modules/policy/AdditionalProfitTable';

const AdditionalIncomeTab = ({ items }) => (
    <React.Fragment>
        {items.map((item, itemIndex) => (
            <React.Fragment key={itemIndex}>
                {(item.type === 'additional_profit_table') && (
                    <DetailsContentWrapper
                        title={item.title}
                        isToggle={item.closed}
                    >
                        <AdditionalProfitTable rows={item.rows}/>
                    </DetailsContentWrapper>
                )}
            </React.Fragment>
        ))}
    </React.Fragment>
);
export default AdditionalIncomeTab;
