import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import ContentWrapper from 'components/ContentWrapper';
import Container from 'components/Container';
import SupervisorPolicySalesList from 'components/SupervisorPolicySalesList';
import MainTopWrapper from '../../components/MainTopWrapper';
import { SUPERVISOR_POLICY_LIST } from '../../api';

class SupervisorSalesPage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Продажи моих сотрудников',
            data: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        document.title = this.state.title;
        SUPERVISOR_POLICY_LIST().then(({ data }) => {
            this.setState({ data, isLoading: false });
        });
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <ContentWrapper isLoading={this.state.isLoading}>
                        <Container>
                            <SupervisorPolicySalesList
                                data={this.state.data}/>
                        </Container>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(SupervisorSalesPage));
