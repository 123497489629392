import React from 'react';
import styles from './styles.module.scss';

const Badge = ({
    children,
    ...rest
}) => (
    <div
        className={styles.Badge}
        {...rest}>
        { children }
    </div>
);

export default Badge;
