import React from 'react';
import styles from './styles.module.scss';

const TitledContent = ({
    title,
    content,
    color,
}) => (
    <div
        className={styles.TitledContent}
        style={{ color }}>
        <div className={styles.TitledContentTitle}>{ title }</div>
        <div className={styles.TitledContentContent}>{ content }</div>
    </div>
);

export default TitledContent;
