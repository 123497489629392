import React from 'react';
import styles from './styles.module.scss';

const LoginWrapper = ({ children }) => (
    <div className={styles.LoginWrapper}>
        { children }
    </div>
);

export default LoginWrapper;
