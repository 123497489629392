import React from 'react';
import cn from 'classnames';
import HeaderNavBar from './HeaderNavBar';
import styles from './styles.module.scss';

const MainHeader = ({
    hasBackground = true,
    isSeller,
    isSupervisor,
    userName,
    profileImageUrl,
}) => (
    <div className={cn(styles.Header, { [styles.HeaderLight]: !hasBackground })}>

        <HeaderNavBar
            isSeller={isSeller}
            isSupervisor={isSupervisor}
            userName={userName}
            profileImageUrl={profileImageUrl}
        />
    </div>
);

export default MainHeader;
