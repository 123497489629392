import React from 'react';
import ScrollContainer from 'components/ScrollContainer';
import styles from './styles.module.scss';

const ToggleTabs = ({
    children,
}) => (
    <ScrollContainer>
        <div className={styles.ToggleTabs}>
            { children }
        </div>
    </ScrollContainer>
);

export const ToggleTabsItem = ({
    active,
    onClick,
    children,
}) => (
    <div
        className={styles.ToggleTabsItem}
        data-active={active}
        onClick={onClick}>
        { children }
    </div>
);

export const ToggleTabsWrapper = ({
    children,
}) => (
    <div className={styles.ToggleTabsWrapper}>
        { children }
    </div>
);

export default ToggleTabs;
