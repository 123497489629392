import React, { useState } from 'react';
import styles from './styles.module.scss';

const ToggleWrapper = ({
    active,
    toggle,
    children,
}) => {
    const [scopeActive, setScopeActive] = useState(active);

    return (
        <div className={styles.ToggleWrapper}>
            <div
                className={styles.ToggleWrapperToggle}
                data-active={scopeActive}
                onClick={() => setScopeActive(!scopeActive)}>
                { toggle }
            </div>

            <div
                className={styles.ToggleWrapperInner}
                data-active={scopeActive}>
                { children }
            </div>
        </div>
    );
};

export default ToggleWrapper;
