import React from 'react';
import BaseTable from 'components/BaseTable';
import QuestionTooltip from '../../QuestionTooltip';

const PolicyBaseTable = ({
    rows,
    className,
    policyId,
    children,
}) => (
    <BaseTable className={className}>
        <thead>
            {rows.map((row, rowIndex) => (
                row.row_type === 'table_header' && (
                    <tr key={rowIndex}>
                        {row.columns.filter((column) => column.title && column.title !== 'null').map((column, columnIndex) => (
                            <th key={columnIndex}>{column.title}</th>
                        ))}
                    </tr>
                )
            ))}
        </thead>

        <tbody>
            {rows.map((row, rowIndex) => (
                (
                    row.row_type === 'table_body'
                    || row.row_type === 'table_highlighted_body'
                ) && (
                    <tr
                        key={rowIndex}
                        data-highlighted={row.row_type === 'table_highlighted_body'}>
                        {row.columns.filter((column) => column.title || column.subtitle || column.description).map((column, columnIndex) => (
                            <td key={columnIndex}>
                                { (column.description && column.description !== null) && <QuestionTooltip policyId={policyId} text={ column.description } />}
                                <span>{ column.title }</span>
                                {column.subtitle && <span data-rel="subTitle">{ column.subtitle }</span>}
                            </td>
                        ))}
                        {row.row_type === 'table_highlighted_body' && (
                            <td>
                                { children }
                            </td>
                        )}
                    </tr>
                )
            ))}
        </tbody>
    </BaseTable>
);

export default PolicyBaseTable;
