import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
    style = {},
    width = '200',
    height = '197',
    className = '',
    viewBox = '0 0 200 197',
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        xmlns='http://www.w3.org/2000/svg'
        className={`svg-icon ${className || ''}`}
        xmlnsXlink='http://www.w3.org/1999/xlink'
    >
        <g transform="translate(0.000000,197.000000) scale(0.100000,-0.100000)"
            stroke="none">
            <path fill='#EA4C4E' d="M895 1959 c-330 -32 -627 -234 -779 -529 -274 -531 -10 -1177 559
-1371 86 -29 157 -42 288 -54 l67 -6 0 184 0 184 -73 6 c-250 21 -468 185
-547 410 -103 294 30 615 311 750 96 47 175 67 261 67 l48 0 0 185 0 185 -22
-1 c-13 -1 -63 -6 -113 -10z"/>
            <path fill='#183B90' d="M1200 1694 l0 -254 -97 0 c-209 -1 -330 -40 -430 -140 -240 -242
-130 -648 201 -744 37 -11 96 -16 190 -16 l136 0 0 -255 c0 -227 2 -255 16
-255 26 0 189 57 246 86 357 181 574 577 531 967 -46 409 -325 736 -721 846
l-72 20 0 -255z"/>
        </g>
    </svg>
);

SVG.propTypes = {
    style: PropTypes.object,
    fill: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
    viewBox: PropTypes.string,
};

export default SVG;
