import { observable, action, decorate } from 'mobx';
import { GET_SOCIAL_TAX_DEDUCTION_CERTIFICATE_LIST, GET_SOCIAL_TAX_DEDUCTION_SELECTIONS } from 'api';

class TaxDeductionModule {
    selections = [];

    certificates = [];

    initialization = false;

    loading = true;

    getSelections(policyId) {
        return GET_SOCIAL_TAX_DEDUCTION_SELECTIONS(policyId).then(({ result }) => {
            this.selections = result;
        });
    }

    getCertificates(policyId) {
        return GET_SOCIAL_TAX_DEDUCTION_CERTIFICATE_LIST(policyId).then(({ result }) => {
            this.certificates = result;
        });
    }

    setInitialization(value) {
        this.initialization = value;
    }

    setLoading(value) {
        this.loading = value;
    }
}

decorate(TaxDeductionModule, {
    selections: observable,
    certificates: observable,
    initialization: observable,
    loading: observable,
    getSelections: action.bound,
    getCertificates: action.bound,
    setInitialization: action.bound,
    setLoading: action.bound,
});

export default TaxDeductionModule;
