import { observable, action, decorate } from 'mobx';

class AppModule {
    constructor(props, appStore) {
        this.appStore = appStore;
    }

    interval = '';

    value = 0;

    startsCount = 0;

    start() {
        if (this.startsCount === 0) {
            this.value = 0;

            this.interval = setInterval(() => {
                this.value += (5 / (this.startsCount || 1));

                if (this.value >= 100) {
                    clearInterval(this.interval);
                }
            }, 500);
        }

        this.startsCount += 1;
    }

    complete() {
        this.startsCount = this.startsCount === 0 ? this.startsCount : this.startsCount - 1;

        if (this.startsCount === 0) {
            clearInterval(this.interval);

            this.value = 100;
        }
    }
}

decorate(AppModule, {
    interval: observable,
    value: observable,
    startsCount: observable,
    start: action.bound,
    complete: action.bound,
});

export default AppModule;
