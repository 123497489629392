import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Container from 'components/Container';
import styles from './styles.module.scss';

const BaseHeader = () => (
    <div className={styles.BaseHeader}>
        <nav className={styles.BaseHeaderNav}>
            <Container fluid>
                <div className={styles.BaseHeaderNavContainer}>
                    <a href='/' className={cn(styles.BaseHeaderNavLogo, styles.BaseHeaderNavLogoLIFE)}> </a>
                    <div className={styles.BaseHeaderNavContent}>
                        <div className={styles.BaseHeaderNavLoginItem} data-active={true}>
                            <Link to="/login">
                                { 'Войти' }
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        </nav>
    </div>
);

export default BaseHeader;
