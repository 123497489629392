import React from 'react';
import styles from './styles.module.scss';

const TextContent = ({
    dangerouslySetInnerHTML,
    children,
}) => (
    <p
        className={styles.TextContent}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
        { children }
    </p>
);

export default TextContent;
