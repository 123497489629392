import React, { forwardRef } from 'react';
import styles from './styles.module.scss';

const Heading = forwardRef(({
    level = 1,
    color,
    description,
    loading = false,
    children,
    ...rest
}, ref) => {
    const HeadingComponent = `h${level}`;

    return (
        <div
            className={styles.HeadingWrapper}
            {...rest}>
            <HeadingComponent
                ref={ref}
                className={styles.Heading}
                style={{ color }}
                data-loading={loading}
            >
                { children }
            </HeadingComponent>
            {description && <div className={styles.HeadingDescription}>{ description }</div>}
        </div>
    );
});

export default Heading;
