import React from 'react';
import styles from './styles.module.scss';

const QuestionLabel = ({ text }) => (
    <div className={styles.QuestionLabel}>
        <div className={styles.QuestionLabelHandler}>
            <div className={styles.QuestionLabelContent}>{ text }</div>
        </div>
    </div>
);

export default QuestionLabel;
