import React, { forwardRef } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import QuestionLabel from '../QuestionLabel';

const InputField = forwardRef(({
    label,
    questionLabel,
    text,
    size,
    icon,
    error,
    description,
    className,
    children,
    required = false,
    onEdit = null,
    ...rest
}, ref) => {
    const sizeClass = () => {
        switch (size) {
        case 'lg':
            return styles.InputFieldSizeLg;
        default:
            return null;
        }
    };

    const iconClass = () => {
        switch (icon) {
        case 'calendar':
            return cn(styles.InputFieldIcon, styles.InputFieldIconCalendar);
        default:
            return null;
        }
    };

    return (
        <div
            className={cn(styles.InputField, sizeClass(), iconClass())}
            data-required={required}
            {...error && {
                'data-error': error,
            }}
        >
            {(label || text || children || onEdit) && (
                <div className={styles.InputFieldLabelWrapper}>
                    {label && <label className={styles.InputFieldLabel}>{ label }{
                        questionLabel && <QuestionLabel text={questionLabel} />
                    }
                    </label>}
                    {text && <div className={styles.InputFieldText}>{ text }{
                        questionLabel && <QuestionLabel text={questionLabel} />
                    }</div>}
                    {children && <div className={styles.InputFieldLabelWrapperInner}>{ children }</div>}
                    {onEdit && <div className={styles.InputFieldLabelEdit} onClick={onEdit} />}
                    {description && <QuestionLabel text={description} />}
                </div>
            )}

            <div className={styles.InputFieldWrapper}>
                <input
                    ref={ref}
                    className={cn(className, styles.InputFieldControl)}
                    {...rest} />
                <div className={styles.InputFieldWrapperInner}>{ children }</div>
            </div>

            {(error && typeof error !== 'boolean') && <div className={styles.InputFieldError}>{ error }</div>}
        </div>
    );
});

export default InputField;
