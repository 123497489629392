import React from 'react';
import Heading from 'components/Heading';
import NewsCard from 'components/NewsCard';
import styles from './styles.module.scss';
import Button from '../Button';

const NewsBlock = ({
    news,
}) => (
    <div className={styles.NewsBlockWrapper}>
        <div className={styles.NewsBlockHeader}>
            <Heading className={styles.NewsHeading} level="4">{'Последние новости'}</Heading>
            <Button variant="grayOutline" size="lg" buttonType='default' onClick={() => window.location.href = '/news'}>Все
                новости</Button>
        </div>
        <div className={styles.NewsList}>
            {
                news.map((item, index) => (
                    <div className={styles.NewsCardItem} key={index}><NewsCard data={item}
                        size='sm'></NewsCard></div>))
            }
        </div>
    </div>
);

export default NewsBlock;
