import React, { forwardRef } from 'react';
import TextMask from 'react-text-mask';
import InputField from 'components/InputField';

const MaskedInput = forwardRef(({
    mask,
    required,
    onChange,
    children,
    ...rest
}, ref) => (
    <TextMask
        ref={ref}
        mask={mask}
        guide={false}
        onChange={onChange}
        render={(innerRef, props) => (
            <InputField
                ref={innerRef}
                required={required}
                {...rest}
                {...props}>
                { children }
            </InputField>
        )} />
));

export default MaskedInput;
