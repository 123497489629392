import React, { useState } from 'react';
import styles from './style.module.scss';

const AccordionWrapper = ({
    active,
    toggle,
    isPaymentInfo,
    children,
}) => {
    const [scopeActive, setScopeActive] = useState(active);

    return (
        <div className={styles.ToggleWrapper}>
            <div
                className={styles.ToggleWrapperToggle}
                data-payment={isPaymentInfo}
                data-active={scopeActive}>
                <div className={styles.ToggleWrapperHeader}>
                    { toggle }
                    <div className={styles.ToggleWrapperArrow} onClick={() => setScopeActive(!scopeActive)}></div>
                </div>

            </div>

            <div
                className={styles.ToggleWrapperInner}
                data-payment={isPaymentInfo}
                data-active={scopeActive}>
                <div className={styles.ToggleWrapperInnerContent}>
                    { children }
                </div>
            </div>
        </div>
    );
};

export default AccordionWrapper;
