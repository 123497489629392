import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import ToolTip from '../ToolTip';
import { getLevelAdvantages, getLevelAddedAdvantages } from '../../helpers/advantages.js';

function getLevelCoefficient(level) {
    switch (level) {
    case 'Silver':
        return '1';
    case 'Gold':
        return '+20%';
    case 'Platinum':
        return '+30%';
    default:
        return '';
    }
}

function getLevelAdvantagesFunction(period, level) {
    if (period === 'currentPeriod') return getLevelAdvantages(level);
    return getLevelAddedAdvantages(level);
}

class UserLevelTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
    }

    render() {
        const { children, period, level, requirements } = this.props;
        return (
            <ToolTip
                content={
                    <div className={styles.UserLevelTooltipContent}>
                        {(period === 'nextPeriod') && (
                            <div className={cn(styles.UserLevelTooltipContentItem, styles.UserLevelTooltipContentItem__NextLevel)}>
                                <div className={styles.UserLevelTooltipContentItem__NextLevelTitle}>
                                    {'До следующего уровня:'}
                                </div>
                                <div className={styles.UserLevelTooltipContentItem__NextLevelConditions}>
                                    <div className={styles.UserLevelTooltipContentItem__NextLevelConditionsCard}>
                                        {requirements ? (
                                            <>
                                                {requirements.iszh !== 0 && (
                                                    <div className={styles.UserLevelTooltipContentItem__NextLevelConditionsCard__Policies}>
                                                        {`${requirements.iszh} ИСЖ`}
                                                    </div>
                                                )}
                                                {requirements.nszh !== 0 && (
                                                    <div className={styles.UserLevelTooltipContentItem__NextLevelConditionsCard__Policies}>
                                                        {`${requirements.nszh} НСЖ`}
                                                    </div>
                                                )}
                                                {requirements.premiumRub !== 0 && (
                                                    <div className={styles.UserLevelTooltipContentItem__NextLevelConditionsCard__Premium}>
                                                        {/* eslint-disable-next-line no-irregular-whitespace */}
                                                        {`либо суммарная премия ${requirements.premiumRub.toLocaleString()} руб.`}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className={styles.UserLevelTooltipContentItem__NextLevelConditionsCard__Policies}>
                                                {'Все условия выполнены'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) }
                        <div
                            className={cn(styles.UserLevelTooltipContentItem, styles.UserLevelTooltipContentItem__Coefficient)}>
                            <div className={styles.UserLevelTooltipContentItem__CoefficientTitle}>
                                {'Коэффициент начисления уралкоинов'}
                            </div>
                            <div className={styles.UserLevelTooltipContentItem__CoefficientPercent}>
                                <div
                                    className={level === 'Silver' ? styles.UserLevelTooltipContentItem__CoefficientPercentNumber : styles.UserLevelTooltipContentItem__CoefficientPercentCard}>
                                    {getLevelCoefficient(level)}
                                </div>
                            </div>
                        </div>
                        <div
                            className={cn(styles.UserLevelTooltipContentItem, styles.UserLevelTooltipContentItem__LevelAdvantages)}>
                            <div className={styles.UserLevelTooltipContentItem__LevelAdvantagesTitle}>
                                {period === 'currentPeriod' ? 'Доступно на уровне:' : 'Дополнительно получите:'}
                            </div>
                            <div className={styles.UserLevelTooltipContentItem__LevelAdvantagesList}>
                                <ul>
                                    {getLevelAdvantagesFunction(period, level).map((advantageItem, index) => (<li key={index}>{advantageItem}</li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                trigger='mouseenter focus'
                placement='bottom'>
                { children }
            </ToolTip>
        );
    }
}

export default UserLevelTooltip;
