import React from 'react';
import OrderItem from '../OrderItem';
import styles from '../../pages/OrdersPage/styles.module.scss';

const OrdersList = ({ data }) => (
    <table className={styles.OrdersListTable}>
        <thead className={styles.OrdersListTableHeader}>
            <tr>
                <th>№ заказа</th>
                <th>Дата заказа</th>
                <th>Стоимость</th>
                <th>Статус заказа</th>
                <th>Описание</th>
                <th>Дата доставки</th>
            </tr>
        </thead>
        <tbody>
            {data.map((item, index) => (
                <OrderItem
                    key={index}
                    data={item}/>
            ))}
        </tbody>
    </table>

);

export default OrdersList;
