import { observable, action, decorate } from 'mobx';
import { GET_CLIENT_INFO, GET_RATING_LIST } from 'api';

class ProfileModule {
    constructor(props, appStore) {
        this.appStore = appStore;
    }

    user = {};

    profileImageUrl = '';

    levelUpgradeModalShown = false;

    levelPlanet = {
        Silver: {
            level: 'Серебряный',
            planet: 'Меркурий',
        },
        Gold: {
            level: 'Золотой',
            planet: 'Сатурн',
        },
        Platinum: {
            level: 'Платиновый',
            planet: 'Юпитер',
        },
    }

    ratingList = [];

    getUserNameField(field) {
        if (this.user.name) {
            const fullName = this.user.name.split(' ');
            switch (field) {
            case 'surname': return fullName[0];
            case 'name': return fullName[1];
            case 'patronymic': return fullName[2];
            case 'navbar': {
                const surname = fullName[0];
                const name = fullName[1];
                const shortSurname = `${surname[0]}.`;
                return `${name} ${shortSurname}`;
            }
            default: return '';
            }
        }

        return '';
    }

    getUserLevelPlanet(field) {
        const levelObject = Object.keys(this.user).length !== 0 ? this.levelPlanet[this.user.level.currentPeriod.level] : null;
        if (levelObject !== null) {
            switch (field) {
            case 'level': return levelObject.level;
            case 'planet': return levelObject.planet;
            case 'levelPlanet': return `${levelObject.level} (${levelObject.planet})`;
            default: return '';
            }
        } else {
            return '';
        }
    }

    transformLink(url) {
        const indexOfUpload = url.indexOf('upload');
        return `/${url.substring(indexOfUpload)}`;
    }

    getClientInfo() {
        return GET_CLIENT_INFO()
            .then((data) => {
                this.user = data.profile;
                if (data.profileImageUrl) {
                    this.profileImageUrl = this.transformLink(data.profileImageUrl);
                }
            }).catch(() => {
                if (Object.keys(this.user).length !== 0) {
                    this.user = {};
                    this.profileImageUrl = '';
                    window.location.replace('/login');
                }
            });
    }

    setLevelUpgradeModalShown(value) {
        this.levelUpgradeModalShown = value;
    }

    getRatingList() {
        return GET_RATING_LIST().then(({ ratingList }) => {
            this.ratingList = ratingList;
        });
    }
}

decorate(ProfileModule, {
    user: observable,
    profileImageUrl: observable,
    levelUpgradeModalShown: observable,
    levelPlanet: observable,
    ratingList: observable,
    getUserName: action.bound,
    getUserLevelPlanet: action.bound,
    getClientInfo: action.bound,
    setLevelUpgradeModalShown: action.bound,
    getRatingList: action.bound,
});

export default ProfileModule;
