import React from 'react';
import styles from './styles.module.scss';

const TopWrapper = ({
    background,
    hasBothRoles,
    children,
}) => (
    <div className={styles.TopWrapper} data-has-background={Boolean(background)} data-has-both-roles={hasBothRoles}>
        {background && (
            <div
                className={styles.TopWrapperPicture}
                style={{ backgroundImage: `url(${background})` }}
            />
        )}

        { children }
    </div>
);

export default TopWrapper;
