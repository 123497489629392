import React from 'react';
import styles from './styles.module.scss';
import closeIcon from '../../assets/icons/close.svg';
import Modal from '../Modal';

class QuestionTooltip extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            policyId: this.props.policyId,
            isShow: false,
            modal: false,
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleModal = this.handleModal.bind(this);
    }

    handleClose() {
        this.setState({
            isShow: !this.state.isShow,
        });
    }

    modalOpen(e) {
        if (e.target.className === 'modal-open') {
            this.setState({
                isShow: false,
                modal: true,
            });
        }
    }

    handleModal() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {
        const { text } = this.props;
        return (
            <div className={styles.QuestionTooltip}>
                <div className={styles.QuestionTooltipHandler} onClick={() => this.handleClose()} >
                    {this.state.modal && <Modal
                        title={ 'Запрос отправлен в страховую компанию' }
                        handleClose={this.handleModal} />
                    }
                    { this.state.isShow && (
                        <div className={styles.QuestionTooltipContent} >
                            <img className={styles.close} src={closeIcon} alt={'QuestionTooltip'} onClick={() => this.handleClose()}/>
                            <span dangerouslySetInnerHTML={{ __html: text }} onClick={(e) => this.modalOpen(e)} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default QuestionTooltip;
