import React from 'react';
import cn from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import Container from 'components/Container';
import styles from './styles.module.scss';

const SalesNavBar = ({ additionalPoints }) => {
    const history = useHistory();
    return (
        <div className={cn((styles.SalesNavBar))}>
            <Container>
                <div className={cn((styles.SalesNavBarWrapper))}>
                    <Link
                        to="/sales"
                        className={cn(
                            styles.SalesNavBarItem,
                            { [styles.SalesNavBarItemActive]: history.location.pathname === '/sales' },
                        )}>
                        {'Главная'}
                    </Link>
                    <Link
                        to="/rating"
                        className={cn(
                            styles.SalesNavBarItem,
                            { [styles.SalesNavBarItemActive]: history.location.pathname === '/rating' },
                        )}>
                        {'Рейтинг продавцов'}
                    </Link>
                    {/* <Link
                        to="/sales/analysis"
                        className={cn(
                            styles.SalesNavBarItem,
                            { [styles.SalesNavBarItemActive]: history.location.pathname === '/sales/analysis' },
                        )}>
                        {'Анализ продаж НСЖ'}
                    </Link> */}
                    {additionalPoints && additionalPoints.length > 0 && (
                        <Link
                            to="/sales/additional"
                            className={cn(
                                styles.SalesNavBarItem,
                                { [styles.SalesNavBarItemActive]: history.location.pathname === '/sales/additional' },
                            )}>
                            {'Дополнительные баллы'}
                        </Link>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default SalesNavBar;
