export const isLocalEnvironment = () => document.location.host === 'localhost:3000';

export const isTestEnvironment = () => document.location.host === 'splk-test.russianitgroup.ru' || document.location.host === 'sp-test.russianitgroup.ru';

export const isProdEnvironment = () => document.location.host === 'seller.in-life.ru' || document.location.host === 'seller.sovcomlife.ru';

export const getCaptchaSiteKey = () => {
    if (isProdEnvironment()) {
        return 'ysc1_uylDi5prmUecavK9Y8l9E9q6WzwUP63oT4TaKs384a3505a0';
    } if (isTestEnvironment) {
        return 'ysc1_SsbQMCws0W96SNZ13bLvavwUhSYh0QgHVP9ObS0f0e9250f2';
    }
    return '';
};
