import React, { useState } from 'react';
import cn from 'classnames';
import ToggleTabs, { ToggleTabsItem } from 'components/ToggleTabs';
import style from './styles.module.scss';
import AccordionWrapper from '../AccordionWrapper';
import Button from '../Button';

const PolicyScheduleTabs = ({ rows }) => {
    const [activeTab, setActiveTab] = useState('upcoming');

    const getHeaderAccord = ({ status, expectedPaymentDate, expectedPaymentDateWithGracePeriod, totalAmount, payAmount, type }, rowIndex) => (
        <div className={style.HeaderAccordWrapper} data-highlighted={type === 'current_payment'}>
            <div className={style.HeaderAccordColumn}>
                <div className={style.HeaderAccordTitle}>{expectedPaymentDate}</div>
                <div className={style.HeaderAccordSubTitle}>{expectedPaymentDateWithGracePeriod}</div>
            </div>
            <div className={style.HeaderAccordColumn}>
                <div className={style.HeaderAccordSubTitle}>Общая сумма</div>
                <div className={style.HeaderAccordTitle}>{totalAmount}</div>
            </div>
            {type === 'past_payment' && (<div className={cn(style.HeaderAccord, style.HeaderAccordLeft)}>
                {status === 'оплачен' && <div className={style.HeaderAccordStatus}>Успешно оплачено</div> }
                {status === 'в обработке' && <div className={cn(style.HeaderAccordStatus, style.HeaderAccordStatusWait)}>В обработке</div> }
            </div>)}
            {(type === 'future_payment' || type === 'current_payment')
                && (<div className={cn(style.HeaderAccord, style.HeaderAccordLeft)}>
                    <div className={cn(style.HeaderAccordColumn)}>
                        <div className={style.HeaderAccordSubTitle}>Сумма к оплате</div>
                        <div className={style.HeaderAccordTitle}>{payAmount}</div>
                    </div>
                    {status === 'не оплачен'
                        && <Button
                            style={{ width: 150 }}
                            size="sm"
                            type="submit"
                            variant="primary">
                            Оплатить
                        </Button>
                    }
                </div>)}
        </div>
    );
    const getPaymentData = (paymentDetails) => paymentDetails !== null
            && (<div className={style.BodyAccordWrapper}>
                <div className={style.BodyAccordHead}>
                    <div className={style.BodyAccordHeadData}>Дата поступления платежа</div>
                    <div className={style.BodyAccordHeadSum}>Оплачено</div>
                </div>
                <div className={style.BodyAccordItems}>
                    {paymentDetails.map((item, itemIndex) => (
                        <div key={itemIndex} className={style.BodyAccordItem}>
                            <div className={style.BodyAccordHeadData}>{item.paymentDate}</div>
                            <div>{item.paidSumInContractCurrency}</div>
                        </div>
                    ))}
                </div>
            </div>);

    const getCurrentPayment = () => {
        const rowIndex = rows.findIndex((row) => row.type === 'current_payment');
        return (
            rows.filter((row) => row.type === 'current_payment')
                .map((item, itemIndex) => (<div key={itemIndex} className={style.PolicyScheduleCurrent}>
                    <p>
                        Ближайший платеж
                    </p>
                    <AccordionWrapper isPaymentInfo={item.paymentDetails !== null} active={true} toggle={getHeaderAccord(item, rowIndex)}>
                        <div>{getPaymentData(item.paymentDetails)}</div>
                    </AccordionWrapper></div>))
        );
    };

    return (
        <div>
            {rows.some((row) => row.type === 'current_payment')
                ? <>
                    {getCurrentPayment()}
                    <div className={style.ToggleTabsWrapper}>
                        <ToggleTabs>
                            <ToggleTabsItem
                                active={activeTab === 'history'}
                                onClick={() => setActiveTab('history')}>
                                <div className={activeTab !== 'history' ? style.NotActiveTab : ''}>{'История платежей'}</div>
                            </ToggleTabsItem>
                            <ToggleTabsItem
                                active={activeTab === 'upcoming'}
                                onClick={() => setActiveTab('upcoming')}>
                                <div
                                    className={activeTab !== 'upcoming' ? style.NotActiveTab : ''}>{'Предстоящие платежи'}</div>
                            </ToggleTabsItem>
                        </ToggleTabs>
                    </div>
                    <div className={style.PolicyScheduleItems}>
                        {activeTab === 'history' && rows.map((row, rowIndex) => (
                            (
                                row.type === 'past_payment'
                            ) && (<AccordionWrapper key={rowIndex} isPaymentInfo={row.paymentDetails !== null} active={true} toggle={getHeaderAccord(row, rowIndex)}>
                                <div>{getPaymentData(row.paymentDetails)}</div>
                            </AccordionWrapper>)
                        ))}
                        {activeTab === 'upcoming' && rows.map((row, rowIndex) => (
                            (
                                row.type === 'future_payment'
                            ) && (<AccordionWrapper key={rowIndex} isPaymentInfo={row.paymentDetails !== null} active={true} toggle={getHeaderAccord(row, rowIndex)}/>)
                        ))}
                    </div>
                </>
                : <div className={style.PolicyScheduleItems}>
                    {rows.map((row, rowIndex) => ((row.type === 'past_payment' || row.type === 'future_payment')
                            && (<AccordionWrapper key={rowIndex} isPaymentInfo={row.paymentDetails !== null} active={true} toggle={getHeaderAccord(row, rowIndex)}>
                                {(row.type === 'past_payment') && <div>{getPaymentData(row.paymentDetails)}</div>}
                            </AccordionWrapper>)
                    ))}
                </div>
            }
        </div>
    );
};
export default PolicyScheduleTabs;
