import React from 'react';
import Button from '@mui/material/Button';
import styles from './styles.module.scss';

class CartItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemNumber: Number(this.props.data.itemNumber),
        };
    }

    handleMinus() {
        if (this.state.itemNumber > 1) {
            this.setState((prevState) => ({
                itemNumber: prevState.itemNumber - 1,
            }), () => {
                this.props.addItemToBasket(this.props.data, this.state.itemNumber);
            });
        }
    }

    handlePlus() {
        if (this.state.itemNumber < this.props.data.quantity) {
            this.setState((prevState) => ({
                itemNumber: prevState.itemNumber + 1,
            }), () => {
                this.props.addItemToBasket(this.props.data, this.state.itemNumber);
            });
        }
    }

    render() {
        return (
            <div className={styles.CartItemWrapper}>
                <div className={styles.CartItemContent}>
                    <img className={styles.CartItemImage}
                        src={`/upload/lk-sellers-shop/${this.props.data.previewImage.id}.png`}
                        alt={this.props.data.title}/>
                    <div className={styles.ItemMainField}>
                        <div className={styles.CartItemTitle}
                            dangerouslySetInnerHTML={{ __html: `${this.props.data.title}${this.props.data.option ? ` ${this.props.data.option}` : ''}` }}>
                        </div>
                        <div className={styles.CartItemSelectQuantityWrapper}>
                            <div className={styles.CartItemMinusButton}>
                                <Button onClick={() => this.handleMinus()} variant="text" style={{
                                    maxWidth: '40px',
                                    maxHeight: '40px',
                                    minWidth: '40px',
                                    minHeight: '40px',
                                    fontSize: '20px',
                                }}>-</Button>
                            </div>
                            <div className={styles.CartItemNumber}>{ this.state.itemNumber }</div>
                            <div className={styles.CartItemPlusButton}>
                                <Button onClick={() => this.handlePlus()} variant="contained" style={{
                                    maxWidth: '40px',
                                    maxHeight: '40px',
                                    minWidth: '40px',
                                    minHeight: '40px',
                                    fontSize: '20px',
                                }}>+</Button>
                            </div>
                        </div>
                        <div className={styles.CardItemQuantityTitle}> В наличии { this.props.data.quantity } шт</div>
                    </div>

                    <div className={styles.CardItemDeleteRightWrapper}>
                        <div className={styles.CartItemPrice}>{ Number(this.props.data.price).toLocaleString() } У</div>
                        <Button className={styles.CardItemDeleteButton} onClick={() => this.props.deleteItemFromBasket(this.props.data)} variant="contained">Удалить</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CartItem;
