import React, { forwardRef } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './style.scss';

const ToolTip = forwardRef(({ content, children, trigger, placement, arrow = true, visible, onClickOutside }, ref) => (
    <Tippy
        visible={visible}
        content={content}
        trigger={trigger}
        placement={placement}
        touch='hold'
        onClickOutside={onClickOutside}
        interactive={true}
        arrow={arrow}>
        {children}
    </Tippy>
));

export default ToolTip;
