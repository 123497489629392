import React from 'react';
import styles from './styles.module.scss';
import arrow from '../../assets/icons/stonks_blue.svg';
import star from '../../assets/icons/new-icon-rating.svg';

const RatingPositionCard = ({ position }) => (
    <div className={styles.RatingPositionCard}>
        <div className={styles.CardHeader}>
            {'Место в рейтинге'}
            <img src={arrow} alt=""/>
        </div>
        <div className={styles.CardPosition}>
            <img src={star} alt="" className={styles.CardPositionIcon}/>
            {`${position} место`}
        </div>
    </div>
);

export default RatingPositionCard;
