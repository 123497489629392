import React from 'react';
import styles from './styles.module.scss';

export const Radio = ({
    children,
    value,
    handleRadioChange,
    checked,
}) => (
    <div className={styles.Radio}>
        <label className={styles.RadioWrapper}>
            <input
                type="radio"
                value={value}
                checked={checked}
                className={styles.RadioInput}
                onChange={(e) => handleRadioChange(e)}
            />

            { children } </label>
    </div>
);

const RadioGroup = ({
    children,
}) => (
    <div className={styles.RadioGroup}>
        {children}
    </div>
);

export default RadioGroup;
