import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const SupervisorRatingItem = ({ data }) => (
    <tr className={styles.RatingItemWrapper}>
        <td className={styles.RatingItemUser}>{ data.userName }</td>
        <td className={styles.RatingItemRank}>
            <div className={cn(
                data.rank === 1 ? styles.RatingItemOne : null,
                data.rank === 2 ? styles.RatingItemTwo : null,
                data.rank === 3 ? styles.RatingItemThree : null,
            )}>

                {data.rank} место
            </div>
        </td>
        <td className={styles.RatingItemOffice}>{ data.region.office }</td>
        <td className={styles.RatingItemInsurancePremium}>{ data.totalInsurancePremiumRuble.toLocaleString()}&nbsp;Р</td>
        <td className={styles.RatingItemPoints}>{ data.awarderdPoints.toLocaleString()}&nbsp;У</td>
        <td className={styles.RatingItemTerritorialDirectorate}>{ data.region.territorialDirectorate }</td>
        <td className={styles.RatingItemMacroRegion}>{ data.region.macroRegion }</td>
    </tr>
);

export default SupervisorRatingItem;
