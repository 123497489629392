import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import ContentWrapper from 'components/ContentWrapper';
import Container from 'components/Container';
import PolicySalesList from 'components/PolicySalesList';
import MainTopWrapper from '../../components/MainTopWrapper';
import SalesNavBar from '../../components/SalesNavBar';
import NewsBlock from '../../components/NewsBlock';
import { GET_POLICY_LIST } from '../../api';

class SalesPage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Личный кабинет продавца',
            data: [],
            news: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        document.title = this.state.title;
        GET_POLICY_LIST().then((data) => {
            this.setState({ data: data.profile });
            if (this.store.news.news.length === 0) {
                this.store.news.getNews().then(() => {
                    this.setState({ news: this.store.news.news.slice(0, 3), isLoading: false });
                });
            } else {
                this.setState({ news: this.store.news.news.slice(0, 3), isLoading: false });
            }
        });
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <SalesNavBar additionalPoints={this.store.profile.user.additionalPoints}/>
                    <ContentWrapper isLoading={this.state.isLoading}>
                        <Container>
                            <PolicySalesList
                                data={this.state.data}/>
                            <NewsBlock
                                news={this.state.news}/>
                        </Container>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(SalesPage));
