import React from 'react';
import Container from 'components/Container';
import ToggleWrapper from 'components/ToggleWrapper';
import Heading from 'components/Heading';
import styles from './styles.module.scss';

const DetailsContentWrapper = ({
    title,
    padding = '55px 0 0',
    backgroundColor,
    isToggle,
    children,
}) => (
    <div style={{ padding, backgroundColor }}>
        <Container fluid>
            <div className={styles.SideWrapper}>
                <div className={styles.SideWrapperInner}>
                    {isToggle
                        ? (
                            <ToggleWrapper
                                active={true}
                                toggle={
                                    <>
                                        {title && <Heading level="4">{title}</Heading>}
                                    </>
                                }
                            >
                                <div style={{ ...(title && { marginTop: 30 }) }}>
                                    {children}
                                </div>
                            </ToggleWrapper>
                        ) : (
                            <>
                                {title && <Heading level="4">{title}</Heading>}

                                <div style={{ ...(title && { marginTop: 30 }) }}>
                                    {children}
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </Container>
    </div>
);

export default DetailsContentWrapper;
