import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import DetailsContentWrapper from 'components/_modules/wrappers/DetailsContentWrapper/index.js';
import TextContent from 'components/TextContent';
import Preloader from 'components/Preloader/Preloader';
import InvestmentTable from 'components/_modules/policy/InvestmentTable';
import Chart from 'components/Chart';
import styles from './styles.module.scss';

const InvestmentIncomeTab = ({ items, policyId, store }) => {
    const { graphicsData, initialization, loading } = store.investmentIncome;

    useEffect(() => {
        const getData = (index) => {
            store.investmentIncome.getGraphicsData(index, policyId).then(() => {
                store.investmentIncome.setInitialization(true);
            });
        };

        if (initialization === false) {
            getData(items.find((item) => item.type === 'additional_income_graph').index);
        } else {
            store.investmentIncome.setLoading(false);
        }
    }, [initialization, items, policyId, store.investmentIncome]);

    return (
        loading
            ? <DetailsContentWrapper>
                <div className={styles.PreloaderWrapper}>
                    <Preloader embedded/>
                </div>
            </DetailsContentWrapper>
            : <React.Fragment>
                {items.map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                        {item.type === 'content' && (
                            <DetailsContentWrapper title={item.title}>
                                {item.values.map((value, valueIndex) => (
                                    <TextContent key={valueIndex}
                                        dangerouslySetInnerHTML={{ __html: value.value }}/>
                                ))}
                            </DetailsContentWrapper>
                        )}
                        {item.type === 'additional_income_graph' && graphicsData.RESULT === 'OK' && (
                            <DetailsContentWrapper title={item.title}>
                                <Chart
                                    data={graphicsData}
                                    index={item.index}
                                />
                            </DetailsContentWrapper>
                        )}
                        {(item.type === 'coupon_income_table') && (
                            <DetailsContentWrapper
                                title={item.title}
                                isToggle={item.closed}
                            >
                                {item.rows.length >= 3 && item.rows[2] && <InvestmentTable rows={item.rows}/>}
                            </DetailsContentWrapper>
                        )}
                    </React.Fragment>
                ))}
            </React.Fragment>
    );
};

export default inject('store')(observer(InvestmentIncomeTab));
