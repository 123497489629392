import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/moment';
import styles from './styles.module.scss';
import Heading from '../../components/Heading';
import Wrapper from '../../components/Wrapper';
import MainTopWrapper from '../../components/MainTopWrapper';
import Container from '../../components/Container';
import AppLayout from '../../layouts/AppLayout';
import photo from '../../assets/images/oksana_soloveva_avatar.jpg';
import ContentWrapper from '../../components/ContentWrapper';

class NewsPage extends Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            newsItem: {},
            isLoading: true,
        };
    }

    componentDidMount() {
        if (Object.keys(this.store.news.news).length === 0) {
            this.store.news.getNews().then(() => {
                const newsItem = this.store.news.news.find((item) => Number(item.id) === Number(this.props.match.params.id));
                this.setState({ newsItem, isLoading: false });
            });
        } else {
            const newsItem = this.store.news.news.find((item) => Number(item.id) === Number(this.props.match.params.id));
            this.setState({ newsItem, isLoading: false });
        }
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    <ContentWrapper isLoading={this.state.isLoading}>
                        <Container fluid>
                            <div className={styles.NewsWrapper}>
                                <div
                                    className={styles.NewsBack}
                                    onClick={() => window.location.href = '/news'}>
                                    Назад к списку новостей
                                </div>
                                <div className={styles.NewsContent}>
                                    <div className={styles.News}>
                                        <Heading level="4">
                                            {this.state.newsItem.title}
                                        </Heading>
                                        <div className={styles.NewsDate}>
                                            {moment.unix(this.state.newsItem.date).format('DD.MM.YYYY')}
                                        </div>
                                        <div className={styles.NewsText}
                                            dangerouslySetInnerHTML={{ __html: this.state.newsItem.mainText }}/>
                                    </div>
                                    <div className={styles.NewsAuthor}>
                                        <img src={photo} className={styles.NewsAuthorPhoto} alt=''/>
                                        <div>
                                            <div className={styles.NewsAuthorLabel}>Автор новости</div>
                                            <div className={styles.NewsAuthorName}>{this.state.newsItem.author}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(NewsPage));
