import 'styles/loader.scss';

import React from 'react';
import {
    Route,
    Switch,
    Redirect,
    BrowserRouter,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import Async from 'react-async';
import { Provider } from 'mobx-react';
import { registerLocale } from 'react-datepicker';
import localeRu from 'date-fns/locale/ru';
import initializeStore from 'stores';
import BaseLayout from 'layouts/BaseLayout';
import LoginPage from 'pages/LoginPage';
import SalesPage from 'pages/SalesPage';
import AdditionalPointsPage from 'pages/AdditionalPointsPage';
import RatingPage from 'pages/RatingPage';
import ShopPage from 'pages/ShopPage';
import CartPage from 'pages/CartPage';
import OrdersPage from 'pages/OrdersPage';
import NewsListPage from 'pages/NewsListPage';
import NewsPage from 'pages/NewsPage';
import SupervisorSalesPage from 'pages/SupervisorSalesPage';
import SupervisorOrdersPage from 'pages/SupervisorOrdersPage';
import SupervisorRatingPage from 'pages/SupervisorRatingPage';
import ProfilePage from 'pages/ProfilePage';
import Page404 from 'pages/Page404';
import PolicyPage from 'pages/PolicyPage';
import FadeTransition from 'components/FadeTransition';
import ScrollToTop from 'components/ScrollToTop';
import { isLocalEnvironment, isTestEnvironment } from './helpers/helpers';

registerLocale('ru', localeRu);

const initializeReactGA = (companyCode) => {
    const key = companyCode === 'Life' ? 'UA-10854799-10' : 'UA-10854799-9';

    ReactGA.initialize(key);
    ReactGA.pageview(window.location.pathname + window.location.search);
};

const store = initializeStore();

if (process.env.NODE_ENV === 'production') {
    initializeReactGA(store.app.companyCode);
}

const getAuthUser = () => store.profile.getClientInfo();

const AppRoute = ({ component: Component, layout: Layout, meta = {
    phoneRequired: undefined,
}, verify, ...rest }) => {
    if (!Component) {
        return <Redirect to={{ pathname: '/login' }} />;
    }

    const { isSeller, isSupervisor } = store.profile.user;
    const showSeller = !!meta.showSeller;
    const showSupervisor = !!meta.showSupervisor;
    const hasAuthUser = Object.keys(store.profile.user).length !== 0;
    const notLoginPage = window.location.pathname.indexOf('/login') === -1;
    const isTestPage = window.location.pathname.indexOf('/test-profile') !== -1;

    return (
        <Route {...rest} render={(props) => (
            <React.Fragment>
                {(!isTestPage && !hasAuthUser && notLoginPage) && (
                    <Redirect to={{ pathname: '/login' }} />
                )}
                {(!isTestPage && !isSeller && showSeller) && (
                    <Redirect to={{ pathname: '/login' }} />
                )}
                {(!isTestPage && !isSupervisor && showSupervisor) && (
                    <Redirect to={{ pathname: '/login' }} />
                )}
                {Layout
                    ? (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )
                    : (
                        <React.Fragment>
                            {store.loading.complete()}
                            {window.scrollTo(0, 0)}
                            <Component {...props} />
                        </React.Fragment>
                    )}
            </React.Fragment>
        )} />
    );
};

const Router = () => (
    <BrowserRouter>
        <ScrollToTop callback={() => {
            document.getElementsByTagName('body')[0].scrollTop = 0;
            getAuthUser();
        }}>
            <Switch>
                <AppRoute exact path="/" />
                <AppRoute exact path="/login" layout={BaseLayout} component={LoginPage}
                    meta={{
                        public: true,
                        onlyWhenLoggedOut: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/sales"
                    component={SalesPage}
                    meta={{
                        showSeller: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/sales/additional"
                    component={AdditionalPointsPage}
                    meta={{
                        showSeller: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/rating"
                    component={RatingPage}
                    meta={{
                        showSeller: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/sales/policy/:policyId"
                    component={PolicyPage}
                    meta={{
                        showSeller: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/shop"
                    component={ShopPage}
                    meta={{
                        showSeller: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/cart"
                    component={CartPage}
                    meta={{
                        showSeller: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/orders"
                    component={OrdersPage}
                    meta={{
                        showSeller: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/news"
                    component={NewsListPage}
                />
                <AppRoute
                    exact
                    path="/news/:id"
                    component={NewsPage}
                />
                <AppRoute
                    exact
                    path="/supervisor/sales"
                    component={SupervisorSalesPage}
                    meta={{
                        showSupervisor: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/supervisor/sales/policy/:policyId"
                    component={PolicyPage}
                    meta={{
                        showSupervisor: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/supervisor/orders"
                    component={SupervisorOrdersPage}
                    meta={{
                        showSupervisor: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/supervisor/rating"
                    component={SupervisorRatingPage}
                    meta={{
                        showSupervisor: true,
                    }}
                />
                <AppRoute
                    exact
                    path="/profile"
                    component={ProfilePage}
                    meta={{
                        showSeller: true,
                    }}
                />
                {(isLocalEnvironment() || isTestEnvironment()) && (
                    <AppRoute
                        exact
                        path="/test-profile/policy/:policyId"
                        component={PolicyPage}
                        meta={{
                            public: true,
                        }}
                    />)}
                <AppRoute
                    exact
                    path="*"
                    component={Page404}
                />
            </Switch>
        </ScrollToTop>
    </BrowserRouter>
);

const App = () => (
    <React.Fragment>
        <Provider store={store}>
            <Async promiseFn={getAuthUser}>
                {({ isLoading }) => (
                    <FadeTransition loading={isLoading}>
                        {!isLoading && <Router />}
                    </FadeTransition>
                )}
            </Async>
        </Provider>
    </React.Fragment>
);

export default App;
