import React, { Component } from 'react';
import Heading from 'components/Heading';
import moment from 'moment';
import styles from './styles.module.scss';
import SupervisorPolicyItem from '../SupervisorPolicyItem';
import FilterWrapper from '../FilterWrapper';
import InputFiled from '../InputField';
import DateInputField from '../DateInputField';
import BonusCard from '../BonusCard';

class SupervisorPolicySalesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: this.props.data,
            periodStart: '',
            periodEnd: '',
            number: '',
            fio: '',
            birthDate: '',
            bonusISG: 0,
            bonusNSG: 0,
        };
    }

    getAmountBonus(data) {
        let isgSum = 0;
        let nsgSum = 0;
        data.map((policy) => {
            switch (policy.insuranceType) {
            case 'НСЖ':
                isgSum += policy.insurancePremiumRub;
                break;
            case 'ИСЖ':
                nsgSum += policy.insurancePremiumRub;
                break;
            default:
                break;
            }
            return policy;
        });
        this.setState({ bonusISG: isgSum, bonusNSG: nsgSum });
    }

    componentDidMount() {
        this.getAmountBonus(this.props.data);
    }

    filterTable() {
        let filterData = this.props.data;
        if (this.state.periodStart) {
            filterData = filterData.filter((item) => moment(item.beginDate).isSameOrAfter(moment(this.state.periodStart)));
        }
        if (this.state.periodEnd) {
            filterData = filterData.filter((item) => moment(item.beginDate).isSameOrBefore(moment(this.state.endStart)));
        }
        if (this.state.number) {
            filterData = filterData.filter((item) => item.number.indexOf(this.state.number) > -1);
        }
        this.setState({ tableData: filterData });
    }

    render() {
        return (
            <>
                <div className={styles.SalesListWrapper}>
                    <Heading className={styles.SalesListHeading} level="4">{'Премии моих сотрудников'}</Heading>
                    <div className={styles.SalesListBonuses}>
                        <BonusCard data={{ name: 'Общая страховая премия', bonus: this.state.bonusISG + this.state.bonusNSG }}/>
                        <BonusCard data={{ name: 'НСЖ', bonus: this.state.bonusNSG }}/>
                        <BonusCard data={{ name: 'ИСЖ', bonus: this.state.bonusISG }}/>
                    </div>
                </div>
                <div className={styles.SalesListWrapper}>
                    <Heading className={styles.SalesListHeading} level="4">{'Продажи моих сотрудников'}</Heading>
                    <FilterWrapper
                        filterClick={this.filterTable.bind(this)}
                    >
                        <DateInputField
                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            selectsStart
                            label="Период"
                            value={this.state.periodStart}
                            size="lg"
                            icon="calendar"
                            format="dd.MM.yyyy"
                            placeholder="дд.мм.гггг"
                            content='c'
                            onChange={(value) => this.setState({ ...this.state, periodStart: value })}/>
                        <DateInputField
                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            selectsEnd
                            size="lg"
                            icon="calendar"
                            format="dd.MM.yyyy"
                            placeholder="дд.мм.гггг"
                            content='по'
                            value={this.state.periodEnd}
                            onChange={(value) => this.setState({ ...this.state, periodEnd: value })}/>
                        <InputFiled
                            label='№ договора'
                            size="lg"
                            value={this.state.number}
                            onChange={(value) => {
                                this.setState({ ...this.state, number: value.target.value });
                            }}
                        >
                        </InputFiled>
                        <InputFiled
                            label='ФИО клиента'
                            size="lg"
                            value={this.state.fio}
                            onChange={(value) => this.setState({ ...this.state, fio: value.target.value })}
                        >
                        </InputFiled>
                        <DateInputField
                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            label="Дата рождения клиента"
                            size="lg"
                            icon="calendar"
                            format="dd.MM.yyyy"
                            placeholder="дд.мм.гггг"
                            value={this.state.birthDate}
                            onChange={(value) => this.setState({ ...this.state, birthDate: value })}
                        />
                    </FilterWrapper>
                    <div className={styles.SalesListTable}>
                        <div className={styles.SalesListTableHeader}>
                            <div className={styles.TableHeaderNumber}>{'№ договора'}</div>
                            <div className={styles.TableHeaderProgram}>{'Продукт'}</div>
                            <div className={styles.TableHeaderType}>{'Тип'}</div>
                            <div className={styles.TableHeaderStatus}>{'Статус'}</div>
                            <div className={styles.TableHeaderDate}>{'Заключен'}</div>
                            <div className={styles.TableHeaderBeginDate}>{'Действует с'}</div>
                            <div className={styles.TableHeaderEndDate}>{'Окончание'}</div>
                            <div className={styles.TableHeaderPremium}>{'Премия'}</div>
                            <div className={styles.TableHeaderStrategy}>{'Стратегия'}</div>
                            <div className={styles.TableHeaderPoints}>{'Баллы'}</div>
                            <div className={styles.TableHeaderEmployeeFullName}>{'ФИО сотрудника'}</div>
                            <div className={styles.TableHeaderOffice}>{'Точка продаж'}</div>
                            <div className={styles.TableHeaderTerritorialDirectorate}>{'Территориальная дирекция'}</div>
                            <div className={styles.TableHeaderRegionalDirectorate}>{'Макрорегион'}</div>
                        </div>
                        {this.state.tableData.map((item, index) => (
                            <SupervisorPolicyItem
                                key={index}
                                firstIndex={index === 0}
                                lastIndex={index + 1 === this.state.tableData.length}
                                data={item}/>
                        ))}
                    </div>
                    <div className={styles.SalesListFooter}>
                        <strong>Всего:</strong> {this.state.tableData.length} договоров
                    </div>
                </div></>
        );
    }
}

export default SupervisorPolicySalesList;
